angular.module('webpanel').directive('scrollbar', function($compile) {
	// UWAGA TU MIESZKAĆ SMOK

	return {
		link: function(scope, element, args) {
			var instance = HardcoreScrollbar.attach(element[0]);
			var content = element[0].querySelector('.ss-content');

			content.setAttribute('scroll-end', args.sbScrollEnd || 'noop'); // patrz controller/main.js
			content.setAttribute('scroll-start', args.sbScrollStart || 'noop');

			if(typeof args.sbExtraContentStyle !== 'undefined') {
				try {
					content.style = args.sbExtraContentStyle;
				} catch(e) {
					console.error(e);
				}
			}

			$compile(content)(scope);

			scope.$on('$destroy', function() {
				instance.destroy();
			});
		}
	}
});