angular.module('webpanel').service('device', ['auth', 'api', 'socket', '$rootScope', 'popup', '$window', function(auth, api, socket, $rootScope, popup, $window) {
	var devices = {
		simcard: {},
		stb: {},
		card: {},
	};
	var features = new Set();

	var currentDevice = null;
	var observers = {};
	var mode = '';
	var home = true;
	var hasGo = false;

	var set = function(list) {
		reset();
		var firstDevice = null;

		var hasStbAccess = false;
		var hasCardAccess = false;
		var hasMobileAccess = false;

		for(var i in list) {
			if(typeof devices[list[i].type] === 'undefined' || !devices[list[i].type]) continue;

			if(!firstDevice) firstDevice = list[i].id;

			// odblokowujemy sekcje panelu w zależności co klient ma
			if(list[i].type === 'card') {
				hasCardAccess = true;
				list[i].type = 'stb';
			}
			if(list[i].type === 'stb') hasStbAccess = true;
			if(list[i].type === 'simcard') hasMobileAccess = true;

			list[i].saneModel = list[i].model.toLowerCase().replace(/[^a-z0-9]/gi, '');
			if (list[i].saneModel.endsWith("tv")) list[i].saneModel = list[i].saneModel.slice(0, -2);
			devices[list[i].type][list[i].id] = list[i];

			for (var j in list[i].features) {
				features.add(list[i].features[j]);
			}
		}

		$rootScope.$emit('appConfigChange', { what: 'tv', state: hasStbAccess });
		$rootScope.$emit('appConfigChange', { what: 'card', state: hasCardAccess });
		$rootScope.$emit('appConfigChange', { what: 'mobile', state: hasMobileAccess });

		var types = getTypes();

		// trzeba wybrać tryb domyslny w zależności od urządzeń, jeśli brak STB to nie wybieramy stb ;)
		if(typeof types.stb !== 'undefined') {
			mode = 'stb';
		} else if(typeof types.simcard !== 'undefined') {
			mode = 'simcard';
		} else {
			var pop = popup.create();
			pop.setText('Krytyczny błąd: znaleziono urządzenia na koncie, ale żadne z nich '+
				'nie jest obsługiwanego typu. Skontaktuj się z Operatorem.');
			pop.addButton('OK', function() {
				$window.location.reload();
			}, 'yes', true);
			popup.push(pop);

			return;
		}

		$rootScope.$emit('appConfigChange', { what: 'device', state: true });
		notifyObservers('change');
	}

	var get = function() {
		switch(mode) {
			case 'stb': return devices.stb;
			case 'simcard': return devices.simcard;
			default: return {};
		}
	}

	var has = function() {
		return !!currentDevice;
	}

	var reset = function() {
		devices = {
			simcard: {},
			stb: {},
			card: {},
		};
		features = new Set();
	}

	var destroy = function() {
		reset();
		currentDevice = null;

		notifyObservers('change');
		notifyObservers('destroy');
		$rootScope.$emit('deviceLost');

		$rootScope.$emit('appConfigChange', { what: 'tv', state: false });
		$rootScope.$emit('appConfigChange', { what: 'card', state: false });
		$rootScope.$emit('appConfigChange', { what: 'mobile', state: false });
		$rootScope.$emit('appConfigChange', { what: 'device', state: false });
		$rootScope.$emit('appConfigChange', { what: 'watchLive', state: false });
		$rootScope.$emit('appConfigChange', { what: 'epg', state: false });
		$rootScope.$emit('appConfigChange', { what: 'npvr', state: false });
		$rootScope.$emit('appConfigChange', { what: 'list100', state: false });
		$rootScope.$emit('appConfigChange', { what: 'parentProtect', state: false });
		$rootScope.$emit('appConfigChange', { what: 'search', state: false });
		$rootScope.$emit('appConfigChange', { what: 'vod', state: false });
		$rootScope.$emit('appConfigChange', { what: 'schedule', state: false });
	}

	var isHome = function() {
		return home;
	}

	var hasGo = function() {
		return hasGo;
	}

	var select = function(id, selectMode) {
		if(selectMode !== mode) return;

		if(typeof devices[mode][id] !== 'undefined') {
			currentDevice = id;
			auth.setImpersonating(id);

			$rootScope.$emit('appConfigChange', { what: 'watchLive', state: features.has('tv') });
			$rootScope.$emit('appConfigChange', { what: 'epg', state: features.has('full_epg') });
			$rootScope.$emit('appConfigChange', { what: 'npvr', state: features.has('npvr') });
			$rootScope.$emit('appConfigChange', { what: 'list100', state: features.has('list_100') });
			$rootScope.$emit('appConfigChange', { what: 'parentProtect', state: features.has('parent_protect') });
			$rootScope.$emit('appConfigChange', { what: 'search', state: features.has('search') });
			$rootScope.$emit('appConfigChange', { what: 'vod', state: features.has('vod') });
			$rootScope.$emit('appConfigChange', { what: 'schedule', state: features.has('schedule') });

			notifyObservers('selectSuccess');

			$rootScope.$emit('deviceAcquired');

			// jeśli karta sim, to dodatkowo wyslij powiadomeinie do modułów obsługujących sim
			if(mode === 'simcard') {
				$rootScope.$emit('deviceAcquiredSim');
				notifyObservers('roamChange', true);
			} else {
				api.ottIsHome().then(function(response) {
					home = response.data.isHome;
					hasGo = response.data.hasGo;

					notifyObservers('roamChange', response.data.isHome);
					notifyObservers('jbxgoChange', response.data.hasGo);
				}).catch(function() {
					if(auth.isDemo()) return;
					handleLoadError();
				});
			}

			return true;
		} else {
			var firstDevice = null;

			for(var i in devices[mode]) {
				firstDevice = devices[mode][i];
				break;
			}

			select(firstDevice.id, firstDevice.type);

			notifyObservers('selectFail');
			return false;
		}
	}

	var handleLoadError = function() {
		var pop = popup.create();
		pop.setText('Wystąpił błąd podczas pobierania danych. Spróbuj ponownie. '+
			'Jeśli problem będzie się powtarzał, skontaktuj się ze swoim Operatorem.');
		pop.addButton('OK', angular.noop, 'yes', true);
		popup.push(pop);
	}

	var getSelected = function() {
		if(!mode) return;
		return devices[mode][currentDevice];
	}

	var addObserver = function(name, observer) {
		if(typeof observer !== 'function') return;
		if(typeof observers[name] !== 'undefined') return;
		observers[name] = observer;
	}

	var removeObserver = function(name) {
		delete observers[name];
	}

	var notifyObservers = function(eventType, eventData) {
		for(var i in observers) observers[i](eventType, eventData);
	}

	var setName = function(deviceId, newName) {
		if(!devices[mode][deviceId]) return;

		var fitleredNewName = newName.replace(/[^0-9a-ząćęłńóśźżĄĆĘŁŃÓŚŹŻ ]/gi, '');

		api.deviceRename(deviceId, fitleredNewName).then(function() {
			devices[mode][deviceId].friendlyName = fitleredNewName;
			notifyObservers('nameChange');
		}).catch(function() {
			var pop = popup.create();
			pop.setText('Zmiana nazwy urządzenia nie powiodła się. Spróbuj ponownie.');
			pop.addButton('OK', angular.noop, 'yes', true);
			popup.push(pop);
		});

		return true;
	}

	var setMode = function(newMode, lastDeviceObj) {
		if(newMode == mode) return;

		var oldMode = mode;
		mode = newMode;

		try {
			if(typeof lastDeviceObj[newMode] === 'undefined' || !lastDeviceObj[newMode]) {
				lastDeviceObj[newMode] = Object.keys(get())[0];
			}
		} catch(e) {
			mode = oldMode;
			throw e;
		}

		notifyObservers('change');
		select(lastDeviceObj[newMode], newMode);
	}

	var getMode = function() {
		return mode;
	}

	var getTypes = function() {
		var types = {};

		for(var i in devices) {
			if(Object.keys(devices[i]).length > 0) types[i] = true;
		}

		return types;
	}

	auth.addObserver('deviceAuthObserver', function(type) {
		if(type === 'destroy') destroy();
	});

	socket.addObserver('deviceSocketObserver', 'device', function() {
		api.devicesGet().then(function(response) {
			if(!response.data.length) {
				destroy();
				auth.destroy();
				$window.location.reload();
				return;
			}

			var lastCurrentDevice = currentDevice;
			set(response.data);

			if(!select(lastCurrentDevice), mode) {
				select(Object.keys(devices[mode])[0], mode);
			}

			notifyObservers('change');
		}).catch(handleLoadError);
	});

	return {
		set: set,
		get: get,
		has: has,
		select: select,
		getSelected: getSelected,
		destroy: destroy,
		setName: setName,
		addObserver: addObserver,
		removeObserver: removeObserver,
		setMode: setMode,
		getMode: getMode,
		getTypes: getTypes,
		isHome: isHome,
		hasGo: hasGo,
	}

}]);