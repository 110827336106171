angular.module('webpanel').service('message', ['$rootScope', 'device', 'api', 'socket', 'popup', 'auth', function($rootScope, device, api, socket, popup, auth) {
	var messages = {};
	var observers = {};

	var idPrefix = 'msg-';

	var fetchAll = function() {
		api.messagesFetch().then(function(response) {
			messages = {};

			for(var i in response.data) {
				var id = idPrefix+response.data[i].id;
				messages[id] = response.data[i];
			}

			notifyObservers('change');
		}).catch(function() {
			if(auth.isDemo()) return;

			var pop = popup.create();
			pop.setText('Wystąpił błąd podczas pobierania danych. Spróbuj ponownie. '+
				'Jeśli problem będzie się powtarzał, skontaktuj się ze swoim Operatorem.');
			pop.addButton('OK', angular.noop, 'yes', true);
			popup.push(pop);
		});
 	}

	var read = function(id) {
		api.messageRead(id).then(function() {
			messages['msg-'+id].read = true;
			notifyObservers('readSuccess');
		}).catch(function() {
			notifyObservers('readFail');
		});
	}

	var get = function() {
		return messages;
	}

	var addObserver = function(name, observer) {
		if(typeof observer !== 'function') return;
		if(typeof observers[name] !== 'undefined') return;
		observers[name] = observer;
	}

	var removeObserver = function(name) {
		delete observers[name];
	}

	var notifyObservers = function(eventType, eventData) {
		for(var i in observers) {
			observers[i](eventType, eventData);
		}
	}

	var update = function() {
		if(device.has()) {
			fetchAll();
		}
	}

	$rootScope.$on('deviceAcquired', update);

	$rootScope.$on('deviceLost', function() {
		messages = {};
		notifyObservers('change');
	});

	socket.addObserver('messageSocketObserver', 'message', update);

	return {
		get: get,
		read: read,
		addObserver: addObserver,
		removeObserver: removeObserver,
		update: update
	}

}]);