angular.module('webpanel').directive('customValidity', function() {
	
	return {
		scope: {
			customValidity: '@',
		},
		
		link: function(scope, element) {
			var onInvalidHandler = function(e) {
				e.target.setCustomValidity('');
				if(!e.target.validity.valid) e.target.setCustomValidity(scope.customValidity);
			}
			
			var onInputHandler = function(e) {
				e.target.setCustomValidity('');
			}
			
			element.on('invalid', onInvalidHandler);
			element.on('input', onInputHandler);
			
			scope.$on('$destroy', function() {
				element.off('invalid');
				element.off('input');
			});
		}
	}
});


