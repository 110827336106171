angular.module('webpanel').controller('SimStatusController', ['$scope', '$interval', 'mvno', 'device', function($scope, $interval, mvno, device) {
	$scope.data = {};
	$scope.serviceIndex = {}; // id z sms -> indeks w tablicy

	$scope.limitSelectorOpen = null;
	$scope.hasData = false;
	$scope.statusModified = false;

	var cooldownRefreshInterval;
	var mainLimitServiceId;
	// zahardkodowane wartości limitu na okres rozliczeniowy
	var mainLimitValues = [
		{ service: -1, label: '0 zł', absValue: 0 },
		{ service: -2, label: '35 zł', absValue: 35 },
		{ service: -3, label: '100 zł', absValue: 100 },
		{ service: -4, label: '200 zł', absValue: 200 },
	];

	// trzyma listę id_usługi -> wartość_kwotowa do hacka na UI do blokady na cenę jednostkową
	var hackUnitLimitValues = [];

	var confirmSave = function() {
		if(!$scope.checkHasEmail()) return;

		$scope.data.isSaving = true;
		var servicesToSave = [];

		for(var i in $scope.data.services) {
			var uiService = $scope.data.services[i];

			switch($scope.data.services[i].id) {
				case 'unitLimit':
						// obsługuje oba przypadki:
						// 1. wszystkie usługi ukryte pod wybieraczką są wyłączone
						//    więc posyłamy je na pałę ze state:false (wybrany fejk "bez limitu")
						// oraz
						// 2. któraś z usług jest załączona. Trzeba ją odnaleźć
						//    i posłać ją jako state:true a wszystkie inne state:false
						for(var j in hackUnitLimitValues) {
							// pomijamy fake opcję "bez limitu", bo ta opcja jest
							// implementowana jako wszystkie inne na state:false
							if(hackUnitLimitValues[j].service === -1) continue;
							var apiService = {};

							apiService.id = hackUnitLimitValues[j].service;
							apiService.state = (hackUnitLimitValues[j].service === uiService.amount)

							servicesToSave.push(apiService);
						}
				break;
				case 'mainLimit':
					var apiService = {};

					apiService.id = mainLimitServiceId;
					apiService.state = true;

					// trzeba odnaleźć stan liczbowy na podstawie wartości
					// zastępczej używanej w gui w wybieraczce
					for(var j in mainLimitValues) {
						if(mainLimitValues[j].service === uiService.amount) {
							apiService.amount = mainLimitValues[j].absValue;
							break;
						}
					}

					servicesToSave.push(apiService);
				break;
				default:
					var apiService = {};

					apiService.id = uiService.id;
					apiService.state = uiService.state;
					if(uiService.amount) apiService.amount = uiService.amount;

					servicesToSave.push(apiService);
			}

		}

		mvno.saveServices(servicesToSave);
		$scope.statusModified = false;

		$scope.analytics.trackEvent('sim_status', 'save_confirm');
	}

	var disableConflicting = function(id) {
		var serviceIndex = $scope.serviceIndex[id];
		if(!serviceIndex) return;

		var conflicts = $scope.data.services[serviceIndex].conflicts;

		// wyciągamy oryginalne id blokad ceny jednostkowej
		var hackUnitLimitServiceIds = hackUnitLimitValues.reduce(function(prev, curr) {
			if (curr.service === -1) return prev;
			prev.push(curr.service);
			return prev;
		}, []);

		for(var i in conflicts) {
			if(hackUnitLimitServiceIds.indexOf(conflicts[i]) > -1) {
				// jeśli jest konflikt, to wyłączamy wszystkie te usługi progowe
				$scope.data.services[$scope.serviceIndex['unitLimit']].amount = -1;
				continue;
			}

			var conflictingService = $scope.data.services[$scope.serviceIndex[conflicts[i]]];
			// mogą być jakieś wartości z kosmosu ustawione w bazie
			if(conflictingService) {
				$scope.data.services[$scope.serviceIndex[conflicts[i]]].state = false;
			}
		}

		$scope.statusModified = true;
	}

	/**
	 * robi łubudubu na usługach żeby pasowały do UI. Uwaga smok.
	 * @param {*} services niezfakapiona lista usług
	 */
	var fuckupServices = function(services) {
		var unitLimitValue = 0;
		var firstDeletedPosition = null;
		var isEnabled = true;
		var firstDeletedservice = null;
		var polishZlotyRegexp = /(\d+,\d+)\szł/;

		hackUnitLimitValues = [];
		mainLimitServiceId = null;
		// domyślnie jeśli nie ma nic state:true to trzeba jakoś wybrać 0
		unitLimitValue = -1;

		// fejkowy id wyłączonej blokady, oznacza ze wszystkie ukryte usługi
		// pod tym selectem są state:false
		hackUnitLimitValues.push({
			service: -1,
			label: 'bez limitu',
			absValue: 0,
		});

		for(var i in services) {
			switch(services[i].type) {
				case 'threshold':
					// chcemy wstawić nasza fake blokade z selectem w miejsce pierwszej
					// blokady progowej żeby było spójnie w UI
					if(firstDeletedPosition === null) {
						firstDeletedPosition = i;
						firstDeletedservice = angular.copy(services[i]);
					}

					isEnabled &= services[i].commited;

					// w ten sposób sprawdzamy co jest wybrane, bo nie chcę hardkodować
					// ID w kodzie. Wiem że parsowanie regexpem ssie, no ale tak ktoś
					// wymyślił backend...
					var matchedValues = services[i].name.match(polishZlotyRegexp);
					if(services[i].state === true) unitLimitValue = services[i].id;

					// wartości oryginalnych blokad w zł trzeba gdzieś upchać wraz
					// z odpowiadającym im id blokady, bo potem musimy wiedzieć
					// którą ustawić na state:true przy zapisywaniu
					hackUnitLimitValues.push({
						service: services[i].id,
						label: matchedValues[0],
						absValue: parseInt(matchedValues[1].replace(',', '.')),
					});

					delete services[i];
				break;
				case 'main_threshold':
					// tą też fejkujemy bo musimy do niej zahardkodować opcje...
					// (albo tu, albo w backendzie; pewnie tu i tu bo trzeba w backendzie
					// jeszcze zwalidować (sgtapi))
					mainLimitServiceId = services[i].id;
					services[i].id = 'mainLimit';
					var options = $scope.getLimitOptions(services[i].id);

					for(var j in options) {
						if(options[j].absValue == services[i].amount) {
							services[i].amount = options[j].service;
							break;
						}
					}
				break;
			}
		}

		hackUnitLimitValues.sort(function(a, b) {
			if(a.absValue > b.absValue) return 1;
			if(a.absValue < b.absValue) return -1;
			return 0;
		});

		// no i wstawiamy w miejsce pierwszej usuniętej nasz fake service z selectem
		services[firstDeletedPosition] = {
			amount: unitLimitValue,
			commited: isEnabled,
			description: firstDeletedservice.description.replace(polishZlotyRegexp, 'wskazana kwota'),
			id: 'unitLimit',
			name: firstDeletedservice.name.replace(polishZlotyRegexp, 'jednostkowej'),
			type: 'main_threshold',
			// zakładam, że wszystkie będa miały te same konflikty,
			// bo to warianty tej samej blokady - tylko z różna kwotą
			conflicts: firstDeletedservice.conflicts,
		};

		return services;
	}

	$scope.change = function(id) {
		$scope.limitSelectorOpen = null;
		var serviceIndex = $scope.serviceIndex[id];

		if(!serviceIndex) return;
		if(id == 99 && $scope.data.restricted) return;

		disableConflicting(id);

		$scope.statusModified = true;
		$scope.data.services[serviceIndex].state = !$scope.data.services[serviceIndex].state;

		$scope.analytics.trackEvent('sim_status', 'service_change', $scope.data.services[serviceIndex].name);
	}

	$scope.getLimitOptions = function(id) {
		switch(id) {
			case 'unitLimit': return hackUnitLimitValues;
			case 'mainLimit': return mainLimitValues;
		}
	}

	$scope.save = function() {
		if(!$scope.checkHasEmail()) return;

		var pop = $scope.popup.create();
		pop.setText('Wprowadzone zmiany będą widoczne za kilka minut. Kontynuować?');
		pop.addButton('Tak', confirmSave, 'yes');
		pop.addButton('Nie', angular.noop, 'no', true);
		$scope.popup.push(pop);

		$scope.analytics.trackEvent('sim_status', 'save');
	}

	$scope.restore = function() {
		if(!$scope.statusModified) return;
		mvno.updateServices();

		$scope.analytics.trackEvent('sim_status', 'restore');
	}

	$scope.toggleOptions = function(id, event) {
		if($scope.limitSelectorOpen === id) {
			$scope.limitSelectorOpen = null;
		} else {
			$scope.limitSelectorOpen = id;
		}

		if(event) {
			event.stopPropagation();
			event.preventDefault();
		}

		$scope.analytics.trackEvent('sim_status', 'service_menu_toggle', id);
	}

	$scope.closeOptions = function() {
		$scope.limitSelectorOpen = null;

		$scope.analytics.trackEvent('sim_status', 'service_menu_close');
	}

	$scope.setLimit = function(id, value) {
		var serviceIndex = $scope.serviceIndex[id];
		if(!serviceIndex) return;

		disableConflicting(id);

		$scope.statusModified = true;
		$scope.data.services[serviceIndex].amount = value;

		$scope.analytics.trackEvent('sim_status', 'limit_set');
	}

	$scope.getLimitLabel = function(id, currentValue) {
		var options = $scope.getLimitOptions(id);

		for(var i in options) {
			if(options[i].service === currentValue) return options[i].label;
		}
	}

	mvno.addObserver('simStatusMvnoObserver', function(type) {
		if(device.getMode() != 'simcard') return;

		switch(type) {
			case 'mvnoChange':
			case 'statusSaveFail':
				$scope.data = {};
				$scope.serviceIndex = {};
				$scope.hasData = false;
				mvno.updateServices();
			break;
			case 'statusChange':
				var data = mvno.getServices();
				data.cooldown = false;

				$scope.serviceIndex = {};

				if(data) {
					data.services = fuckupServices(data.services);

					for(var i in data.services) {
						$scope.serviceIndex[data.services[i].id] = i;
						// jakakolwiek usługa z tą flaga powoduje pytanie o status
						// w interwale cooldownRefreshInterval. Jesli jest cooldown,
						// API nie robi zapytań do PLK - więc można spamować.
						if(!data.services[i].commited) data.cooldown = true;
					}

					$scope.data = data;
					$scope.limitSelectorOpen = null;
					$scope.hasData = Object.keys(data).length > 0;
				}

				$scope.statusModified = false;
				$interval.cancel(cooldownRefreshInterval);

				if(data.cooldown) {
					cooldownRefreshInterval = $interval(mvno.updateServices, 60000);
				}
			break;
		}
	});

	$scope.$on('$destroy', function() {
		mvno.removeObserver('simStatusMvnoObserver');
	});

	if($scope.user.isDemo()) {
		$scope.showLoginPrompt(true);
	} else {
		if(device.has() && device.getMode() === 'simcard') {
			mvno.updateServices();
		}
	}

}]);