angular.module('webpanel').controller('PasswordController', ['$scope', 'api', function($scope, api) {
	var changeType = '';

	$scope.password = { bsReference: '' };
	$scope.newValue = { bsReference: '' };

	$scope.passwordQuestionVisible = false;

	$scope.change = function(what) {
		if(!$scope.checkHasEmail()) return;

		$scope.cancel();
		changeType = what;
	}

	$scope.changeVisible = function(what) {
		if(what === 'any') return !!changeType;
		return what === changeType;
	}

	$scope.confirm = function() {
		$scope.passwordQuestionVisible = true;
	}

	$scope.confirmPassword = function() {
		var pop = $scope.popup.create();

		switch(changeType) {
			case 'jpin':
				api.jPinChange($scope.newValue.bsReference, $scope.password.bsReference).then(function() {
					pop.setText('jPIN został zmieniony.');
					pop.addButton('OK', angular.noop, 'yes', true);
					$scope.popup.push(pop);

					$scope.analytics.trackEvent('user', 'jpin_change');
				}).catch(function() {
					pop.setText('Nie udało się zapisać zmian. Upewnij się, że podano poprawne hasło i że nowy kod jPIN ma cztery znaki numeryczne.');
					pop.addButton('Spróbuj ponownie', angular.noop, 'yes', true);
					$scope.popup.push(pop);
				});
			break
			case 'tvpin':
				api.tvPinChange($scope.newValue.bsReference, $scope.password.bsReference).then(function() {
					pop.setText('tvPIN został zmieniony.');
					pop.addButton('OK', angular.noop, 'yes', true);
					$scope.popup.push(pop);

					$scope.analytics.trackEvent('user', 'tvpin_change');
				}).catch(function() {
					pop.setText('Nie udało się zapisać zmian. Upewnij się, że podano poprawne hasło i że nowy kod tvPIN ma cztery znaki numeryczne.');
					pop.addButton('Spróbuj ponownie', angular.noop, 'yes', true);
					$scope.popup.push(pop);
				});
			break;
			case 'password':
				api.passwordChange($scope.newValue.bsReference, $scope.password.bsReference).then(function() {
					pop.setText('Hasło zostało zmienione.');
					pop.addButton('OK', angular.noop, 'yes', true);
					$scope.popup.push(pop);

					$scope.analytics.trackEvent('user', 'password_change');
				}).catch(function() {
					pop.setText('Nie udało się zapisać zmian. Upewnij się, że podano poprawne hasło i że nowe hasło ma co najmniej 8 znaków, zawiera dużą literę oraz cyfrę.');
					pop.addButton('Spróbuj ponownie', angular.noop, 'yes', true);
					$scope.popup.push(pop);
				});
			break;
		}

		$scope.cancel();
	}

	$scope.cancel = function() {
		changeType = null;
		$scope.passwordQuestionVisible = false;
		$scope.password.bsReference = '';
		$scope.newValue.bsReference = '';
	}

	$scope.cancelConfirmation = function() {
		$scope.passwordQuestionVisible = false;
		$scope.password.bsReference = '';
	}

	if($scope.user.isDemo()) {
		$scope.showLoginPrompt(true);
	}

}]);