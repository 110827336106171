angular.module('webpanel').directive('clickOutside', function($document) {

	return {
		scope: {
			clickOutside: '=',
			coIgnoreNode: '@'
		},

		link: function(scope, element) {
			var handler = function(event) {
				if(typeof scope.coIgnoreNode === 'string') {
					var ignoredNode = document.querySelector(scope.coIgnoreNode);
					if(ignoredNode && event.target === ignoredNode) return;
				}

				if(element !== event.target && !element[0].contains(event.target)) {
					scope.$apply(scope.clickOutside);
				}
			}

			$document.on('mousedown', handler);

			scope.$on('$destroy', function() {
				$document.off('mousedown', handler);
			});
		}
	}
});
