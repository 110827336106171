angular.module('webpanel').controller('DeviceNameController', ['$scope', 'device', 'mode', function($scope, device, mode) {
	$scope.devices = [];
	var currentDevice = null;

	var fetch = function() {
		switch(device.getMode()) {
			case 'stb': $scope.mode = 'default'; break;
			case 'simcard': $scope.mode = 'mvno'; break;
		}

		$scope.devices = device.get();
		currentDevice = device.getSelected();
	}

	device.addObserver('deviceDeviceObserver', function(type) {
		switch(type) {
			case 'change':
			case 'nameChange':
			case 'selectSuccess':
				fetch();
			break;
		}
	});

	$scope.isSelected = function(deviceObj) {
		return deviceObj.id === currentDevice.id;
	}

	$scope.changedDevice = '';
	$scope.newFriendlyName = { bsReference: '' };

	$scope.change = function(device) {
		$scope.changedDevice = device.id;
		$scope.newFriendlyName.bsReference = device.friendlyName;
	}

	$scope.confirm = function() {
		$scope.devices[$scope.changedDevice].friendlyName = $scope.newFriendlyName.bsReference;
		var setNameResult = device.setName($scope.changedDevice, $scope.newFriendlyName.bsReference);

		if(setNameResult) {
			$scope.analytics.trackEvent('device', 'rename_success', $scope.newFriendlyName.bsReference);
		} else {
			var pop = $scope.popup.create();
			pop.setText('Zmiana nazwy urządzenia nie powiodła się. Spróbuj ponownie.');
			pop.addButton('OK', angular.noop, 'yes', true);
			$scope.popup.push(pop);

			$scope.analytics.trackEvent('device', 'rename_fail', $scope.newFriendlyName.bsReference);
		}

		$scope.newFriendlyName.bsReference = '';
		$scope.changedDevice = '';
	}

	$scope.cancel = function() {
		$scope.newFriendlyName.bsReference = '';
		$scope.changedDevice = '';
	}

	$scope.changeVisible = function(deviceId) {
		if(deviceId === 'any') return !!$scope.changedDevice;
		return $scope.changedDevice === deviceId;
	}

	$scope.$on('$destroy', function() {
		device.removeObserver('deviceDeviceObserver');
	});

	if($scope.user.isDemo()) {
		$scope.showLoginPrompt(true);
	} else {
		$scope.mode = mode;
		fetch();
	}

}]);