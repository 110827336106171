angular.module('webpanel').directive('blockTouchMove', function() {
	return {
		link: function(scope, element) {			
			var onTouchMove = function(e) {
				e.preventDefault();
				e.stopPropagation();
				return false;
			}
		
			element.on('touchmove', onTouchMove);
			
			scope.$on('$destroy', function() {
				element.off('touchmove', onTouchMove);
			});
		}
	}
});