angular.module('webpanel').controller('CustomListController', ['$scope', '$rootScope', 'setting', 'asset', 'device', 'api', function($scope, $rootScope, setting, asset, device, api) {
	$scope.assets = {};
	$scope.customList = {};

	$scope.draggedChannel = null;
	$scope.customListModified = false;
	// chrome/webkit nie nadaje pseudoklasy :hover innym elementom niż ten, na którym przycisk
	// myszy został wciśnięty, dopóki przycisk nie zostanie zwolniony.
	$scope.mousedOver = null;

	$scope.mobileSelectVisible = false;
	$scope.mobileSelected = false;
	$scope.tabletCustomMemuVisible = false;

	var tabletCanonicalSelected = null;
	var tabletCustomSelected = null;

	var dragStartedFrom;
	var draggedCustomNumber;

	var listLength = 100;
	var usedIds = [];

	var fetch = function() {
		//nie miel listami jeśli użytkownik edytuje
		if($scope.customListModified) return;

		var assetList = asset.get();
		usedIds = [];

		for(var i=1; i<=listLength; i++) {
			$scope.customList[i] = null;
		}

		for(var j in assetList) {
			if(assetList[j].number <= 100) {
				$scope.customList[assetList[j].number] = assetList[j];
				usedIds.push(assetList[j].sgtid)
			} else {
				$scope.assets[j] = assetList[j];
			}
		}

		markUsed();
	}

	var markUsed = function() {
		for(var k in $scope.assets) {
			$scope.assets[k].used = usedIds.indexOf($scope.assets[k].sgtid) > -1;
		}
	}

	var cleanupDrag = function() {
		draggedCustomNumber = null;
		$scope.draggedChannel = null;
		dragStartedFrom = null;
		$scope.mousedOver = null;
	}

	$scope.dragStartCanonical = function(channel) {
		if(!$scope.checkHasEmail()) return;

		$scope.draggedChannel = channel;
		dragStartedFrom = 'canonical';
	}

	$scope.dragEndCanonical = function() {
		if(dragStartedFrom === 'custom') {
			var index = usedIds.indexOf($scope.draggedChannel.sgtid);
			if(index > -1) {
				usedIds.splice(index, 1);
				markUsed();
			}

			$scope.customListModified = true;
		}

		cleanupDrag();
	}

	$scope.mouseoverCustom = function(index) {
		if($scope.draggedChannel) $scope.mousedOver = index;
	}

	$scope.dragStartCustom = function(channel) {
		if(channel === null) return;
		if(!$scope.checkHasEmail()) return;

		dragStartedFrom = 'custom';
		draggedCustomNumber = channel.number;

		$scope.draggedChannel = channel;
		$scope.customList[channel.number] = null;

	}

	$scope.dragEndCustom = function(position, channel) {
		if(!$scope.draggedChannel) return;

		// trzeba pozbyć się referencji w $scope.draggedChannel, w przeciwnym
		// razie zmiany nastąpią także w oryginalnej liście
		$scope.customList[position] = angular.copy($scope.draggedChannel);
		$scope.customList[position].number = position;

		if(channel !== null && dragStartedFrom === 'custom' && draggedCustomNumber < 101) {
			$scope.customList[draggedCustomNumber] = channel;
			$scope.customList[draggedCustomNumber].number = draggedCustomNumber;
		}

		if(dragStartedFrom === 'canonical') {
			// mogą wystąpić wielokrotnie
			usedIds.push($scope.draggedChannel.sgtid);

			if(channel) {
				// jeśli zastępujemy istniejący, skasuj wystapienie
				var index = usedIds.indexOf(channel.sgtid);
				if(index > -1) usedIds.splice(index, 1);
			}

			markUsed();
		}

		// żeby nie nadpisać zmian przy updacie
		$scope.customListModified = true;

		cleanupDrag();
	}

	$scope.cancel = function() {
		if(dragStartedFrom === 'custom') {
			$scope.customList[draggedCustomNumber] = $scope.draggedChannel;
		}

		cleanupDrag();
	}

	$scope.restoreList = function() {
		// tutaj celowo ignorujemy wprowadzone zmiany
		$scope.customListModified = false;
		asset.update();
		$scope.analytics.trackEvent('customlist', 'restore');
	}

	$scope.clearList = function() {
		if(!$scope.checkHasEmail()) return;

		$scope.customListModified = true;

		for(var i in $scope.customList) {
			$scope.customList[i] = null;
		}

		usedIds = [];
		markUsed();

		$scope.analytics.trackEvent('customlist', 'clear');
	}

	$scope.defaultList = function() {
		if(!$scope.checkHasEmail()) return;

		$scope.customListModified = true;

		var newList = {}
		usedIds = [];

		for(var i in $scope.assets) {
			if($scope.assets[i].default_number) {
				var asset = angular.copy($scope.assets[i]);

				asset.number = asset.default_number;
				newList[asset.number] = asset;

				usedIds.push(asset.sgtid);
			}
		}

		for(var j=1; j<=100; j++) {
			if(typeof newList[j] === 'undefined') newList[j] = null;
		}

		$scope.customList = newList;
		markUsed();

		$scope.analytics.trackEvent('customlist', 'default');
	}

	$scope.saveList = function() {
		if(!$scope.checkHasEmail()) return;

		var serializedListArray = [];

		for(var i=1; i<101; i++) {
			var channelId = -1;

			if(typeof $scope.customList[i] !== 'undefined' && $scope.customList[i] !== null) {
				channelId = $scope.customList[i].sgtid;
			}

			serializedListArray.push(channelId);
		}

		setting.set('customlist', serializedListArray.join(','), asset.update);
		$scope.customListModified = false;

		$scope.analytics.trackEvent('customlist', 'save');

		var pop = $scope.popup.create();
		pop.setText('Aby zmiany były widoczne na urządzeniu, należy je zrestartować (funkcja w wersji beta; w przyszłości restart nie będzie wymagany).');
		pop.addButton('Zrestartuj urządzenie', $scope.restart, 'yes');
		pop.addButton('Nie teraz', angular.noop, 'no', true);
		$scope.popup.push(pop);
	}

	$scope.restart = function() {
		api.deviceReboot().then(function() {
			$scope.analytics.trackEvent('device', 'restart', device.getSelected().id);
		}).catch(function() {
			var pop = $scope.popup.create();
			pop.setText('Restart nie powiódł się. Spróbuj ponownie.');
			pop.addButton('OK', angular.noop, 'yes', true);
			$scope.popup.push(pop);
		});
	}

	// ----------------- interfejs mobilny ------------------

	$scope.chooseChannel = function(index) {
		$scope.mobileSelectVisible = true;
		$scope.mobileSelected = index;
		$scope.tabletCustomMemuVisible = false;
	}

	$scope.placeChannel = function(channel) {
		if(!channel) return;

		var position = $scope.mobileSelected+1;
		var previousChannel = $scope.customList[position];

		$scope.customList[position] = angular.copy(channel);
		$scope.customList[position].number = position;

		usedIds.push(channel.sgtid);

		if(previousChannel) {
			var index = usedIds.indexOf(previousChannel.sgtid);
			if(index > -1) usedIds.splice(index, 1);
		}

		markUsed();

		$scope.customListModified = true;
		$scope.endChoice();
	}

	$scope.clearPosition = function() {
		try {
			if($scope.tabletCustomMemuVisible) {
				var channel = $scope.customList[tabletCustomSelected+1];
				$scope.customList[tabletCustomSelected+1] = null;
			} else if($scope.mobileSelectVisible) {
				var channel = $scope.customList[$scope.mobileSelected+1];
				$scope.customList[$scope.mobileSelected+1] = null;
			}

			var index = usedIds.indexOf(channel.sgtid);
			if(index > -1) {
				usedIds.splice(index, 1);
				markUsed();
			}
		} catch(e) {
			// pusta pozycja
			$scope.endChoice();
			return;
		}

		$scope.customListModified = true;
		$scope.endChoice();
	}

	$scope.endChoice = function() {
		$scope.mobileSelectVisible = false;
		$scope.mobileSelected = null;

		tabletCanonicalSelected = null;
		tabletCustomSelected = null;
		$scope.tabletCustomMemuVisible = false;
	}

	// ----------------- interfejs tabletowy ------------------

	$scope.clickCanonical = function(channel) {
		$scope.tabletCustomMemuVisible = false;
		tabletCanonicalSelected = channel;
	}

	$scope.clickCustom = function(position) {
		if(tabletCanonicalSelected) {
			if(position < 0) return;

			var actualPosition = position+1; // 1-indexed
			var previousChannel = $scope.customList[actualPosition];

			$scope.customList[actualPosition] = angular.copy(tabletCanonicalSelected);
			$scope.customList[actualPosition].number = actualPosition;

			usedIds.push(tabletCanonicalSelected.sgtid);

			if(previousChannel) {
				var index = usedIds.indexOf(previousChannel.sgtid);
				if(index > -1) usedIds.splice(index, 1);
			}

			markUsed();
			$scope.customListModified = true;
			$scope.endChoice();
		} else {
			if($scope.customList[position+1]) {
				tabletCustomSelected = position;
				$scope.tabletCustomMemuVisible = true;
			} else {
				$scope.endChoice();
			}
		}
	}

	// ------------------ eksport/import ---------------------

	$scope._export = function(__injectAnchorNode) {
		var flattenedList = {};

		for(var i in Object.keys($scope.customList)) {
			if(typeof $scope.customList[i] !== 'undefined' && $scope.customList[i] !== null && typeof $scope.customList[i].sgtid !== 'undefined') {
				flattenedList[i] = $scope.customList[i].sgtid;
			}
		}

		var stringifiedList = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(flattenedList));
		var anchorNode = (__injectAnchorNode instanceof HTMLAnchorElement) ? __injectAnchorNode : document.createElement('a');

		anchorNode.setAttribute('href', stringifiedList);
		anchorNode.setAttribute('download', 'Lista 100 - '+device.getSelected().id+'.json');

		anchorNode.dispatchEvent(new MouseEvent('click'));

		if(__injectAnchorNode instanceof HTMLAnchorElement) {
			return anchorNode;
		} else {
			anchorNode.remove();
		}
	}

	$scope._import = function(fileContents) {
		try {
			var flattenedList = JSON.parse(fileContents);

			if(Object.keys(flattenedList).length < 1) {
				var pop = $scope.popup.create();
				pop.setText('Załadowana lista nie zawiera żadncyh pozycji.');
				pop.addButton('OK', angular.noop, 'yes', true);
				$scope.popup.push(pop);
				return;
			}

			var expandedList = {};
			var existingAsset;

			usedIds = [];

			// lista zaczyna się od 1
			for(var i=1; i<=listLength; i++) {
				expandedList[i] = null;
			}

			for(var position in expandedList) {
				if(position < 1 || position > 100) continue;
				existingAsset = false;

				for(var asset in $scope.assets) {
					if($scope.assets[asset].sgtid == flattenedList[position]) {
						existingAsset = angular.copy($scope.assets[asset]);

						// trzeba ustawić te wartości, inaczej kanał nie będzie usunięty po przeciągnięciu
						existingAsset.number = position;
						existingAsset.used = true;

						break;
					}
				}

				if(existingAsset) {
					expandedList[position] = existingAsset;
					usedIds.push(existingAsset.sgtid);
				}
			}

			$scope.customList = expandedList;
			$scope.customListModified = true;
			markUsed();
		} catch(e) {
			var pop = $scope.popup.create();
			pop.setText('Import nie powiódł się. Upewnij się, że został wybrany prawidłowy plik Mojej Listy 100.');
			pop.addButton('OK', angular.noop, 'yes', true);
			$scope.popup.push(pop);
		}

	}

	// -------------------------------------------------------

	asset.addObserver('customListAssetObserver', function(type) {
		switch(type) {
			case 'change':
				fetch();
			break;
		}
	});

	device.addObserver('customListDeviceObserver', function(type) {
		if(device.getMode() != 'stb') return;

		switch(type) {
			case 'selectSuccess':
				$scope.restoreList();
				asset.update();
			break;
		}
	});

	device.addObserver('watchDeviceObserver', function(type) {
		if($scope.user.isDemo()) return;
		if (type === 'selectSuccess' && !$rootScope.access.list100) return $scope.location.path('/');

	});

	$scope.$on('$destroy', function() {
		asset.removeObserver('customListAssetObserver');
		device.removeObserver('customListDeviceObserver');
		device.removeObserver('watchDeviceObserver');
	});

	if($scope.user.isDemo()) {
		$scope.showLoginPrompt(true);
	} else {
		asset.update();
	}

}]);