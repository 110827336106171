angular.module('webpanel').controller('OrderPinController', ['$scope', 'api', function($scope, api) {
	$scope.termsAccepted = { bsReference: false }
	$scope.jpin = { bsReference: null }

	$scope.orderInProgress = false;

	$scope.defaultSuccessMsg = 'Dziękujemy za dokonanie zakupu. Pakiet będzie dostępny w ciągu kilku minut. '+
	'Jeśli po tym czasie nie pojawi się na liście aktywnych usług, proszę zrestartować dekoder.';

	$scope.cancel = function() {
		$scope.analytics.trackEvent('order', 'cancel', $scope.selectedPackage.name);

		$scope.termsAccepted.bsReference = false;
		$scope.jpin.bsReference = null;

		$scope.orderOnCancel();
	}

	var success = function() {
		$scope.analytics.trackEvent('order', 'complete', $scope.selectedPackage.name);

		$scope.termsAccepted.bsReference = false;
		$scope.jpin.bsReference = null;

		$scope.orderOnSuccess();
	}

	$scope.order = function() {
		$scope.orderInProgress = true;

		api.packageOrder(parseInt($scope.selectedPackage.id), $scope.jpin.bsReference).then(function() {
			var pop = $scope.popup.create();

			pop.setText($scope.successMsg || $scope.defaultSuccessMsg);
			pop.addButton('OK', angular.noop, 'yes', true);

			$scope.popup.push(pop);

			var transactionId = Date.now();
			$scope.analytics.addTrans(transactionId, 'Jambox', $scope.selectedPackage.price);
			$scope.analytics.addItem(transactionId, $scope.selectedPackage.id, $scope.selectedPackage.name, '', $scope.selectedPackage.price, 1);
			$scope.analytics.trackTrans();

			$scope.orderInProgress = false;
			success();
		}).catch(function(response) {
			var pop = $scope.popup.create();
			
			var errorText = 'Przepraszamy, zakup nie powiódł się. Prawdopodobnie wprowadzono niepoprawny kod jPIN.';
			if(response.data.code === 402 && response.data.message === 'customer is suspended') {
				errorText = 'Nie można zrealizować zamówienia z powodu blokady windykacyjnej';
			}

			pop.setText(errorText);
			pop.addButton('Spróbuj ponownie', angular.noop, 'yes', true);

			$scope.analytics.trackEvent('order', 'fail', $scope.selectedPackage.name);

			$scope.jpin.bsReference = null;
			$scope.termsAccepted.bsReference = false;
			$scope.orderInProgress = false;

			$scope.popup.push(pop);
		});
	}

}]);