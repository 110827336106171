angular.module('webpanel').controller('TvOrderController', ['$scope', '$routeParams', 'asset', 'service', 'api', function($scope, $routeParams, asset, service, api) {
	$scope.assets = [];
	$scope.packageList = [];

	$scope.percentageComplete = 0;
	$scope.packageOrderList = [];

	$scope.selectedAssetId = -1;
	$scope.selectedPackage = null;

	var assetsReady = false;
	var filter;

	var getAssets = function() {
		$scope.assets = [];
		$scope.percentageComplete = 0;

		if(!asset.has()) asset.update();

		var assets = asset.get();
		var entitledCount = 0;
		var totalCount = 0;

		if(assets.length) {
			for(var i=0; i<assets.length; i++) {
				if(assets[i].number < 101) continue;

				if(assets[i].entitled) entitledCount++;
				totalCount++;
				$scope.assets.push(assets[i]);
			}

			$scope.percentageComplete = Math.ceil(entitledCount/totalCount*100);
		}

		assetsReady = true;
	}

	var load = function() {
		if(assetsReady) return;
		getAssets();

		if(filter && filter.length) {
			$scope.analytics.trackEvent('order', 'load_filter', filter.join(','));

			api.servicesGetForOrder('filter', filter).then(function(response) {
				if(response.data.length) {
					if(response.data.length === 1) {
						$scope.clearOrderList();
						$scope.orderPackage(response.data[0]);
					} else {
						$scope.packageOrderList = response.data;
					}
				} else {
					var pop = $scope.popup.create();
					pop.setText('Nie znaleziono żadnych pakietów pasujących do użytego filtra.');
					pop.addButton('OK', angular.noop, 'yes', true);
					$scope.popup.push(pop);

					$scope.clearOrderList();
					$scope.analytics.trackEvent('order', 'filter_none');
				}
			}).catch(function() {
				var pop = $scope.popup.create();
				pop.setText('Wystapił błąd podczas pobierania listy pakietów. Spróbuj ponownie.');
				pop.addButton('OK', angular.noop, 'yes', true);
				$scope.popup.push(pop);

				$scope.clearOrderList();
				$scope.analytics.trackEvent('order', 'filter_error');
			});
		}
	}

	var showNoPackagePopup = function() {
		var pop = $scope.popup.create();

		pop.setText('Nie znaleziono żadnych pakietów zawierających ten kanał. '+
			'Skontaktuj się ze swoim Operatorem i zapytaj o możliwość zakupu wybranego kanału.');
		pop.addButton('Przejdź do danych Operatora', function() {
			$scope.location.path('/twoje-konto/twoje-dane');
		}, 'yes', true);
		pop.addButton('Nie teraz', angular.noop, 'no');

		$scope.popup.push(pop);
		$scope.clearOrderList();
	}

	var showPackagesWithAsset = function(asset) {
		if(typeof asset !== 'undefined') {
			if(asset.entitled) return;

			api.servicesGetWithAsset(asset.sgtid).then(function(response) {
				if(!response.data.length || response.status === 204) {
					showNoPackagePopup();
					$scope.analytics.trackEvent('order', 'no_package_asset', asset.name);
					return;
				}

				api.servicesGetForOrder('filter', response.data).then(function(secondResponse) {
					if(!secondResponse.data.length) {
						showNoPackagePopup();
						$scope.analytics.trackEvent('order', 'no_package_filter', asset.name);
						return;
					} else if(secondResponse.data.length === 1) {
						$scope.clearOrderList();
						$scope.orderPackage(secondResponse.data[0]);
						$scope.analytics.trackEvent('order', 'show_single_package', asset.name);
					} else {
						$scope.packageOrderList = secondResponse.data;
						$scope.analytics.trackEvent('order', 'show_packages', asset.name);
					}
				}).catch(function() {
					var pop = $scope.popup.create();
					pop.setText('Wystapił błąd podczas pobierania listy pakietów. Spróbuj ponownie.');
					pop.addButton('OK', angular.noop, 'yes', true);
					$scope.popup.push(pop);

					$scope.analytics.trackEvent('order', 'error_filter', asset.name);
				});
			}).catch(function() {
				var pop = $scope.popup.create();
				pop.setText('Wystapił błąd podczas pobierania listy pakietów. Spróbuj ponownie.');
				pop.addButton('OK', angular.noop, 'yes', true);
				$scope.popup.push(pop);

				$scope.analytics.trackEvent('order', 'error_package', asset.name);
			});
		} else {
			api.servicesGetForOrder().then(function(response) {
				$scope.packageOrderList = response.data;
				$scope.analytics.trackEvent('order', 'show_all_packages');
			}).catch(function() {
				var pop = $scope.popup.create();
				pop.setText('Wystapił błąd podczas pobierania listy pakietów. Spróbuj ponownie.');
				pop.addButton('OK', angular.noop, 'yes', true);
				$scope.popup.push(pop);

				$scope.analytics.trackEvent('order', 'error', 'all');
			});
		}
	}

	var parseFilter = function(urlParam) {
		filter = urlParam.split(',');
	}

	$scope.loadPackages = function(asset) {
		var assetName = 'all';

		if(typeof asset !== 'undefined') {
			if(asset.entitled) return;
			$scope.selectedAssetId = asset.sgtid;
			assetName = asset.name;
		}

		showPackagesWithAsset(asset);
		$scope.analytics.trackEvent('order', 'view', assetName);
	}

	$scope.clearOrderList = function() {
		$scope.packageOrderList = [];
		$scope.selectedAssetId = -1;
	}

	$scope.orderPackage = function(_package) {
		if(!$scope.checkHasEmail()) return;
		$scope.selectedPackage = _package;

		$scope.analytics.trackEvent('order', 'select', _package.name);
	}

	$scope.getRepeats = function(count) {
		var repeats = [];

		for(var i=0; i<count; i++) {
			repeats[i] = i;
		}

		return repeats;
	}

	$scope.orderOnCancel = function() {
		$scope.selectedPackage = null;
	}

	$scope.orderOnSuccess = function() {
		$scope.selectedPackage = null;
		$scope.clearOrderList();
	}

	service.addObserver('tvOrderServiceObserver', function(type, data) {
		switch(type) {
			case 'orderListAvailable':
				$scope.packageList = data;
			break;
		}
	});

	asset.addObserver('tvOrderAssetObserver', function(type) {
		if($scope.user.isDemo()) return;

		switch(type) {
			case 'change':
				assetsReady = false;

				if(typeof $routeParams.orderId !== 'undefined') {
					parseFilter($routeParams.orderId);
				}

				load();
			break;
		}
	});

	$scope.$on('$destroy', function() {
		asset.removeObserver('tvOrderAssetObserver');
		service.removeObserver('tvOrderServiceObserver');
	});

	if($scope.user.isDemo()) {
		$scope.showLoginPrompt(true);
	} else {
		if(!asset.has()) {
			asset.update();
		} else {
			if(typeof $routeParams.orderId !== 'undefined') {
				parseFilter($routeParams.orderId);
			}

			load();
		}
	}

}]);