angular.module('webpanel').controller('EpgController', ['$scope', '$rootScope', '$routeParams', '$interval', '$timeout', 'epg', 'asset', 'recording', 'setting', 'ott', 'device',
	function($scope, $rootScope, $routeParams, $interval, $timeout, epg, asset, recording, setting, ott, device) {

	$scope.assets = {};
	$scope.times = {};

	$scope.currentTimeLine = -1;
	$scope.timeLineHeight = 0;

	$scope.weekday = 'Dzisiaj';

	$scope.now = Math.floor(Date.now()/1000);
	$scope.chosenBrick = null;

	$scope.assetNames = [];
	$scope.assetNameFilter = null;
	$scope.assetsFilteredEmSize = 0;
	$scope.quickSearchVisisble = false;
	$scope.assetNamesFiltered = [];

	$scope.extraContentStyle = {
		height: 'calc(100% + {{clientRect.scrollbar}}px)',
	}

	var firstRender = true;

	var assetIndex = {}; // sgtid -> number
	var scrollState = 'idle';

	var leftTimestamp = 0;
	var firstDrawTimeout;

	var scrollOffsetLeft = 0;
	var scrollOffsetWidth = 0;

	var unit = 0; // px na sekundę
	var deferredDrawList = [];
	var visibleLogos = [];
	var drawnBricksIndex = [];
	var clickedBrick;

	var storedAsset = null;
	var storedBrickOffset = null;
	var storedBrickTimestamp = null;

	var shouldScrollToAssetPosition = false;
	var shouldUpdateBrickReference = false;
	var hasScrolledToTimeline = false;
	var updateTimeLineDeferer;

	var leftmostTimestamp = Math.floor(Date.now()/5400000)*5400;

	var weekdays = ['Niedziela', 'Poniedz.', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'];
	var recordings = {};

	var screenRange = 10800;
	var rightBorder = 3600;

	// ----------------------------------------------

	$scope.draw = function(assetId, inView) {
		switch(scrollState) {
			case 'scrolling':
				if(inView) {
					if(deferredDrawList.indexOf(assetId) < 0) {
						deferredDrawList.push(assetId);
					}
				} else {
					var indexScrolling = deferredDrawList.indexOf(assetId);
					if(indexScrolling > -1) deferredDrawList.splice(indexScrolling, 1);
				}
			break;
			case 'idle':
				if(inView) {
					if(deferredDrawList.indexOf(assetId) < 0) {
						deferredDrawList.push(assetId);
					}
				} else {
					var indexIdle = deferredDrawList.indexOf(assetId);
					if(indexIdle > -1) deferredDrawList.splice(indexIdle, 1);
				}

				draw();
			break;
		}

		renderLogo(assetId, inView);
	}

	var renderLogo = function(uid, inView) {
		var index = visibleLogos.indexOf(uid);

		if(inView) {
			// wrzuć tylko jak nie ma bo będzie fakap z duplikatami
			if(index < 0 ) visibleLogos.push(uid);
		} else {
			if (index > -1) visibleLogos.splice(index, 1);
		}
	}

	$scope.logoVisible = function(uid) {
		return (visibleLogos.indexOf(uid) > -1);
	}

	$scope.brickMouseDown = function(assetNumber, brick) {
		clickedBrick = angular.copy(brick);
		clickedBrick.assetNumber = assetNumber;
	}

	$scope.brickMouseUp = function(event, ignoreSameBrickClose) {
		ignoreSameBrickClose = (typeof ignoreSameBrickClose === 'undefined') ? false : !!ignoreSameBrickClose;

		if(typeof event !== 'undefined') {
			// na mobilnym jest bug powodujący wysyłanie mousedown do klocka pod etykietą z logo/nazwą kanału
			// i nie pomaga preventDefault na ng-click bo to jest mousedown...
			var targetAttribs = [];
			for(var i in event.target.attributes) {
				targetAttribs.push(event.target.attributes[i].name);
			}

			if(targetAttribs.indexOf('ng-mousedown') < 0) return;
		}

		// czasami event mouseup przychodzi zanim clickedBrick zdąży się ustawić.
		var brickLifecycleHackTimeout = $timeout(function() {
			$timeout.cancel(brickLifecycleHackTimeout);

			if(typeof clickedBrick !== 'undefined') {
				// jesli kliknięto ten sam klocek, zamnkij podgląd
				if($scope.chosenBrick && clickedBrick.epg_id === $scope.chosenBrick.epg_id && !ignoreSameBrickClose) {
					$scope.closePane();
					return;
				}

				$scope.hideQuickSearch();

				$scope.chosenBrick = angular.copy(clickedBrick);
				var description = clickedBrick.description.split('***');
				storedAsset = clickedBrick.assetId;
				storedBrickOffset = clickedBrick.left;

				$scope.chosenBrick.descriptionOne = description[0];
				$scope.chosenBrick.descriptionTwo = description[1];

				$scope.chosenBrick.entitled = $scope.assets[clickedBrick.assetNumber].entitled;
				$scope.chosenBrick.recordingAvailable = recording.isAvailable(clickedBrick.assetId);

				try {
					$scope.chosenBrick.canPlay = ($scope.assets[clickedBrick.assetNumber].url.hlsAac.length > 10) 
						|| ($scope.hasMediaSource && $scope.assets[clickedBrick.assetNumber].alternate_id.vectra_uuid);
				} catch(e) {
					$scope.chosenBrick.canPlay = false;
				}

				$scope.analytics.trackEvent('epg', 'brick_select', $scope.chosenBrick.name);
			}
		}, 0);
	}

	$scope.brickMouseMove = function(event) {
		var movement = Math.sqrt(Math.pow(event.movementX, 2) + Math.pow(event.movementY, 2));

		// pozwól na odrobinę ruchu podczas kliknięcia bo nie każdy
		// ma zdolności manualne pro graczy w CSGO
		if(movement > 10) {
			clickedBrick = undefined;
		}
	}

	$scope.closePane = function() {
		$scope.chosenBrick = null;

		shouldUpdateBrickReference = false;
		shouldScrollToAssetPosition = false;
		storedAsset = null;
		storedBrickOffset = null;
		storedBrickTimestamp = null;

		$scope.analytics.trackEvent('epg', 'brick_close_button');
	}

	$scope.scrollStart = function() {
		scrollState = 'scrolling';
	}

	$scope.scrollEnd = function(offsetLeft, _, offsetWidth, windowInnerWidth) {
		if(windowInnerWidth <= 1440) rightBorder = 3000;
		if(windowInnerWidth <= 600) rightBorder = 1500;
		if(windowInnerWidth < 1280) {
			screenRange = 5400;
		} else {
			screenRange = 10800;
		}

		scrollOffsetLeft = offsetLeft;

		if(firstRender) {
			scrollOffsetWidth = offsetWidth;
			firstRender = false;
		} else {
			scrollOffsetWidth = offsetWidth-$scope.clientRect.scrollbar;
		}

		scrollState = 'idle';
		calculateTimestamps();
		deferredDraw();
	}

	$scope.visible = function(assetId) {
		return deferredDrawList.indexOf(assetId) > -1;
	}

	$scope.schedule = function(brick, type) {
		if(!$scope.checkHasEmail()) return;

		var assetNumber = brick.assetNumber;
		var program = [];
		var programPosition = 0;

		for(var i in $scope.assets[assetNumber].grid) {
			if($scope.assets[assetNumber].grid[i].epg_id === brick.epg_id) {
				programPosition = i;
				program = $scope.assets[assetNumber].grid[i];
				break;
			}
		}

		switch(type) {
			case 'autozap':
				if(program.schedule == 2) {
					program.schedule = 0;
					$scope.analytics.trackEvent('autozap', 'remove', brick.name);
				} else {
					program.schedule = 2;
					$scope.analytics.trackEvent('autozap', 'add', brick.name);
				}
			break;
			case 'remind':
				if(program.schedule == 3) {
					program.schedule = 0;
					$scope.analytics.trackEvent('remind', 'remove', brick.name);
				} else {
					program.schedule = 3;
					$scope.analytics.trackEvent('remind', 'add', brick.name);
				}
			break;
		}

		$scope.assets[assetNumber].grid[programPosition] = program;

		if($scope.chosenBrick && $scope.chosenBrick.epg_id == program.epg_id) {
			$scope.chosenBrick.schedule = program.schedule;
		}

		var autozapList = setting.getAutozap();
		var scheduleExists = false;

		for(var entry in autozapList) {
			var autozapData = autozapList[entry].split(',');

			if(parseInt(autozapData[0]) < $scope.now) { // start > now
				autozapList.splice(entry, 1);
				continue;
			}

			if(autozapData[2] == brick.assetId && autozapData[4] == program.epg_id) {
				scheduleExists = true;

				if(program.schedule === 0) {
					autozapList.splice(entry, 1);
				} else {
					autozapData[3] = program.schedule;
					autozapList[entry] = autozapData.join(',');
				}
				break;
			}
		}

		if(!scheduleExists) {
			var newAutozapData = [program.start, program.end, brick.assetId, program.schedule, program.epg_id];
			autozapList.push(newAutozapData.join(','));
		}

		setting.setAutozap(autozapList);
	}

	$scope.record = function(brick) {
		if(!$scope.checkHasEmail()) return;

		recording.add(brick.assetId, brick.start, brick.end, brick.epg_id, brick.name);
		$scope.analytics.trackEvent('recording', 'add', brick.name);
	}

	$scope.recordDelete = function(brick) {
		if(!$scope.checkHasEmail()) return;

		if(typeof brick.recording === 'undefined' || typeof brick.recording.id === 'undefined') return;
		recording.remove(brick.recording.id);

		$scope.analytics.trackEvent('recording', 'delete', brick.name);
	}

	$scope.recordStop = function(brick) {
		if(!$scope.checkHasEmail()) return;

		if(typeof brick.recording === 'undefined' || typeof brick.recording.id === 'undefined') return;
		recording.change(brick.recording.id, $scope.now);

		$scope.analytics.trackEvent('recording', 'stop', brick.name);
	}

	$scope.recordResume = function(brick) {
		if(!$scope.checkHasEmail()) return;

		if(typeof brick.recording === 'undefined' || typeof brick.recording.id === 'undefined') return;
		recording.change(brick.recording.id, 0);

		$scope.analytics.trackEvent('recording', 'resume', brick.name);
	}

	$scope.watchLive = function() {
		var asset = $scope.assets[$scope.chosenBrick.assetNumber];

		if($scope.user.isDemo()) {
			$scope.showLoginPrompt();
			$scope.analytics.trackEvent('epg', 'watch_unauthorized', asset.name);
			return;
		}

		$scope.analytics.trackEvent('epg', 'watch_live', asset.name);
		if(!$scope.checkHasEmail()) return;

		ottPlay(asset.id, asset.name, asset.url.hlsAac);
	}

	$scope.watchLiveChannel = function(id, name, url) {
		if($scope.user.isDemo()) {
			$scope.showLoginPrompt();
			$scope.analytics.trackEvent('epg', 'watch_unauthorized', name);
			return;
		}

		$scope.analytics.trackEvent('epg', 'watch_live_channel', name);
		if(!$scope.checkHasEmail()) return;

		ottPlay(id, name, url.hlsAac);
	}

	$scope.showQuickSearch = function() {
		$scope.quickSearchVisisble = true;
	}

	$scope.hideQuickSearch = function() {
		$scope.quickSearchVisisble = false;
	}

	$scope.scrollToAsset = function(id, number) {
		$scope.quickSearchVisisble = false;
		scrollToAsset(id, number);
	}

	// ----------------------------------------------

	var draw = function() {
		$timeout.cancel(firstDrawTimeout);
		firstDrawTimeout = $timeout(deferredDraw, 100);
	}

	var deferredDraw = function() {
		// możliwe, że nie ma nic do rysowania
		//console.log(new Date(leftTimestamp*1000).toLocaleString());

		if(deferredDrawList.length) {
			var sgtIdList = [];
			for(var i in deferredDrawList) {
				var sgtId = deferredDrawList[i].split('/')[0];
				sgtIdList.push(parseInt(sgtId));
			}

			epg.query(sgtIdList, leftTimestamp);
			//console.log('will draw deferred '+deferredDrawList);
		}
	}

	var calculateTimestamps = function() {
		unit = screenRange/(scrollOffsetWidth-8*$scope.clientRect.width);
		leftTimestamp = Math.floor(((leftmostTimestamp+scrollOffsetLeft*unit)/5400))*5400;

		var now = new Date();
		var relative = new Date(leftTimestamp*1000);

		var currentWeekDay = now.getDay();
		var relativeWeekDay = relative.getDay();

		if(currentWeekDay == relativeWeekDay) {
			$scope.weekday = 'Dzisiaj';
		} else if(currentWeekDay+1 == relativeWeekDay) {
			$scope.weekday = 'Jutro';
		} else if(currentWeekDay+2 == relativeWeekDay) {
			$scope.weekday = 'Pojutrze';
		} else {
			$scope.weekday = weekdays[relativeWeekDay];
		}
	}

	var updateTimeLine = function() {
		$timeout.cancel(updateTimeLineDeferer);

		updateTimeLineDeferer = $timeout(function() {
			var now = Math.floor(Date.now()/1000);
			$scope.currentTimeLine = Math.floor((now-leftmostTimestamp)/unit);

			// scrollujemy do tajmlajna tylko na mobilnym
			if($scope.currentTimeLine > -1) {
				if(!hasScrolledToTimeline && screenRange < 5400) {
					scrollToTimeLine();
				}
				hasScrolledToTimeline = true;
			}

			for(var asset in $scope.assets) {
				if(deferredDrawList.indexOf($scope.assets[asset].uid) > -1) {
					for(var brick in $scope.assets[asset].grid) {
						var brickObj = $scope.assets[asset].grid[brick];
						brickObj.current = (brickObj.start < now && brickObj.end > now);
						brickObj.past = brickObj.end < now;
					}
				}
			}
		}, 15);
	}

	var getAssets = function() {
		var assets = asset.get();
		var pvrEnabled = recording.getAvailable();

		$scope.assetNames = [];
		$scope.assets = {};
		assetIndex = {};

		for(var i in assets) {
			var number = assets[i].number;

			if(typeof assetIndex[assets[i].sgtid] === 'undefined') {
				assetIndex[assets[i].sgtid] = [];
			}

			assetIndex[assets[i].sgtid].push(number);

			$scope.assets[number] = {
				id: assets[i].sgtid,
				number: number,
				name: assets[i].name,
				nameEncoded: assets[i].name_encoded,
				grid: [],
				uid: assets[i].sgtid+'/'+number,
				entitled: assets[i].entitled,
				url: assets[i].url,
				features: {
					jpvr: (pvrEnabled.indexOf(assets[i].sgtid) > -1),
					ott: (assets[i].url && Object.keys(assets[i].url).length > 0),
				}
			}

			$scope.assetNames.push({
				name: assets[i].name,
				id: assets[i].sgtid,
				number: number,
				// do szukania po numerze i nazwie na raz. Pusty string żeby nie próbował castować na int
				nameAndNumber: assets[i].name+''+number,
			});
		}

		calculateTimestamps();
		deferredDraw();

		for(var assetNumber in $scope.assets) {
			// jak mamy w cache kanał którego nie otrzymaliśmy z api, to go usuń
			if(typeof assetIndex[$scope.assets[assetNumber].id] === 'undefined') {
				delete $scope.assets[assetNumber];
			}
		}

		setTimeout(function() {
			// storedAsset będzie wypełniony jeśli opis jest widoczny. Po zmianie assetów spróbuj
			// przescrollować do assetu który jest otwarty, i podmień referencję do klocka na nowy obiekt
			// z nowej siatki (bo stara poleciała w kosmos po zmianie assetów).
			if(shouldScrollToAssetPosition && storedAsset !== null) {
				scrollToAsset(storedAsset);
			}

			if(storedBrickOffset) {
				scrollToBrick(storedBrickOffset);
			} else if(storedBrickTimestamp) {
				scrollToBrick((storedBrickTimestamp-leftmostTimestamp)/unit);
			}
		}, 0);

		$scope.timeLineHeight = 7*Object.keys($scope.assets).length;
	}

	var scrollToAsset = function(id, number) {
		// mogę zrobić dyrektywę, która będzie obserwować wartość i aktualizować
		// offsetTop, albo moge to zaimplementować tutaj bez dodatkowych observerów...
		// epg__grid już zdaje się ma jakieś dyrektywy scrollujące więc mógłby być fakap
		try {
			if(typeof number !== 'undefined') {
				var element = document.querySelector('#epg__asset__'+id+'.epg__asset-number__'+number);
			} else {
				var element = document.getElementById('epg__asset__'+id);
			}

			var grid = document.querySelector('.epg__grid .ss-content');

			if(element && grid) {
				element.scrollIntoView();
				grid.scrollTop -= (3.8 * $scope.clientRect.width);
			}
		} catch(e) {
			// console.log('element or grid not found or failed to set scroll props');
		}
	}

	var scrollToBrick = function(offsetLeft) {
		if(offsetLeft < 0) return;
		//console.log('scroll to brick '+offsetLeft);

		try {
			document.querySelector('.epg__grid .ss-content').scrollLeft = offsetLeft;
		} catch(e) {
			// console.log('failed to set scroll props');
		}
	}

	var scrollToTimeLine = function() {
		if($scope.currentTimeLine < 0) return;

		var chunkSize = (scrollOffsetWidth-8*$scope.clientRect.width);
		var containingChunkStart = Math.floor($scope.currentTimeLine/chunkSize)*chunkSize;

		try {
			document.getElementsByClassName('epg__grid')[0].scrollLeft = containingChunkStart;
		} catch(e) {
			// console.log('failed to set scroll props');
		}
	}

	var updateRecordings = function() {
		var recordingList = recording.get();
		recordings = {};

		for(var id in recordingList) {
			recordings[recordingList[id].rec_contentid] = recordingList[id];
		}
	}

	var decorateRecording = function(brick, recording) {
		var recordingFrom = parseInt(recording.rec_from);
		if(parseInt(recording.user_rec_start)) recordingFrom = parseInt(recording.user_rec_start);

		var recordingTo = parseInt(recording.rec_to);
		if(parseInt(recording.user_rec_stop)) recordingTo = parseInt(recording.user_rec_stop);

		recording.offset = (recordingFrom-brick.start)/(brick.end-brick.start)*100;
		recording.width = ((recordingTo-brick.start)/(brick.end-brick.start)*100) - recording.offset;

		return recording;
	}

	var ottPlay = function(id, name, source) {
		var play = function() {
			if(typeof source !== 'undefined' && source.length) {
				ott.play(id, name, source);
				ott.setLabel(name);
				ott.setChannelId(id);
			}
		}

		if(!ott.initialized()) {
			ott.init().then(play); // TODO .catch()
		} else {
			play();
		}
	}

	var onAfterChangeComplete = function() {
		// jeśli jest otwarty panel podczas aktualizacji po zmianie uprawnień, podmień referencję do klocka
		// albo zamknij panel boczny jesli taki program nie istnieje

		// bonus event: jeśli jest wejście do programu z urla to będzie ustawiony fake klocek z dummy: true
		// i wtedy trzeba znaleźć program na podstawie timestampa początku, bo nie mamy zapamiętanego offsetu
		// a epg jeszcze nie istnieje na tym etapie (nie zostało przescrollowane = nie zostało pobrane)
		if(shouldUpdateBrickReference && $scope.chosenBrick) {
			try {
				var brick = null;
				var assetObj = $scope.assets[assetIndex[storedAsset][0]];

				for(var program in assetObj.grid) {
					if(assetObj.grid[program].epg_id == $scope.chosenBrick.epg_id || ($scope.chosenBrick.dummy && assetObj.grid[program].start === $scope.chosenBrick.start)) {
						brick = assetObj.grid[program];
						break;
					}
				}

				if(brick !== null) {
					$scope.brickMouseDown(assetObj.number, brick);
					$scope.brickMouseUp(undefined, true);
				} else {
					$scope.closePane();
				}

				shouldUpdateBrickReference = false;
			} catch(e) {
				shouldUpdateBrickReference = false;
			}
		}
	}

	var prepareScrollFromUrl = function() {
		// http://sgt-dev-b/mikolaj/webpanel-hd/telewizja/epg/286/foobar/1566921900/bazbaf

		shouldScrollToAssetPosition = true;
		storedAsset = $routeParams.channelId;
		storedBrickTimestamp = $routeParams.programStart;

		shouldUpdateBrickReference = true;
		$scope.chosenBrick = {dummy: true, start: parseInt(storedBrickTimestamp) }
	}

	// ----------------------------------------------

	recording.addObserver('epgRecordingObserver', function(type, data) {
		switch(type) {
			case 'listChange':
				updateRecordings();
			break;
			case 'asyncListChange':
				updateRecordings();

				for(var ass in $scope.assets) {
					if(!$scope.assets[ass].grid) continue;

					for(var brickDel in $scope.assets[ass].grid) {
						delete $scope.assets[ass].grid[brickDel].recording;
					}
				}

				for(var i in recordings) {
					var assetNumbers = assetIndex[parseInt(recordings[i].channel_id)];

					// lista 100, wielokrotne kanały
					for(var number in assetNumbers) {
						for(var brick in $scope.assets[assetNumbers[number]].grid) {
							var brickObj = $scope.assets[assetNumbers[number]].grid[brick];

							if(brickObj.epg_id == recordings[i].rec_contentid) {
								var decoratedRecording = decorateRecording(brickObj, recordings[i]);
								brickObj.recording = decoratedRecording;

								if($scope.chosenBrick && $scope.chosenBrick.epg_id == recordings[i].rec_contentid) {
									$scope.chosenBrick.recording = decoratedRecording;
								}

								break;
							}
						}
					}
				}
			break;
			case 'addSuccess':
				var assetNumbers = assetIndex[parseInt(data.channel_id)];

				for(var number in assetNumbers) {
					for(var brick in $scope.assets[assetNumbers[number]].grid) {
						var brickObj = $scope.assets[assetNumbers[number]].grid[brick];

						if(brickObj.epg_id == data.rec_contentid) {
							var decoratedRecording = decorateRecording(brickObj, data);

							brickObj.recording = decoratedRecording;

							if($scope.chosenBrick && $scope.chosenBrick.epg_id == data.rec_contentid) {
								$scope.chosenBrick.recording = decoratedRecording;
							}

							break;
						}
					}
				}
			break;
			case 'removeSuccess':
				var assetNumbers = assetIndex[parseInt(data.channel_id)];

				for(var number in assetNumbers) {
					for(var brick in $scope.assets[assetNumbers[number]].grid) {
						var brickObj = $scope.assets[assetNumbers[number]].grid[brick];

						if(brickObj.epg_id === parseInt(data.rec_contentid)) {
							delete brickObj.recording;

							if($scope.chosenBrick && $scope.chosenBrick.epg_id == parseInt(data.rec_contentid)) {
								delete $scope.chosenBrick.recording;
							}

							break;
						}
					}
				}
			break;
			case 'changeSuccess':
				var affectedRecording = recording.getOne(data); // w data jest id nagrania
				var assetNumbers = assetIndex[parseInt(affectedRecording.channel_id)];

				if($scope.chosenBrick && $scope.chosenBrick.recording && $scope.chosenBrick.recording.id == data) {
					$scope.chosenBrick.recording.user_rec_stop = affectedRecording.user_rec_stop;
					$scope.chosenBrick.recording.user_rec_resume = affectedRecording.user_rec_resume;
				}

				for(var number in assetNumbers) {
					for(var brick in $scope.assets[assetNumbers[number]].grid) {
						var brickObj = $scope.assets[assetNumbers[number]].grid[brick];

						if(brickObj.recording && brickObj.recording.id === affectedRecording.id) {
							brickObj.recording.user_rec_stop = affectedRecording.user_rec_stop;
							brickObj.recording.user_rec_resume = affectedRecording.user_rec_resume;

							var decoratedRecording = decorateRecording(brickObj, brickObj.recording);
							brickObj.recording = decoratedRecording;

							if($scope.chosenBrick && $scope.chosenBrick.recording && $scope.chosenBrick.epg_id === affectedRecording.rec_contentid) {
								$scope.chosenBrick.recording.user_rec_stop = affectedRecording.user_rec_stop;
								$scope.chosenBrick.recording.user_rec_resume = affectedRecording.user_rec_resume;
								$scope.chosenBrick.recording = decoratedRecording;
							}

							break;
						}
					}
				}
			break;
			case 'obliterateSuccess':
				recordings = {};

				for(var asset in $scope.assets) {
					for(var brick in $scope.assets[asset].grid) {
						delete $scope.assets[asset].grid[brick].recording;
						if($scope.chosenBrick) delete $scope.chosenBrick.recording;
					}
				}
			break;
			case 'triggerNotEntitled':
				$scope.analytics.trackEvent('recording', 'not_entitled');
			break;
			case 'triggerOrderMore':
				$scope.location.path('/uslugi-tv/zamow-dodatki');
				$scope.analytics.trackEvent('order', 'more');
			break;
			case 'channelsChange':
				var pvrEnabled = recording.getAvailable();

				if($scope.assets) {
					for(var i in $scope.assets) {
						$scope.assets[i].features.jpvr = (pvrEnabled.indexOf($scope.assets[i].id) > -1);
					}
				}
			break;
		}
	});

	asset.addObserver('epgAssetObserver', function(type) {
		switch(type) {
			case 'change':
				calculateTimestamps();
				$scope.assets = {};

				// jeśli mamy otwarty opis w epg, ustaw flagę która sprawi że po zmianie assetów
				// epg przewinie się do assetu z otwartego opisu
				if($scope.chosenBrick !== null) {
					shouldScrollToAssetPosition = true;
					shouldUpdateBrickReference = true;
				}

				// bardzo brzydki hack wymuszający wypełnienie listy assetów w nowym digest cycle
				$timeout(getAssets, 0);
			break;
		}
	});

	setting.addObserver('epgSettingObserver', function(type) {
		switch(type) {
			case 'change':
				var autozapList = setting.getAutozap();
				//[start,end,channelSgtId,scheduleType,brickEpgId]
				var hasFoundCurrentBrick = false;

				for(var i in autozapList) {
					var autozapData = autozapList[i].split(',');
					var assetNumbers = assetIndex[parseInt(autozapData[2])];

					// lista 100, wielokrotne kanały
					for(var number in assetNumbers) {
						if(!$scope.assets[assetNumbers[number]].grid) continue;

						for(var brick in $scope.assets[assetNumbers[number]].grid) {
							var brickObj = $scope.assets[assetNumbers[number]].grid[brick];

							if(brickObj.epg_id == autozapData[4]) {
								brickObj.schedule = autozapData[3];

								if($scope.chosenBrick && $scope.chosenBrick.epg_id == autozapData[4]) {
									$scope.chosenBrick.schedule = autozapData[3];
									hasFoundCurrentBrick = true;
								}

								break;
							}
						}
					}
				}

				if(!hasFoundCurrentBrick && $scope.chosenBrick) {
					$scope.chosenBrick.schedule = 0;
				}
			break;
		}
	});

	epg.addObserver('epgEpgObserver', function(type) {
		switch(type) {
			case 'change':
				var epgData = epg.get();
				var emPxSize = $scope.clientRect.width;

				var now = Math.floor(Date.now()/1000);
				var sgtIdList = [];

				drawnBricksIndex = [];
				$scope.times = {};

				for(var i in deferredDrawList) {
					var sgtId = deferredDrawList[i].split('/')[0];
					sgtIdList.push(parseInt(sgtId));
				}

				var autozapList = setting.getAutozap();
				var autozapIndex = {};

				for(var i in autozapList) {
					var autozapData = autozapList[i].split(',');
					autozapIndex[autozapData[4]] = autozapData[3];
				}

				for(var assetId in epgData) {
					// tu może być więcej niż 1 numer bo pozycje na liście kanałów niekoniecznie są unikatowe
					var assetNumbers = assetIndex[assetId];
					var tempGrid = [];

					if(sgtIdList.indexOf(parseInt(assetId)) > -1) {
						for(var chunk in epgData[assetId]) {

							for(var brick in epgData[assetId][chunk]) {
								var brickObj = epgData[assetId][chunk][brick];

								if(drawnBricksIndex.indexOf(brickObj.epg_id) > -1) continue;
								drawnBricksIndex.push(brickObj.epg_id);

								brickObj.left = (brickObj.start-leftmostTimestamp)/unit;
								brickObj.size = (brickObj.end-brickObj.start)/unit;

								if(brickObj.left > rightBorder*emPxSize) {
									// nie rysuj tego co mamy za prawą granicą
									continue;
								}

								if(brickObj.left > scrollOffsetLeft+screenRange/unit || brickObj.left+brickObj.size < scrollOffsetLeft) {
									continue;
								}

								// jeśli wystaje poza 1492em, obetnij
								// nie mogę tu użyć overflow:hidden bo position:sticky przestanie działać
								if(brickObj.size+brickObj.left > rightBorder*emPxSize) {
									//console.log('calculated em to px size is '+1492*emPxSize);
									//console.log('block takes '+(brickObj.size+brickObj.left));
									brickObj.size = ((rightBorder*emPxSize)-brickObj.left);
									//console.log('block has been cut to '+(brickObj.size));
								}

								brickObj.current = (brickObj.start < now && brickObj.end > now);
								brickObj.past = brickObj.end < now;
								brickObj.schedule = autozapIndex[brickObj.epg_id] || 0;
								brickObj.assetId = parseInt(assetId, 10);

								try {
									var descriptionCandidate = brickObj.description.match(/.*<br[\s\/]?>(.*)/)[1];
									if(descriptionCandidate.length > 150) {
										brickObj.descriptionShort = descriptionCandidate.substring(0, 150) + '...';
									} else {
										brickObj.descriptionShort = descriptionCandidate;
									}
								} catch(e) {}

								if(typeof recordings[brickObj.epg_id] !== 'undefined') {
									brickObj.recording = decorateRecording(brickObj, recordings[brickObj.epg_id]);
								} else {
									brickObj.recording = null;
								}

								tempGrid.push(brickObj);
							}

							var timelineMultiplier = 3;
							//if(screenRange < 5400) timelineMultiplier = 1;

							for(var i=0; i<3; i++) {
								var offsetDate = new Date((parseInt(chunk)+(screenRange/timelineMultiplier*i))*1000);

								if(typeof $scope.times[offsetDate] === 'undefined') {
									var timelineEntry = {
										left: (chunk-leftmostTimestamp)/unit+(i/timelineMultiplier*(scrollOffsetWidth-8*emPxSize)), // what the fuck
										size: (scrollOffsetWidth-8*emPxSize)/timelineMultiplier,
										time: offsetDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit'}),
									};

									if(timelineEntry.left > rightBorder*emPxSize) continue;

									if(timelineEntry.size+timelineEntry.left > rightBorder*emPxSize) {
										timelineEntry.size = ((rightBorder*emPxSize)-timelineEntry.left);
									}

									$scope.times[offsetDate] = timelineEntry;
								}
							}
						}
					}

					updateTimeLine();

					// lista 100, wiele pozycji może mieć jeden kanał
					for(var number in assetNumbers) {
						$scope.assets[assetNumbers[number]].grid = tempGrid;
					}
				}

				onAfterChangeComplete();
			break;
		}
	});

	device.addObserver('watchDeviceObserver', function(type) {
		if($scope.user.isDemo()) return;
		if (type === 'selectSuccess' && !$rootScope.access.epg) return $scope.location.path('/');

	});

	$interval(function() {
		$scope.now = Math.floor(Date.now()/1000);
		updateTimeLine();
	}, 10000);

	$scope.$on('$destroy', function() {
		firstRender = true;

		asset.removeObserver('epgAssetObserver');
		epg.removeObserver('epgEpgObserver');
		recording.removeObserver('epgRecordingObserver');
		device.removeObserver('watchDeviceObserver');
	});

	$scope.$watch('assetNamesFiltered', function() {
		if($scope.assetNamesFiltered.length < 1 || $scope.assetNameFilter.length < 1) {
			$scope.assetsFilteredEmSize = 0;
		} else {
			$scope.assetsFilteredEmSize = $scope.assetNamesFiltered.length*2.9;
			if($scope.assetsFilteredEmSize > 19.5) $scope.assetsFilteredEmSize = 19.5;
		}
	});

	asset.update();
	updateRecordings();
	prepareScrollFromUrl();

}]);