angular.module('webpanel').directive('srcError', function() {
	return {
		link: function(scope, element, attrs) {
			var handler = function() {
				if(attrs.$attr.src != attrs.srcError) {
					attrs.$set('src', attrs.srcError);
				}
			}

			element.on('error', handler);

			scope.$on('$destroy', function() {
				element.off('error', handler);
			});
		}
	}
});