angular.module('webpanel').service('recording', ['$rootScope', 'device', 'api', 'socket', 'popup', 'auth', 'setting', 'asset', function($rootScope, device, api, socket, popup, auth, setting, asset) {
	var recordingData = {};
	var freeSpace = 0;
	var availableChannels = [];

	var observers = {};
	var namePrefix = 'npvr-';

	var fetchAll = function(fromSocket) {
		if($rootScope.access.card) return;
		api.recordingGetAll().then(function(response) {
			recordingData = {};

			if(response.data) {
				for(var i in response.data) {
					var id = namePrefix+response.data[i].id;
					recordingData[id] = response.data[i];
				}
			}

			if(fromSocket) notifyObservers('asyncListChange');
			notifyObservers('listChange');
		}).catch(function(response) {
			if(auth.isDemo()) return;
			notifyObservers('listFail', response.status);

			var pop = popup.create();
			pop.setText('Wystąpił błąd podczas pobierania danych. Spróbuj ponownie. '+
				'Jeśli problem będzie się powtarzał, skontaktuj się ze swoim Operatorem.');
			pop.addButton('OK', angular.noop, 'yes', true);
			popup.push(pop);
		});
 	}

	var updateFreeSpace = function() {
		if($rootScope.access.card) return;
		api.recordingGetStorage().then(function(response) {
			freeSpace = Math.round(response.data.available/102.4)/10;
			notifyObservers('storageChange');
		}).catch(function(response) {
			notifyObservers('storageFail', response.status);
		});
	}

	var updateAvailableChannels = function() {
		var assets = asset.get();
		availableChannels = [];

		for(var i in assets) {
			if(assets[i].constraints_at_home.npvr) {
				availableChannels.push(assets[i].sgtid);
			}
		}

		notifyObservers('channelsChange');
	}

	var isAvailable = function(channel) {
		return availableChannels.indexOf(parseInt(channel)) > -1;
	}

	var getAvailable = function() {
		return availableChannels;
	}

	var get = function() {
		return recordingData;
	}

	var getOne = function(id) {
		return recordingData[namePrefix+id];
	}

	var getSpace = function() {
		return freeSpace;
	}

	var add = function(channelId, from, to, contentId, title) {
		if(!isAvailable(channelId)) return false;

		var recordingObj = {
			channel_id: channelId,
			rec_from: parseInt(from),
			rec_to: parseInt(to),
			rec_contentid: parseInt(contentId),
			rec_title: title,
			rec_parental: Number(setting.isLocked(channelId)).toString(),
		}

		api.recordingAdd(recordingObj).then(function(response) {
			var id = namePrefix+response.data.id;
			recordingData[id] = response.data;

			notifyObservers('addSuccess', response.data);
			notifyObservers('listChange');
		}).catch(function(response) {
			notifyObservers('addFail', response.status);
			var pop = popup.create();

			switch(response.status) {
				case 409:
				case '409':
					pop.setText('Osiągnięto limit jednoczśnie aktywnych materiałów w usłudze JAMBO Nagrywarka. Usuń jeden lub więcej materiałów.');
					pop.addButton('OK', angular.noop, 'yes', true);
				break;
				case 401:
				case '401':
					notifyObservers('triggerNotEntitled');

					pop.setText('Nagrywanie możliwe jest tylko na kanałach dostępnych w ramach posiadanego abonamentu.');
					pop.addButton('Sprawdź ofertę', function() {
						notifyObservers('triggerOrderMore');
					}, 'yes');
					pop.addButton('Nie teraz', angular.noop, 'no', true);
				break;
				case 402:
				case '402':
					pop.setText('Brak miejsca w usłudze JAMBO Nagrywarka. Usuń jedno lub więcej nagrań.');
					pop.addButton('Nie teraz', angular.noop, 'yes', true);
				break;
				default:
					pop.setText('Nie udało się dodać nagrania. Spróbuj ponownie.');
					pop.addButton('OK', angular.noop, 'yes', true);
			}
			popup.push(pop);
		});
	}

	var remove = function(id) {
		api.recordingDelete(id).then(function() {
			var oldRecording = angular.copy(recordingData[namePrefix+id]);
			delete recordingData[namePrefix+id];

			notifyObservers('removeSuccess', oldRecording);
			notifyObservers('listChange');
		}).catch(function(response) {
			notifyObservers('removeFail', response.status);

			var pop = popup.create();
			pop.setText('Nie udało się usunąć nagrania. Spróbuj ponownie.');
			pop.addButton('OK', angular.noop, 'yes', true);
			popup.push(pop);
		});
	}

	var change = function(id, userStop, userResume) {
		var postData = {};

		if(typeof userStop !== 'undefined') postData.user_rec_stop = userStop;
		if(typeof userResume !== 'undefined') postData.user_rec_resume = userResume;

		if(Object.keys(postData).length) {
			api.recordingChange(id, postData).then(function() {
				if(typeof userStop !== 'undefined') recordingData[namePrefix+id].user_rec_stop = userStop;
				if(typeof userResume !== 'undefined') recordingData[namePrefix+id].user_rec_resume = userResume;

				notifyObservers('changeSuccess', id);
				notifyObservers('listChange');
			}).catch(function(response) {
				notifyObservers('changeFail', response.status);

				var pop = popup.create();
				pop.setText('Nie udało się zmodyfikować nagrania. Spróbuj ponownie.');
				pop.addButton('OK', angular.noop, 'yes', true);
				popup.push(pop);
			});
		} else {
			notifyObservers('changeNothing');
		}
	}

	var obliterate = function() {
		if(!Object.keys(recordingData).length) {
			notifyObservers('obliterateNothing');
			return;
		}

		api.recordingDeleteAll().then(function() {
			for(var i in recordingData) {
				if(recordingData[i].rec_status === 'SCHEDULED') {
					api.recordingDelete(recordingData[i].id);
				}
			}

			recordingData = {};

			notifyObservers('listChange');
			notifyObservers('obliterateSuccess');

			updateFreeSpace();
		}).catch(function(response) {
			notifyObservers('obliterateFail', response.status);

			var pop = popup.create();
			pop.setText('Nie udało się usunąć nagrań. Spróbuj ponownie.');
			pop.addButton('OK', angular.noop, 'yes', true);
			popup.push(pop);
		});
	}

	var addObserver = function(name, observer) {
		if(typeof observer !== 'function') return;
		if(typeof observers[name] !== 'undefined') return;
		observers[name] = observer;
	}

	var removeObserver = function(name) {
		delete observers[name];
	}

	var notifyObservers = function(eventType, eventData) {
		for(var i in observers) observers[i](eventType, eventData);
	}

	var update = function(fromSocket) {
		if(typeof fromSocket !== 'boolean') fromSocket = false;

		if(device.has()) {
			fetchAll(fromSocket);
			updateFreeSpace();
			updateAvailableChannels();
		}
	}

	$rootScope.$on('deviceAcquired', update);

	$rootScope.$on('deviceLost', function() {
		recordingData = {};
		freeSpace = 0;
		availableChannels = [];

		notifyObservers('listChange');
		notifyObservers('storageChange');
		notifyObservers('channelsChange');
	});

	socket.addObserver('recordingSocketObserver', 'jpvr', function() {
		update(true);
	});

	update();

	return {
		addObserver: addObserver,
		removeObserver: removeObserver,
		update: update,
		add: add,
		remove: remove,
		change: change,
		obliterate: obliterate,
		get: get,
		getOne: getOne,
		isAvailable: isAvailable,
		getAvailable: getAvailable,
		getSpace: getSpace
	}

}]);