angular.module('webpanel').service('state', [function() {
	var storedState = [];
	var observers = [];

	var set = function(index, value) {
		storedState[index] = angular.copy(value);
		notifyObservers(index);
	}

	var get = function(index) {
		return storedState[index];
	}

	var addObserver = function(name, index, observer) {
		if(typeof observer !== 'function' || typeof name !== 'string' || typeof index !== 'string') return;
		if(typeof observers[index] === 'undefined') observers[index] = [];
		if(typeof observers[index][name] !== 'undefined') return;

		observers[index][name] = observer;
	}

	var removeObserver = function(name, index) {
		delete observers[index][name];
	}

	var notifyObservers = function(index) {
		for(var i in observers[index]) observers[index][i]();
	}

	return {
		get: get,
		set: set,
		addObserver: addObserver,
		removeObserver: removeObserver,
	}
}]);