'use strict';

if ('serviceWorker' in navigator) navigator.serviceWorker.register('worker.js');

angular.module('webpanel', ['LocalStorageModule', 'ngRoute', 'angular-inview', 'angular-google-analytics', 'vcRecaptcha', 'ngLocationUpdate']
).config(['$httpProvider', '$routeProvider', '$locationProvider', 'AnalyticsProvider', function($httpProvider, $routeProvider, $locationProvider, AnalyticsProvider) {
	$httpProvider.defaults.timeout = 5000;

	var templatePath = 'assets/template/';

	// obsługa wywalania usera z miejsc, które mu nie będą działać z powodu braku odpowiednich urządzeń
	var hasTv = function($rootScope, $location) {
		if(!$rootScope.access.device || $rootScope.access.device && $rootScope.access.tv) return true;
		$location.path('/');
	}

	var hasMobile = function($rootScope, $location) {
		if(!$rootScope.access.device || $rootScope.access.device && $rootScope.access.mobile) return true;
		$location.path('/');
	}

	$locationProvider.hashPrefix('');
	$locationProvider.html5Mode(true);

	AnalyticsProvider.useECommerce(true, false);
	AnalyticsProvider.setCurrency('PLN');

	AnalyticsProvider.setAccount({
		tracker: 'UA-54660862-5',
		displayFeatures: true,
		enhancedLinkAttribution: true,
		trackEvent: true,
		trackEcommerce: true
	});

	$httpProvider.interceptors.push('loadingInterceptor');

	$routeProvider
		.when('/', {
			templateUrl: templatePath+'welcome.htm',
			controller: 'WelcomeController',
			title: '',
		})
		.when('/:mode', {
			templateUrl: templatePath+'login.htm',
			controller: 'LoginController',
			title: '',
		})

		.when('/resetuj-haslo/:passwordToken', {
			templateUrl: templatePath+'welcome.htm',
			controller: 'WelcomeController',
			title: '',
		})

		.when('/telewizja/ogladaj', {
			templateUrl: templatePath+'watch.htm',
			controller: 'WatchController',
			title: 'Oglądaj na żywo',
			resolve: { factory: hasTv }
		})

		.when('/telewizja/epg', {
			templateUrl: templatePath+'epg.htm',
			controller: 'EpgController',
			title: 'EPG - Elektroniczny przewodnik po programach',
			resolve: { factory: hasTv }
		})
		.when('/telewizja/epg/:channelId/:channelName/:programStart/:programName', {
			templateUrl: templatePath+'epg.htm',
			controller: 'EpgController',
			title: 'EPG - Elektroniczny przewodnik po programach',
			resolve: { factory: hasTv }
		})

		.when('/telewizja/nagrania', {
			templateUrl: templatePath+'recording.htm',
			controller: 'RecordingController',
			title: 'JAMBO Nagrywarka',
			resolve: { factory: hasTv }
		})
		.when('/telewizja/moja-lista-100', {
			templateUrl: templatePath+'customlist.htm',
			controller: 'CustomListController',
			title: 'Moja Lista 100',
			resolve: { factory: hasTv }
		})
		.when('/telewizja/ochrona-rodzicielska', {
			templateUrl: templatePath+'parentallock.htm',
			controller: 'ParentalLockController',
			title: 'Ochrona rodzicielska',
			resolve: { factory: hasTv }
		})

		.when('/telewizja/wyszukaj', {
			templateUrl: templatePath+'search.htm',
			controller: 'SearchController',
			title: 'Wyszukaj',
			resolve: { factory: hasTv }
		})
		.when('/telewizja/wyszukaj/:mode/:phrase', {
			templateUrl: templatePath+'search.htm',
			controller: 'SearchController',
			title: 'Wyszukaj',
			resolve: { factory: hasTv }
		})

		.when('/telewizja/wideo-na-zyczenie-vod', {
			templateUrl: templatePath+'vod.htm',
			controller: 'VodController',
			title: 'Wideo na życzenie VOD',
			resolve: { factory: hasTv }
		})
		.when('/telewizja/wideo-na-zyczenie-vod/:kioskId/:kioskName/:categoryId/:categoryName', {
			templateUrl: templatePath+'vod.htm',
			controller: 'VodController',
			title: 'Wideo na życzenie VOD',
			resolve: { factory: hasTv }
		})
		.when('/telewizja/wideo-na-zyczenie-vod/:kioskId/:kioskName/:categoryId/:categoryName/:filmId/:filmName', {
			templateUrl: templatePath+'vod.htm',
			controller: 'VodController',
			title: 'Wideo na życzenie VOD',
			resolve: { factory: hasTv }
		})

		.when('/uslugi-tv/zamow-dodatki', {
			templateUrl: templatePath+'tvorder.htm',
			controller: 'TvOrderController',
			title: 'Zamów dodatkowe pakiety i usługi',
			resolve: { factory: hasTv }
		})
		.when('/uslugi-tv/zamow-dodatki/zamow-teraz/:orderId', {
			templateUrl: templatePath+'tvorder.htm',
			controller: 'TvOrderController',
			title: 'Zamów dodatkowe pakiety i usługi',
			resolve: { factory: hasTv }
		})
		.when('/uslugi-tv/twoje-uslugi-i-pakiety', {
			templateUrl: templatePath+'tvservice.htm',
			controller: 'TvServiceController',
			title: 'Twoje usługi i pakiety telewizyjne',
			resolve: { factory: hasTv }
		})

		.when('/urzadzenia/obecne', {
			templateUrl: templatePath+'device.htm',
			controller: 'DeviceController',
			title: 'Twoje urządzenia',
			resolve: {
				mode: function() { return 'default'; }, // ten kontroler to musi wiedzieć zawsze
				factory: hasTv,
			}
		})
		.when('/urzadzenia/przyjazne-nazwy', {
			templateUrl: templatePath+'devicename.htm',
			controller: 'DeviceNameController',
			title: 'Przyjazne nazwy urządzeń',
			resolve: {
				mode: function() { return 'default'; }, // ten kontroler to musi wiedzieć zawsze
				factory: hasTv,
			}
		})

		.when('/twoje-konto/wiadomosci', {
			templateUrl: templatePath+'message.htm',
			controller: 'MessageController',
			title: 'Wiadomości'
		})
		.when('/twoje-konto/twoje-dane', {
			templateUrl: templatePath+'tvuser.htm',
			controller: 'TvUserController',
			title: 'Twoje dane'
		})
		.when('/twoje-konto/twoje-dane/potwierdz-email/:emailToken', {
			templateUrl: templatePath+'tvuser.htm',
			controller: 'TvUserController',
			title: 'Twoje dane'
		})
		.when('/twoje-konto/zmiana-hasel-i-pinow', {
			templateUrl: templatePath+'password.htm',
			controller: 'PasswordController',
			title: 'Zmiana haseł i pinów'
		})

		.when('/uslugi-mobilne/podsumowanie-zuzycia', {
			templateUrl: templatePath+'simsummary.htm',
			controller: 'SimSummaryController',
			title: 'Podsumowanie zużycia',
			resolve: {
				mode: function() { return 'mvno'; },
				factory: hasMobile,
			}
		})
		.when('/uslugi-mobilne/twoje-numery', {
			templateUrl: templatePath+'device.htm',
			controller: 'DeviceController',
			title: 'Twoje numery',
			resolve: {
				mode: function() { return 'mvno'; },
				factory: hasMobile,
			}
		})
		.when('/uslugi-mobilne/przyjazne-nazwy-sim', {
			templateUrl: templatePath+'devicename.htm',
			controller: 'DeviceNameController',
			title: 'Przyjazne nazwy kart SIM i numerów',
			resolve: {
				mode: function() { return 'mvno'; },
				factory: hasMobile,
			}
		})
		.when('/uslugi-mobilne/limity-i-blokady', {
			templateUrl: templatePath+'simstatus.htm',
			controller: 'SimStatusController',
			title: 'Limity i blokady',
			resolve: {
				mode: function() { return 'mvno'; },
				factory: hasMobile,
			}
		})
		.when('/uslugi-mobilne/historia-polaczen', {
			templateUrl: templatePath+'simhistory.htm',
			controller: 'SimHistoryController',
			title: 'Historia połączeń',
			resolve: {
				mode: function() { return 'mvno'; },
				factory: hasMobile,
			}
		})
		.when('/uslugi-mobilne/zamow-dodatkowe-uslugi', {
			templateUrl: templatePath+'simorder.htm',
			controller: 'SimOrderController',
			title: 'Zamów dodatki',
			resolve: {
				factory: hasMobile,
			}
		})

		.otherwise({
			redirectTo: '/'
		});
}]);

angular.module('webpanel').run(['$rootScope', function($rootScope) {
	$rootScope.$on('$routeChangeSuccess', function(_, current) {
		var titleSuffix = 'JAMBOX go!';

		try {
			$rootScope.title = current.$$route.title;
			if(!$rootScope.title) throw new Error();

			$rootScope.title += (' - '+titleSuffix);
		} catch(e) {
			$rootScope.title = titleSuffix;
		}
	});
}]);
