angular.module('webpanel').controller('TvUserController', ['$scope', '$routeParams', 'api', 'auth', function($scope, $routeParams, api, auth) {
    $scope.userData = [];
    $scope.operatorData = [];
    $scope.marketingConsent = [];

    $scope.changeEmailVisible = false;
    $scope.pinQuestionVisible = false;
    $scope.newEmail = { bsReference: '' };
    $scope.credential = { bsReference: '' };

    var userKeys = ['name', 'surname', 'email', 'address', 'id'];
    var operatorKeys = ['operator', 'operator_address', 'operator_phone', 'operator_www'];

    var dictionary = {
        name: 'Imię',
        surname: 'Nazwisko',
        address: 'Adres',
        id: 'Numer ID klienta',
        email: 'E-mail',
        operator: 'Nazwa firmy',
        operator_address: 'Adres',
        operator_phone: 'Telefon',
        operator_www: 'Strona WWW',
    };

    $scope.translate = function(label) {
        if (typeof dictionary[label] !== 'undefined') {
            return dictionary[label];
        } else {
            return '((' + label + '))';
        }
    };

    var fetch = function() {
        var newData = $scope.user.get();

        var userData = [];
        var operatorData = [];

        for (var i = 0; i < userKeys.length; i++) {
            if (typeof newData[userKeys[i]] !== 'undefined') {
                userData.push({
                    label: userKeys[i],
                    value: newData[userKeys[i]],
                });
            }
        }

        for (var j = 0; j < operatorKeys.length; j++) {
            if (typeof newData[operatorKeys[j]] !== 'undefined') {
                var row = {
                    label: operatorKeys[j],
                    value: newData[operatorKeys[j]],
                };

                if (row.label === 'operator_www' && row.value) {
                    if (!row.value.match(/^https?:\/\//i)) row.value = 'http://' + row.value;
                    row.link = true;
                }

                operatorData.push(row);
            }
        }

        $scope.userData = userData;
        $scope.operatorData = operatorData;

        api.marketingAgreementsGet().then(function(response) {
            $scope.marketingConsent = response.data;
        }).catch(function(error) {
            console.error('Error fetching marketing agreements:', error);
        });
    };

    var handleMailConfirmation = function(token) {
        var pop = $scope.popup.create();

        $scope.location.path('/twoje-konto/twoje-dane');
        $scope.location.replace();

        api.emailChangeConfirm(token).then(function() {
            pop.setText('Adres e-mail został zmieniony.');
            pop.addButton('OK', angular.noop, 'yes', true);
            $scope.popup.push(pop);

            $scope.user.update();

            $scope.analytics.trackEvent('user', 'email_change_confirm');
        }).catch(function() {
            pop.setText('Nie udało się potwierdzić zmiany adresu e-mail.');
            pop.addButton('Spróbuj ponownie', angular.noop, 'yes', true);
            $scope.popup.push(pop);

            $scope.user.update();
        });
    };

    $scope.changeEmail = function() {
        for (var i in $scope.userData) {
            if ($scope.userData[i].label === 'email') {
                $scope.newEmail.bsReference = $scope.userData[i].value;
                $scope.changeEmailVisible = true;
                break;
            }
        }
    };

    $scope.changeEmailConfirm = function() {
        $scope.pinQuestionVisible = true;
    };

    $scope.changeEmailConfirmPin = function() {
        api.emailChange($scope.newEmail.bsReference, $scope.credential.bsReference, $scope.hasEmail()).then(function() {
            for (var i in $scope.userData) {
                if ($scope.userData[i].label === 'email') {
                    $scope.userData[i].value = $scope.newEmail.bsReference;
                    $scope.changeEmailCancel();
                    $scope.user.update();

                    var pop = $scope.popup.create();
                    pop.setText('Żądanie zmiany adresu e-mail zostało zapisane. Musisz teraz potwierdzić zmianę adresu ' +
                        'klikając w odnośnik wysłany na nowo podany adres e-mail. Jeśli nie widzisz wiadomości w swojej ' +
                        'skrzynce, sprawdź folder SPAM.');
                    pop.addButton('Rozumiem', angular.noop, 'yes', true);
                    $scope.popup.push(pop);

                    $scope.analytics.trackEvent('user', 'email_change');

                    break;
                }
            }
        }).catch(function() {
            $scope.changeEmailCancel();
            var pop = $scope.popup.create();

            if ($scope.hasEmail()) {
                pop.setText('Nie udało się zmienić adresu e-mail. Upewnij się, że podano ' +
                    'poprawne hasło oraz że wpisany adres e-mail ma prawidłowy format.');
            } else {
                pop.setText('Nie udało się zmienić adresu e-mail. Upewnij się, że podano ' +
                    'poprawny kod jPIN oraz że wpisany adres e-mail ma prawidłowy format.');
            }
            pop.addButton('Spróbuj ponownie', angular.noop, 'yes', true);
            $scope.popup.push(pop);
        });
    };

    $scope.securityGlobalLogout = function() {
        var pop = $scope.popup.create();

        pop.setText('Czy na pewno chcesz wylogować się ze wszystkich urządzeń? Spowoduje to ' +
            'zresetowanie preferencji automatycznego logowania oraz zakończenie trwających sesji Chromecast.');

        pop.addButton('Wyloguj ze wszystkich urządzeń', function() {
            api.userPairMassDestroy().then(function() {
                auth.destroy();
                location.reload();
            }).catch(function() {
                console.log('epic fail');
            });
        }, 'yes', true);

        pop.addButton('Anuluj', angular.noop, 'no');
        $scope.popup.push(pop);
    };

    $scope.changeEmailCancel = function() {
        $scope.changeEmailVisible = false;
        $scope.pinQuestionVisible = false;
        $scope.newEmail.bsReference = '';
        $scope.credential.bsReference = '';
    };

    $scope.changeEmailCancelConfirmation = function() {
        $scope.credential.bsReference = '';
        $scope.pinQuestionVisible = false;
    };

    $scope.changeConsent = function(consent) {
        consent.value = (consent.value === 'enabled') ? 'disabled' : 'enabled';

        var formattedData = {};
        $scope.marketingConsent.forEach(function(consent) {
            if (consent.agreement === 'cust_personal_agreement') {
                formattedData.cust_personal_agreement = consent.value;
            } else if (consent.agreement === 'cust_electronic_agreement') {
                formattedData.cust_electronic_agreement = consent.value;
            }
        });

        api.marketingAgreementsSet(formattedData).catch(function(error) {
            console.error('Error updating marketing consents:', error);
        });
    };

   
    $scope.user.addObserver('tvUserUserObserver', function(type) {
        switch (type) {
            case 'change':
                fetch();
                break;
        }
    });

    $scope.$on('$destroy', function() {
        $scope.user.removeObserver('tvUserUserObserver');
    });

    if ($scope.user.isDemo()) {
        $scope.showLoginPrompt(true);
    } else {
        fetch();

        if (typeof $routeParams.emailToken !== 'undefined') {
            handleMailConfirmation($routeParams.emailToken);
        }
    }

}]);
