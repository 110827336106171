angular.module('webpanel').service('ott', ['$interval', '$rootScope', 'auth', 'device', 'api', 'popup', 'state', 'asset',
	function($interval, $rootScope, auth, device, api, popup, state, asset) {

	var observers = {};
	var qualities = {};
	var token = '';
	var tokenObtainTime = 0;
	var ttl = 20*60*1000;
	var isActive = false;

	var tokenUpdateInterval;

	var init = function() {
		$interval.cancel(tokenUpdateInterval);
		tokenUpdateInterval = $interval(updateToken, ttl/3);

		isActive = true;
		return Promise.all([updateQualities(), updateToken()]).catch(function() {
			// om nom nom
		});
	}

	var destroy = function() {
		$interval.cancel(tokenUpdateInterval);
		notifyObservers('destroy');
		isActive = false;
	}

	var initialized = function() {
		try {
			return (isActive && token.length > 0 && (tokenObtainTime + ttl) > Date.now());
		} catch(e) {
			return false;
		}
	}

	var updateToken = function() {
		if(!device.has()) {
			$interval.cancel(tokenUpdateInterval);
			return;
		}

		if(tokenObtainTime + ttl > Date.now()) {
			return;
		}

		return new Promise(function(resolve, reject) {
			api.ottTokenGet().then(function(response) {
				token = response.data.token;
				notifyObservers('tokenChange');

				// trzeba uwzględnić że request może chwilę trwać
				tokenObtainTime = Date.now()-5000;

				resolve();
			}).catch(function() {
				var pop = popup.create();
				pop.setText('Nie udało się uruchomić usługi OTT. Brak uprawnień do '+
					'usługi lub usługa chwilowo niedostępna.');
				pop.addButton('OK', angular.noop, 'yes', true);
				popup.push(pop);

				notifyObservers('tokenFail');
				reject();
			});
		});
	}

	var updateQualities = function() {
		if(!device.has()) {
			return;
		}

		return new Promise(function(resolve) {
			Promise.all([api.ottQualityGet(), api.ottQualityAvailableGet()]).then(function(result) {
				qualities = [];

				var allQualities = result[0].data;
				var enabledQualities = result[1].data;

				if(!enabledQualities.length) {
					qualities = allQualities;
					notifyObservers('qualitiesAvalable');
					resolve();
					return;
				}

				for(var i in allQualities) {
					if(enabledQualities.indexOf(i) > -1) {
						qualities[i] = allQualities[i];
					}
				}

				notifyObservers('qualitiesAvalable');
				resolve();
			}).catch(function() {
				// zezwól na wszystkie

				qualities = {};
				notifyObservers('qualitiesAvalable');
				resolve();
			});
		});
	}

	var getQualities = function() {
		return qualities;
	}

	var addCredentials = function(url) {
		return url + '?token=' + encodeURIComponent(token) + '&hash='+ encodeURIComponent(auth.getId());
	}

	var start = function(playlist){
		if(!device.has()) return false;
		
		// var isApple = playlist.url.includes('.m3u8') && typeof playlist.license !== 'undefined' && playlist.license;

	 	// if(isApple){
		// 	notifyObservers('startPlaybackApple', playlist);	
		// } else
		if(typeof playlist.license !== 'undefined' && playlist.license && !playlist.url.includes('.m3u8')) {
			notifyObservers('startPlaybackDash', playlist);
		} else{
			notifyObservers('startPlayback', addCredentials(playlist.url));
		}
	}

	var play = function(id, channelName, url) {

		var playlist = {
			url: '',
			license: '',
			// cert: '',
			// src: '',
			// uuid: ''
		}

		playlist.url = url;

		var play = function() {
			if(typeof playlist.url !== 'undefined' && playlist.url.length) {
				start(playlist);
				setLabel(channelName);
				setChannelId(id);

				$rootScope.isPlaying = true;
			}
		}

		var dashUuid;

		var getDash = function() {
			// return Promise.resolve({});

			try {
				dashUuid = asset.getAlternateIds(id).vectra_uuid;
				if(!dashUuid) throw new Error('alternate id not found');
			} catch(e) {
				return Promise.resolve({});
			}
			
			return api.ottDash(dashUuid);
		}

		var checkDash = function(response) {
			// return Promise.resolve({});

			if(typeof response.data === 'undefined' || auth.isAppleDevice()) return;
			
			if(response.data.url) playlist.url = response.data.url;
			if(response.data.license) playlist.license = response.data.license;
			// if(response.data.drm){
			// 	playlist.cert = response.data.drm.cert; 
			// 	playlist.src = response.data.drm.src; 
			// 	playlist.uuid = dashUuid;
			// }
			
		}

		if(!initialized()) {
			init()
				.then(getDash)
				.then(checkDash)
				.then(play)
				.then($rootScope.fixPlayerSize)
				.catch(function(e) {
					console.log(e); //todo
				});
		} else {
			getDash()
				.then(checkDash)
				.then(play)
				.catch(function(e) {
					console.log(e); //todo
				});
		}
		
	}

	var stop = function(reason) {
		notifyObservers('stopPlayback', reason);
	}

	var setLabel = function(label) {
		if(label) {
			state.set('ottChannelName', label);
			notifyObservers('setLabel', label);
		}
	}

	var setChannelId = function(id) {
		if(id) {
			state.set('ottChannelId', id);
			notifyObservers('setChannelId', id);
		}
	}

	var setProgram = function(name) {
		if(name) {
			state.set('ottProgramName', name);
			notifyObservers('setProgram', name);
		}
	}

	var addObserver = function(name, observer) {
		if(typeof observer !== 'function') return;
		if(typeof observers[name] !== 'undefined') return;
		observers[name] = observer;
	}

	var removeObserver = function(name) {
		delete observers[name];
	}

	var notifyObservers = function(eventType, eventData) {
		for(var i in observers) {
			observers[i](eventType, eventData);
		}
	}

	device.addObserver('serviceDeviceOttObserver', function(event) {
		switch(event) {
			case 'selectSuccess':
				stop('deviceChange');
			break;
		}
	});

	return {
		addObserver: addObserver,
		removeObserver: removeObserver,
		play: play,
		stop: stop,
		setLabel: setLabel,
		setProgram: setProgram,
		setChannelId: setChannelId,
		getQualities: getQualities,
		init: init,
		destroy: destroy,
		initialized: initialized,
		addCredentials: addCredentials,
	}
}]);