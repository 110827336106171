angular.module('webpanel').controller('VideoController', [
	'$rootScope', '$scope', '$timeout', '$interval', 'ott', 'toast', 'localStorageService', 'api', 'Analytics', 'asset', 'sizing', 'state', 'cast',
	function($rootScope, $scope, $timeout, $interval, ott, toast, storage, api, Analytics, asset, sizing, state, cast) {

	$scope.clientRect = {} // tu trzeba kopię bo to nie jest dziecko Main
	$scope.epgScale = 1470;

	$scope.mediaUri = { bsReference: null };
	$scope.channelName = null;
	$scope.channelId = null;
	$scope.movieName = null;
	$scope.movieDescription = null;

	$scope.visible = false;
	$scope.isHover = false;
	$scope.isFullscreen = false;
	$scope.changingVolume = false;

	$scope.channels = [];
	$scope.channelsVisible = false;

	if(window.innerWidth > 768) {
		$scope.muted = storage.get('ott.mute') || false;
		$scope.volume = storage.get('ott.volume') || 75;
	} else {
		$scope.muted = false;
		$scope.volume = 75;
	}

	$scope.error = null;
	$scope.errorDetails = '';
	$scope.epgData = null;

	$scope.quality = '';
	$scope.qualityId = null;
	$scope.qualities = [];

	$scope.qualitySelectorOpen = false;

	$scope.autoplaySupported = !(/(ipad|iphone|ipod)/g.test(navigator.userAgent.toLowerCase()));
	$scope.noAutoplayPlaying = false;

	$scope.chromecastAvailable = false;
	$scope.canCast = false;
	$scope.chromecastState = 'IDLE';
	$scope.castMedia = {};

	var leaveTimeout;
	var volumeChangeStartTarget;
	var epgUpdateInterval;

	var lastEpgCurrentDownload = 0;
	var fragmentLoadingAfterQChange = false;
	var suspendUiFadeOut = false;

	var qualityNameMap = {}
	var defaultQualityNameMap = {
		'low': { label: 'Niska', bitrate: 500000 },
		'medium': { label: 'Średnia', bitrate: 2000000 },
		'high': { label: 'Wysoka', bitrate: 3000000 },
	}

	var translatedErrorDesc = {
		// hls
		audioTrackLoadError: 'nie udało się załadować ścieżki audio',
		audioTrackLoadTimeOut: 'upłynął limit czasu na pobranie ścieżki audio',
		bufferAddCodecError: 'błąd kodeka podczas tworzenia bufora',
		bufferAppendingError: 'bład w trakcie dodawania danych do bufora',
		bufferAppendError: 'nie udało się zapełnić bufora',
		bufferFullError: 'przepełnienie bufora',
		bufferNudgeOnStall: 'brak odtwarzania pomimo pradwidłowych danych',
		bufferSeekOverHole: 'próba przewinięcia w zakresie przerwy w materiale',
		bufferStalledError: 'brak nowych danych w buforze',
		fragDecryptError: 'deszyfrowanie fragmentu nie powiodło się',
		fragLoadError: 'próba pobrania fragmentu nie powiodła się',
		fragLoadTimeOut: 'upłynął limit czasu na pobranie fragmentu',
		fragParsingError: 'błąd przetwarzania fragmentu',
		internalException: 'błąd wewnętrzny',
		keyLoadError: 'próba pobrania klucza nie powiodła się',
		keyLoadTimeOut: 'upłynął limit czasu na pobranie klucza',
		keySystemLicenseRequestFailed: 'nie udało się pobrać licencji',
		keySystemNoAccess: 'brak dostępu do systemu licencji',
		keySystemNoKeys: 'brak klucza licencji',
		keySystemNoSession: 'brak sesji licencji',
		levelLoadError: 'nie udało się załadować poziomu',
		levelLoadTimeOut: 'upłynął limit czasu na załadowanie poziomu',
		levelSwitchError: 'próba przełączenia poziomu nie powiodła się',
		manifestIncompatibleCodecsError: 'niekompatybilne kodeki w pliku manifestu',
		manifestLoadError: 'błąd ładowania manifestu',
		manifestLoadTimeOut: 'upłynął limit czasu na pobranie manifestu',
		manifestParsingError: 'błąd przetwarzania manifestu',
		remuxAllocError: 'błąd alokacji pamięci',
		// dash
		10: 'błąd parsowania manifestu',
		11: 'błąd ładowania manifestu',
		12: 'błąd xlink',
		13: 'błąd ładowania listy segmentów',
		14: 'segment niedostępny',
		15: 'błąd systemu ładowania fragmentów',
		16: 'błąd synchronizacji czasu',
		17: 'błąd ładowania fragmentu',
		18: 'błąd ładowania fragmentu: puste żądanie',
		19: 'błąd ładowania fragmentu: nieznany adres',
		20: 'nie udało się dołączyć fragmentu',
		21: 'nie udało się usunąć fragmentu',
		22: 'aktualizacja danych nie powiodła się',
		23: 'klient nie wspiera tej zawartości',
		24: 'klient nie obsługuje szyfrowania',
		32: 'nie wykryto strumienia w manifeście',
		33: 'problem podczas parsowania napisów',
		34: 'typ muxed nie jest wspierany',
		35: 'klient nie wspiera tej zawartości',
		100: 'błąd ogólny systemu DRM',
		101: 'nieznany błąd systemu DRM',
		102: 'inicjalizacja DRM nie powiodła się',
		103: 'błąd serwera licencji',
		104: 'brak urządzenia wyjściowego zgodnego z DRM',
		105: 'wykryto zmianę konfiguracji sprzętowej',
		106: 'błąd domeny systemu DRM',
		107: 'klient nie wspiera ID sesji',
		108: 'DRM: pusta odpowiedź',
		109: 'błąd certyfikatu serwera DRM',
		110: 'licencja wygasła',
		111: 'nie określono adresu serwera licencji',
		112: 'odmowa dostępu do systemu DRM',
		113: 'nie udało się uzyskać sesji DRM',
		114: 'problem z dostawcą licencji',
	}

	// ---------------------------------------

	$scope.fullscreen = function() {
		if($scope.isFullscreen) {
			$rootScope.$broadcast('runeVideoExitFullscreen');
			Analytics.trackEvent('ott', 'fullscreen_exit');
		} else {
			$rootScope.$broadcast('runeVideoGoFullscreen');
			Analytics.trackEvent('ott', 'fullscreen_enter');
		}

		$scope.isFullscreen = isFullscreen();
		$scope.channelsVisible = false;
	}

	$scope.stop = function() {
		$scope.mediaUri.bsReference = null;
		$scope.visible = false;

		document.body.classList.remove('video-visible');
		document.body.classList.remove('watch-standalone');

		$rootScope.$broadcast('runeVideoExitFullscreen');
		$interval.cancel(epgUpdateInterval);

		$scope.isFullscreen = false;
		$scope.channelsVisible = false;
		$scope.noAutoplayPlaying = false;
		$scope.channelName = null;
		$scope.channelId = null;
		$scope.movieName = null;

		$scope.epgData = null;
		lastEpgCurrentDownload = 0;

		ott.destroy();

		Analytics.trackEvent('ott', 'playback_stop');
	}

	$scope.mute = function() {
		$rootScope.$broadcast('runeVideoMuteToggle');
		$scope.muted = !$scope.muted;
		storage.set('ott.mute', $scope.muted);

		Analytics.trackEvent('ott', 'mute_toggle');
	}

	$scope.hover = function(event) {
		if($scope.channelsVisible) return;

		$timeout.cancel(leaveTimeout);

		$scope.isHover = true;
		$scope.volumeChangeMove(event);

		if($scope.isFullscreen && !suspendUiFadeOut) {
			$scope.leave();
		}
	}

	$scope.leave = function() {
		if($scope.channelsVisible) return;

		$timeout.cancel(leaveTimeout);

		leaveTimeout = $timeout(function() {
			$scope.isHover = false;
			$scope.volumeChangeEnd();
			$scope.qualitySelectorOpen = false;
		}, 3000);
	}

	$scope.mouseDown = function() {
		$scope.hover(); // kolejność ważna!
		suspendUiFadeOut = true;
	}

	$scope.mouseUp = function() {
		suspendUiFadeOut = false; // kolejność ważna!
		$scope.volumeChangeEnd();
		$scope.hover();
	}

	$scope.volumeChangeStart = function(event) {
		// potrzebyjemy wysokość tego elementu żeby znać %
		volumeChangeStartTarget = event.target;

		$scope.changingVolume = true;
		volumeChange(event);

		Analytics.trackEvent('ott', 'volume_change');
	}

	$scope.volumeChangeEnd = function() {
		$scope.changingVolume = false;
	}

	$scope.volumeChangeMove = function(event) {
		if(!$scope.changingVolume) return;
		volumeChange(event);
	}

	$scope.qualityChange = function(newQuality) {
		fragmentLoadingAfterQChange = true;

		for(var i in $scope.qualities) {
			if($scope.qualities[i].gui.internalName === newQuality) {
				$rootScope.$broadcast('runeVideoQualityChange', $scope.qualities[i].level);
				break;
			}
		}

		Analytics.trackEvent('ott', 'quality_change', newQuality);
	}

	$scope.toggleQualitySelector = function() {
		if(!$scope.qualityId) return;
		$scope.qualitySelectorOpen = !$scope.qualitySelectorOpen;
	}

	$scope.closeQualitySelector = function() {
		$scope.qualitySelectorOpen = false;
	}

	$scope.getRepeats = function(count) {
		var repeats = [];
		for(var i=0; i<count; i++) repeats[i] = i;
		return repeats;
	}

	$scope.toggleChannelsVisible = function() {
		$scope.channelsVisible = !$scope.channelsVisible;

		if($scope.channelsVisible) {
			// po zmianie jest widoczne
			$scope.isHover = false;
			$scope.volumeChangeEnd();
			$scope.qualitySelectorOpen = false;
		}
	}

	$scope.switchChannel = function(targetId) {
		var assets = asset.get();

		for(var i in assets) {
			if(assets[i].sgtid == targetId) {
				if(!assets[i].entitled) throw new Error('not entitled');

				if(typeof assets[i].url === 'undefined' || typeof assets[i].url.hlsAac === 'undefined' || !assets[i].url.hlsAac.length) {
					throw new Error('channel cannot ba played');
				}

				$scope.channelsVisible = false;
				return ottPlay(targetId, assets[i].name, assets[i].url.hlsAac);
			}
		}
	}

	$scope.handleResize = function(_, __, ___, viewportWidth) {
		if(viewportWidth < 960) {
			$scope.epgScale = 740;
		} else {
			$scope.epgScale = 1470;
		}
	}

	$scope.cast = function() {
		if($scope.chromecastAvailable && $scope.canCast) {
			var alternateIds = asset.getAlternateIds($scope.channelId);

			if(alternateIds && alternateIds.vectra_uuid) {
				cast.startDash(
					alternateIds.vectra_uuid,
					$scope.movieName, 
					$scope.movieDescription, 
					$scope.channelId
				);
			} else {
				cast.start($scope.mediaUri.bsReference, $scope.movieName, $scope.movieDescription, $scope.channelId);
			}
			Analytics.trackEvent('ott', 'cast_start_try', $scope.movieName);
		} else {
			toast.push('Chromecast nie jest obsługiwany przez to urządzenie.');
			Analytics.trackEvent('ott', 'cast_start_unsupported');
		}
	}

	$scope.noop = angular.noop;

	// ---------------------------------------

	$rootScope.$on('runeVideoError', function(_, type, details) {
		$scope.errorDetails = '';

		switch(type) {
			case 'media':
				$scope.error = 'Błąd podczas odtwarzania: problem z materiałem';
			break;
			case 'network':
				$scope.error = 'Błąd podczas odtwarzania: błąd sieci';
			break;
			case 'drm':
				$scope.error = 'Błąd systemu ochrony treści';
			break;
			default:
				$scope.error = 'Błąd podczas odtwarzania: inny błąd';
		}

		if(details) {
			$scope.errorDetails = translatedErrorDesc[details];
		}

		Analytics.trackEvent('ott', 'error', type);
	});

	$rootScope.$on('runeVideoFullscreenFail', function() {
		toast.push('Przeglądarka nie wspiera trybu pełnoekranowego.');
		Analytics.trackEvent('ott', 'fullscreen_fail');
	});

	$rootScope.$on('runeVideoFullscreenChange', function() {
		$scope.isFullscreen = isFullscreen();

		// jeśli wyszliśmy z fullscreena, zboradcastuj to żeby przywrócić zapisaną pozycję playera
		if(!$scope.isFullscreen) {
			$scope.channelsVisible = false;
			$rootScope.$broadcast('runeVideoExitFullscreen');
		}
	});

	$rootScope.$on('runeVideoLevelsAvailable', function(_, levels) {
		$scope.qualities = decorateQualities(levels);
	});

	$rootScope.$on('runeVideoLevelsAvailableDash', function(_, levels) {
		$scope.qualities = decorateQualitiesDash(levels);
	});

	$rootScope.$on('runeVideoFragmentLoaded', function() {
		fragmentLoadingAfterQChange = false;
	});

	$rootScope.$on('runeVideoLevelChange', function(_, level) {
		if(fragmentLoadingAfterQChange || level === -1 || !$scope.qualities.length) {
			$scope.quality = '';
			$scope.qualityId = 'unknown';
			return;
		}

		for(var i in $scope.qualities) {
			if($scope.qualities[i].level == level) {
				$scope.quality = $scope.qualities[i].gui.name;
				$scope.qualityId = $scope.qualities[i].gui.internalName;
				break;
			}
		}
	});

	$rootScope.$on('runeVideoPlay', function() {
		$scope.noAutoplayPlaying = true;
	});

	$rootScope.$on('runeVideoPause', function() {
		$scope.noAutoplayPlaying = false;
	});

	// ---------------------------------------

	var volumeChange = function(event) {
		// przy pierwszym kliknięciu w chrome jest undefined bo change przychodzi przed click...?
		if(!event) return;

		// nie chce mi się do tego robić osobnej dyrektywy
		var clientRect = volumeChangeStartTarget.getBoundingClientRect();
		var offsetY = clientRect.bottom - event.clientY;

		var newVolume = offsetY/volumeChangeStartTarget.offsetHeight;

		if(newVolume < 0) newVolume = 0;
		if(newVolume > 1) newVolume = 1;

		if($scope.muted) {
			$rootScope.$broadcast('runeVideoMuteToggle');
			$scope.muted = false;
			storage.set('ott.mute', false);
		}

		$rootScope.$broadcast('runeVideoVolumeChange', newVolume);
		$scope.volume = Math.round(newVolume*100);

		storage.set('ott.volume', $scope.volume);
	}

	var isFullscreen = function() {
		if(typeof document.fullScreen !== 'undefined') return document.fullScreen;
		if(typeof document.mozFullScreen !== 'undefined') return document.mozFullScreen;
		if(typeof document.webkitIsFullScreen !== 'undefined') return document.webkitIsFullScreen;

		return false;
	}

	var updateProgramName = function() {
		if($scope.epgData && $scope.epgData.length) {
			var now = Math.floor(Date.now()/1000);

			for(var i in $scope.epgData) {
				if(now >= $scope.epgData[i].start && now <= $scope.epgData[i].end) {
					$scope.movieName = $scope.epgData[i].name;
					$scope.movieDescription = $scope.epgData[i].description;
					ott.setProgram($scope.movieName);

					return true;
				}
			}
		}

		return false;
	}

	var updateEpg = function() {
		var now = Math.floor(Date.now()/1000);

		if(!$scope.channelId || !$scope.visible) return;
		if(updateProgramName()) return;
		// trzeba zaktualizować epg na dole też FIXME

		// jeśli doszliśmy do tego miejsca to znaczy, że nie mamy ważnego EPG
		// ale nie chcemy aktualizować jeśli nie minęły 3 minuty od ostatniego requesta

		if(lastEpgCurrentDownload+180 > Date.now()/1000) return;

		api.epgGetCurrent($scope.channelId).then(function(result) {
			var data = angular.copy(result.data);

			for(var i in data) {
				data[i].endTimeFormatted = new Date(data[i].end*1000).toLocaleTimeString('pl-PL', { hour: '2-digit', minute: '2-digit'});
			}

			$scope.epgData = data;
			lastEpgCurrentDownload = now;
			updateProgramName();
		}).catch(function() {
			$scope.epgData = null;
		});
	}

	var decorateQualities = function(manifestLevelData) {
		// trzeba to skopiowac żeby nie sortować oryginały po referencji
		var manifestDataCopy = angular.copy(manifestLevelData);

		for(var i in manifestDataCopy) {
			manifestDataCopy[i].gui = {};
			manifestDataCopy[i].gui.bitrate = (Math.floor(manifestDataCopy[i].bitrate/100000)/10).toString().replace('.', ',');

			try {
				if(manifestDataCopy[i].url[0].match(/vectra/i)) {
					throw new Error('cannot recognize quality from playlist name');
				}

				var qualityInternalName = manifestDataCopy[i].url[0].match(/hls_scr_aac\/(.*)?\/playlist.m3u8/)[1]; // throws
				manifestDataCopy[i].gui.internalName = qualityInternalName;

				if(qualityNameMap[qualityInternalName]) {
					manifestDataCopy[i].gui.name = qualityNameMap[qualityInternalName].label;
				} else {
					// ustaw pierwszą dla której zadeklarowany bitrate jest większy niż obecny
					if(!manifestDataCopy[i].gui.name) {
						for(var j in qualityNameMap) {
							manifestDataCopy[i].gui.name = qualityNameMap[j].label;
							if(qualityNameMap[j].bitrate > manifestDataCopy[i].bitrate) break;
						}
					}
				}
			} catch(e) {
				if(typeof manifestDataCopy[i].height === 'undefined' || !manifestDataCopy[i].height) {
					continue; // audio only?
				}

				//strumineie z vectry albo inne jakieś, zrób wykrywanie po bitrate
				manifestDataCopy[i].gui.name = (manifestDataCopy[i].height+'p ');
				manifestDataCopy[i].gui.name += (manifestDataCopy[i].height >= 720) ? 'HD' : 'SD';

				manifestDataCopy[i].gui.internalName = (manifestDataCopy[i].height+'p');
			}
		}

		// przed sortowaniem zapisz tę kolejność, bo ona odpowiada levelom
		for(var level in manifestDataCopy) {
			manifestDataCopy[level].level = parseInt(level);
		}

		return manifestDataCopy.sort(function(a, b) {
			if(a.bitrate > b.bitrate) return -1;
			if(b.bitrate > a.bitrate) return 1;
			return 0;
		});
	}

	var decorateQualitiesDash = function(manifestLevelData) {
		var qualities = angular.copy(manifestLevelData);
		var qualitiesParsed = [];

		for(var i in qualities) {
			qualitiesParsed.push({
				dash: true, 
				level: qualities[i].qualityIndex, 
				maxBitrate: qualities[i].bitrate, 
				gui: {
					name: qualities[i].height+'p '+(qualities[i].height < 720 ? 'SD' : 'HD'),
					bitrate: (qualities[i].bitrate/1e6).toString().replace('.', ','),
					internalName: 'level'+i,
				},
			});
		}

		return qualitiesParsed.sort(function(a, b) {
			if(a.gui.bitrate > b.gui.bitrate) return -1;
			if(b.gui.bitrate > a.gui.bitrate) return 1;
			return 0;
		});
	}

	var getAssets = function() {
		var assets = asset.get();
		var newChannelList = [];

		for(var i in assets) {
			if(typeof assets[i].url === 'undefined') continue;
			if(typeof assets[i].url.hlsAac === 'undefined') continue;
			if(!assets[i].url.hlsAac.length) continue;

			newChannelList.push({
				id: assets[i].sgtid,
				entitled: assets[i].entitled,
			});
		}

		$scope.channels = newChannelList;
	}

	var ottPlay = function(id, name, source) {
		var play = function() {
			if(typeof source !== 'undefined' && source.length) {
				ott.play(id, name, source);
				ott.setLabel(name);
				ott.setChannelId(id);
			}
		}

		if(!ott.initialized()) {
			ott.init().then(play);
		} else {
			play();
		}
	}

	var handleCastStateChange = function(newState) {
		$scope.chromecastState = newState;

		if(newState !== 'IDLE') {
			try {
				$scope.castMedia = cast.getMedia();
			} catch(e) {
				$scope.castMedia = {};
			}
		}

		$rootScope.$broadcast('chromecastInfo', {
			casting: (newState !== 'IDLE'),
			media: $scope.castMedia,
		});
	}

	// ---------------------------------------

	ott.addObserver('videoOttObserver', function(type, data) {
		switch(type) {
			case 'startPlayback':
			case 'startPlaybackDash':
			case 'startPlaybackApple':
				$scope.error = null;
				$scope.errorDetails = '';

				$scope.visible = true;

				switch(type) {
					case 'startPlayback':
						$scope.mediaUri.bsReference = data;
					break;
					case 'startPlaybackDash':
						$rootScope.$broadcast('runeLicenseUriSet', data.license);
						$scope.mediaUri.bsReference = data.url;
					break;
					// case 'startPlaybackApple':

					// var license = api.getDashLicense(data.uuid);

					// var auth = api.authCallback(license).headers;
					// var headers = [
					// 	{name: 'X-Auth', value: auth['X-Auth']},
					// 	{name: 'X-Nonce', value: auth['X-Nonce']},
					// 	{name: 'X-Impersonate', value: auth['X-Impersonate']},
					// 	{name: 'X-Device-Id', value: auth['X-Device-Id']},
					// ];

					// try{
					// 	$scope.mediaUri.bsReference = data.url;
					// 	var src = {
					// 		fps: $scope.mediaUri.bsReference,
					// 	}

					// 	var settings = {
					// 		licenseKey: api.getDashLicense(data.uuid),
					// 		src: src,
					// 		width: 640,
					// 		height: 360,
					// 		fpsDrm: {
					// 			certificatePath: data.cert,
					// 			processSpcPath: data.src,
					// 			// licenseRequestHeaders: headers,
					// 		  }
					// 	}
					// 	var rmp = new RadiantMP('video');
					// 	rmp.init(settings);
					// }
					// catch(error){
					// 	console.log(error)
					// }
					// break;
				}

				$rootScope.$broadcast('runeVideoVolumeChange', $scope.volume/100);
				$rootScope.$broadcast('runeVideoMuteSet', $scope.muted);

				// tutaj znowu mi się nie che pisać dyrektywy
				document.body.classList.add('video-visible');

				Analytics.trackEvent('ott', 'playback_start');
			break;
			case 'tokenFail':
			case 'tokenLost':
				$scope.mediaUri.bsReference = null;
				$scope.visible = false;
				document.body.classList.remove('watch-standalone');
			break;
			case 'setLabel':
				$scope.channelName = data;
			break;
			case 'setChannelId':
				$scope.epgData = null;
				lastEpgCurrentDownload = 0;

				$scope.channelId = data;
				updateEpg();

				$interval.cancel(epgUpdateInterval);
				epgUpdateInterval = $interval(updateEpg, 60*1000);
				$scope.canCast = asset.getCast($scope.channelId);
			break;
			case 'stopPlayback':
				// najpierw powiadomienie, bo po stop() jest visible: false
				if(data && $scope.visible) {
					switch(data) {
						case 'deviceChange':
							toast.push('Odtwarzanie przerwane z powodu zmiany urządzenia.');
						break;
					}
				}

				$scope.stop();
			break;
			case 'qualitiesAvalable':
				qualityNameMap = ott.getQualities();

				if(!Object.keys(qualityNameMap).length) {
					qualityNameMap = defaultQualityNameMap;
				}
			break;
		}
	});

	asset.addObserver('videoAssetObserver', function(type) {
		switch(type) {
			case 'change':
				getAssets();
			break;
		}
	});

	sizing.addObserver('videoSizingObserver', function(type, rect) {
		switch(type) {
			case 'change':
				$scope.clientRect = rect;
			break;
		}
	});

	cast.addObserver('videoCastObserver', function(type, data) {
		switch(type) {
			case 'start':
				ott.stop('cast_start');
				Analytics.trackEvent('ott', 'cast_start_ok');
			break;
			case 'available':
				$scope.chromecastAvailable = true;
			break;
			case 'state':
				handleCastStateChange(data);
			break;
		}
	});

	$scope.$watch('epgData', function(newValue) {
        state.set('channelEpg', newValue);
    });

	$scope.$on('$destroy', function() {
		asset.removeObserver('videoAssetObserver');
		ott.removeObserver('videoOttObserver');
		sizing.removeObserver('videoSizingObserver');
		cast.removeObserver('videoCastObserver');

		cast.destroy();
	});

	if(asset.has()) {
		getAssets();
	} else {
		asset.update();
		// pobierze observer
	}

	$scope.clientRect = sizing.get();
	cast.prepare();

}]);