angular.module('webpanel').service('popup', ['$interval', '$timeout', function($interval, $timeout) {
	var popups = [];
	var observers = [];

	var suspended = 0;
	var busy = false;

	var currentPopup = null;
	var iteration = 0;

	var autoExitTimeout;

	var Popup =  function() {
		var buttons = [];
		var message = '';
		var defaultAction = angular.noop;
		var _hash = 0;
		var eventCallbacks = [];
		var blocking = false;

		var setText = function(text) {
			message = text;
			generateHash();
		}

		var addButton = function(label, callable, cssClass, isDefault) {
			if(typeof isDefault !== 'boolean') isDefault = false;
			var button = new PopupButton();

			button.setLabel(label);
			button.setAction(callable);
			button.setClass(cssClass);

			buttons.push(button);
			if(isDefault) setDefault(callable);

			generateHash();
		}

		var setDefault = function(action) {
			if(typeof action === 'function') {
				defaultAction = action;
			}
		}

		var generateHash = function() {
			var string = message;

			for(var i in buttons) {
				string += ('|'+buttons[i].getLabel());
			}

			var stringHash = 0;
			var character;

			if (string.length === 0) return stringHash;

			for(var j=0; j<string.length; j++) {
				character = string.charCodeAt(j);
				stringHash = ((stringHash << 5) - stringHash) + character;
				stringHash |= 0;
			}

			_hash = stringHash;
		}

		var on = function(event, callable) {
			// unikamy kolizji nazw z metodami Array (np. push, pop... ;)
			event = '__'+event;

			if(typeof callable === 'function') {
				if(typeof eventCallbacks[event] === 'undefined') eventCallbacks[event] = [];
				eventCallbacks[event].push(callable);
			}
		}

		var setBlocking = function() {
			blocking = true;
		}

		var getText = function() {
			return message;
		}

		var getButtons = function() {
			return buttons;
		}

		var getDefault = function() {
			return defaultAction;
		}

		var getHash = function() {
			return _hash;
		}

		var fire = function(event) {
			event = '__'+event;

			if(typeof eventCallbacks[event] !== 'undefined') {
				for(var i in eventCallbacks[event]) {
					eventCallbacks[event][i]();
				}
			}
		}

		var isBlocking = function() {
			return blocking;
		}

		return {
			setText: setText,
			addButton: addButton,
			getText: getText,
			getButtons: getButtons,
			getDefault: getDefault,
			getHash: getHash,
			setBlocking: setBlocking,
			isBlocking: isBlocking,
			on: on,
			fire: fire,
		}
	}

	var PopupButton = function() {
		var action = function() {}
		var label = '';
		var _class = '';

		var setLabel = function(text) {
			if(typeof text !== 'string') return;
			label = text;
		}

		var setAction = function(callable) {
			if(typeof callable !== 'function') return;
			action = callable;
		}

		var setClass = function(cssClass) {
			if(typeof cssClass !== 'string') return;
			_class = cssClass;
		}

		var getLabel = function() {
			return label;
		}

		var getAction = function() {
			return action;
		}

		var getClass = function() {
			return _class;
		}

		return {
			setLabel: setLabel,
			setAction: setAction,
			getLabel: getLabel,
			getAction: getAction,
			getClass: getClass,
			setClass: setClass,
		}
	}

	var ready = function() {
		$interval(function() {
			iteration++;

			if(suspended || busy) {
				if(iteration%60 === 0) iteration = 0;
				return;
			}

			currentPopup = shift();

			if(currentPopup) {
				busy = true;

				currentPopup.fire('beforedigest');
				notifyObservers('popupPop', currentPopup);
				currentPopup.fire('afterdigest');

				if(!currentPopup.getButtons().length && !currentPopup.isBlocking()) {
					autoExitTimeout = $timeout(function() {
						currentPopup.fire('autoresolve');
						notifyObservers('popupClose');
					}, 4000);
				}
			}
		}, 300);
	}

	var push = function(popup) {
		for(var i in popups) {
			if(popup.getHash() === popups[i].getHash()) {
				popup.fire('reject');
				return;
			}
		}

		popup.fire('push');
		popups.push(popup);
	}

	var shift = function() {
		if(!popups.length) return false;

		var nextPopup = popups.shift();
		nextPopup.fire('shift');

		return nextPopup;
	}

	var suspend = function() {
		suspended++;
	}

	var resume = function() {
		suspended--;
	}

	var create = function() {
		return new Popup();
	}

	var resolve = function() {
		currentPopup.fire('resolve');
		currentPopup = null;
		$timeout.cancel(autoExitTimeout);
		busy = false;
	}

	var addObserver = function(name, observer) {
		if(typeof observer !== 'function') return;
		if(typeof observers[name] !== 'undefined') return;
		observers[name] = observer;
	}

	var removeObserver = function(name) {
		delete observers[name];
	}

	var notifyObservers = function(eventType, eventData) {
		for(var i in observers) {
			observers[i](eventType, eventData);
		}
	}

	return {
		push: push,
		suspend: suspend,
		resume: resume,
		create: create,
		ready: ready,
		resolve: resolve,
		addObserver: addObserver,
		removeObserver: removeObserver,
	}
}]);