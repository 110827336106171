angular.module('webpanel').controller('MessageController', ['$scope', 'message', function($scope, message) {
	$scope.messages = {};
	$scope.chosenMessage = null;
	$scope.count = -1;

	message.addObserver('messageMessageObserver', function(type) {
		switch(type) {
			case 'change':
				$scope.messages = message.get();
				$scope.count = Object.keys($scope.messages).length;
			break;
		}
	});

	var choose = function(chosenMessage) {
		$scope.chosenMessage = chosenMessage;
		if(!chosenMessage.read) message.read(chosenMessage.id)

		$scope.analytics.trackEvent('message', 'view', chosenMessage.teaser.body);
	}

	$scope.$on('$destroy', function() {
		message.removeObserver('messageMessageObserver');
	});

	$scope.choose = choose;

	$scope.closePane = function() {
		$scope.chosenMessage = null;
	}

	if($scope.user.isDemo()) {
		$scope.showLoginPrompt(true);
	} else {
		message.update();
		$scope.messages = message.get();
	}

}]);