angular.module('webpanel').directive('dragLite', function($rootScope, $window, localStorageService) {
	var handle;
	var dragElement;

	var anchorStartY;
	var anchorStartX;
	var beforeFullscreenTop = null;
	var beforeFullscreenLeft = null;

	$rootScope.$on('runeVideoGoFullscreen', function() {
		beforeFullscreenTop = dragElement.style.top;
		beforeFullscreenLeft = dragElement.style.left;

		dragElement.removeAttribute('style');
	});

	$rootScope.$on('runeVideoExitFullscreen', function() {
		if(beforeFullscreenTop !== null && beforeFullscreenLeft !== null) {
			dragElement.style.top = beforeFullscreenTop;
			dragElement.style.left = beforeFullscreenLeft;

			beforeFullscreenTop = null;
			beforeFullscreenLeft = null;
		}
	});

	return {
		link: function(scope, element) {
			var dragInProgress = false;
			dragElement = element[0];

			var onMouseUp = function() {
				dragInProgress = false;
				element[0].classList.remove('moving');
			}

			var onMouseDown = function(e) {
				dragInProgress = true;
				element[0].classList.add('moving');

				anchorStartY = element[0].offsetTop-e.pageY;
				anchorStartX = element[0].offsetLeft-e.pageX;
			}

			var onMouseMove = function(e) {
				if(dragInProgress) {
					var newTop = e.pageY+anchorStartY;
					var newLeft = e.pageX+anchorStartX;

					setPosition(newTop, newLeft);

					e.preventDefault();
					e.stopPropagation();
				}
			}

			var setPosition = function(newTop, newLeft) {
				if(newTop < 0) newTop = 0;
				if(newLeft < 0) newLeft = 0;

				if(newTop > $window.innerHeight-element[0].offsetHeight) newTop = $window.innerHeight-element[0].offsetHeight;
				if(newLeft > $window.innerWidth-element[0].offsetWidth) newLeft = $window.innerWidth-element[0].offsetWidth;

				element[0].style.top = newTop+'px';
				element[0].style.left = newLeft+'px';

				localStorageService.set('ott.position.top', newTop);
				localStorageService.set('ott.position.left', newLeft);
			}

			var savedPositionTop = localStorageService.get('ott.position.top');
			var savedPositionLeft = localStorageService.get('ott.position.left');

			if(savedPositionTop !== null && savedPositionLeft !== null) {
				setPosition(savedPositionTop, savedPositionLeft);
			}

			handle = angular.element('<div>');
			handle[0].className = 'video__button drag-handle';

			element.append(handle);

			handle.on('mousedown', onMouseDown);
			handle.on('mouseup', onMouseUp);

			angular.element($window).on('mousemove', onMouseMove);
			angular.element($window).on('mouseup', onMouseUp);

			scope.$on('$destroy', function() {
				handle.off('mousedown', onMouseDown);
				handle.off('mouseup', onMouseUp);
				handle.off('mousemove', onMouseMove);
				handle.off('mouseleave', onMouseLeave);

				angular.element($window).off('mousemove', onMouseMove);
				angular.element($window).off('mouseup', onMouseUp);

				handle.remove();
			});
		}
	}
});