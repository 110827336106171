angular.module('webpanel').directive('contentChanged', function() {
	
	return {
		scope: {
			contentChanged: '=',
			ccReferenceContainer: '@',
		},
		
		link: function(scope, element) {
			var observer = new MutationObserver(function(mutations) {
				scope.contentChanged(element[0], mutations, document.querySelector(scope.ccReferenceContainer));
			});
			
			observer.observe(element[0], {
				childList: true,
			});
			
			scope.$on('$destroy', function() {
				observer.disconnect();
				observer = undefined;
			});
		}
	}
});


