angular.module('webpanel').service('toast', [function() {
	var observers = {};

	var addObserver = function(name, observer) {
		if(typeof observer !== 'function') return;
		if(typeof name !== 'string') return;

		observers[name] = observer;
	}

	var removeObserver = function(_, name) {
		delete observers[name];
	}

	var notifyObservers = function(type, data) {
		for(var i in observers) observers[i](type, data);
	}

	var push = function(message, icon, persistence) {
		if(typeof message !== 'string') return;
		if(typeof icon !== 'string') icon = null;
		if(typeof persistence !== 'boolean') persistence = false;

		notifyObservers('push', {
			message: message,
			icon: icon,
			persistence: persistence,
		});
	}

	return {
		push: push,
		addObserver: addObserver,
		removeObserver: removeObserver,
	}

}]);