angular.module('webpanel').directive('fileread', [function() {
	return {
		scope: {
			callback: '='
		},
		link: function(scope, element) {
			element.bind('change', function(changeEvent) {
				var reader = new FileReader();

				reader.onload = function(loadEvent) {
					scope.$apply(function() {
						if(typeof scope.callback === 'function') {
							scope.callback(loadEvent.target.result);
							element[0].value = '';
						}
					});
				}

				reader.readAsText(changeEvent.target.files[0]);
			});
		}
	 }
}]);