angular.module('webpanel').controller('PopupController', ['$scope', '$interval', '$timeout', 'popup', 'toast', function($scope, $interval, $timeout, popup, toast) {
	$scope.currentPopup = null;
	$scope.currentToast = null;

	var toastBacklog = [];
	var toastLock = false;
	var toastBacklogInterval;
	var toastCleanTimeout;
	var toastAnimationTimeout;

	$scope.execute = function(callable) {
		var callableResult = callable();
		$scope.currentPopup = null;
		popup.resolve();

		return callableResult;
	}

	popup.ready();

	popup.addObserver('popupPopupObserver', function(event, popupInstance) {
		switch(event) {
			case 'popupPop':
				$scope.currentPopup = {
					text: popupInstance.getText(),
					_default: function() {
						var action = popupInstance.getDefault();
						$scope.execute(action);
					},
					buttons: [],
				}

				var buttons = popupInstance.getButtons();

				for(var i in buttons) {
					$scope.currentPopup.buttons.push({
						label: buttons[i].getLabel(),
						action: buttons[i].getAction(),
						class: buttons[i].getClass(),
					});
				}
			break;
			case 'popupClose':
				$scope.currentPopup = null;
			break;
		}
	});

	toast.addObserver('popupToastObserver', function(type, data) {
		switch(type) {
			case 'push':
				toastBacklog.push(data);
			break;
		}
	});

	toastBacklogInterval = $interval(function() {
		if(toastLock) return;
		if(toastBacklog.length < 1) return;

		toastLock = true;
		$scope.currentToast = toastBacklog.shift();
		$scope.currentToast.visibility = 1;

		toastCleanTimeout = $timeout(function() {
			$timeout.cancel(toastCleanTimeout);
			// nie musimy czyścić $scope.currentToast, bo kolejny toast nadpisze
			$scope.currentToast.visibility = 2;

			toastAnimationTimeout = $timeout(function() {
				$timeout.cancel(toastAnimationTimeout);
				// zwalniamy blokadę dopiero po zakończeniu animacji poprzedniego
				$scope.currentToast.visibility = 0;
				toastLock = false;
			}, 1337);
		}, 5000);
	}, 1000);

	$scope.$on('$destroy', function() {
		$timeout.cancel(toastCleanTimeout);
		$timeout.cancel(toastAnimationTimeout);
		$interval.cancel(toastBacklogInterval);

		popup.removeObserver('popupPopupObserver');
		toast.removeObserver('popupToastObserver');
	});
}]);