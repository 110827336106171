angular.module('webpanel').directive('popupDefaultAction', function($document) {
	return {
		scope: {
			popupDefaultAction: '=',
		},

		link: function(scope) {
			var handler = function(event) {
				if(event.keyCode === 13 || event.key === 'Enter') {
					scope.$apply(scope.popupDefaultAction);
				}
			}

			$document.on('keydown', handler);

			scope.$on('$destroy', function() {
				$document.off('keydown', handler);
			});
		}
	}
});