angular.module('webpanel').controller('WelcomeController', ['$scope', '$rootScope', 'api', 'device', 'auth', function($scope, $rootScope, api, device, auth) {
	$scope.recommended = [];

	var promotionLength = 24;
	$scope.promo = {};

	var day = (new Date()).getDate();
	var month = (new Date()).getMonth();
	$scope.showPromo = false;

	var promoAlts = [
		'Furioza',
		'Lena i Śnieżek - tylko na TV SMART',
		'Śniegu już nigdy nie będzie',
		'Smerfy2 - tylko na TV SMART',
		'Całe szczęście',
		'Kevin sam w domu - tylko na TV SMART',
		'Sztuka kochania',
		'Jak rozmawiać z psem',
		'Strażnicy Galaktyki vol.2 - tylko na TV SMART',
		'The social network - tylko na TV SMART',
		'Piotruś Pan i Alicja w Krainie Czarów',
		'Złe Mamuśki 2. Jak przetrwać Święta - tylko na TV SMART',
		'Nieznośny ciężar wielkiego talentu',
		'Pies w rozmiarze XXL - tylko na TV SMART',
		'Spider Man: Bez drogi do domu - tylko na TV SMART',
		'THOR: Miłość i grom - tylko na TV SMART',
		'X-men Mroczna Phoenix - tylko na TV SMART',
		'Świąteczny anioł',
		'Planeta Singli - tylko na TV SMART',
		'Miłość, ślub i inne nieszczęścia',
		'Szczęściarze',
		'Skarb Mikołajka',
		'Listy do M 4',
		'Planeta Singli 2 - tylko na TV SMART',
	]

	if($scope.location.hash() === 'promotest') $scope.showPromo = true;

	var getRecommended = function() {
		api.recommendedGet().then(function(response) {
			$scope.recommended = response.data;
		}).catch(function() {
			var pop = $scope.popup.create();
			pop.setText('Nie udało się załadować rekomendacji. Spróbuj ponownie.');
			pop.addButton('OK', angular.noop, 'yes', true);
			$scope.popup.push(pop);
		});
	}

	var preparePromo = function() {
		$scope.promo = {};

		api.promoGet().then(function(response) {
			for(var i=1; i<=promotionLength; i++) {
				$scope.promo[i] = {
					day: i,
					open: day > i,
					current: day == i,
					filename: response.data[i],
					alt: promoAlts[i-1],
				};
			}
		}).catch(function() {
			var pop = $scope.popup.create();
			pop.setText('Nie udało się załadować informacji o promocjach. Spróbuj ponownie.');
			pop.addButton('OK', angular.noop, 'yes', true);
			$scope.popup.push(pop);
		});
	}

	$scope.reveal = function(item) {
		if(item.current) $scope.promo[item.day].open = true;
	}

	$scope.user.addObserver('welcomeUserObserver', function(type) {
		switch(type) {
			case 'change':
				if($scope.showPromo) {
					if(device.has()) preparePromo();
				} else {
					if(device.has()) getRecommended();
				}
			break;
		}
	});

	$scope.$on('$destroy', function() {
		$scope.user.removeObserver('welcomeUserObserver');
	});

	if (!$rootScope.disableQuality) {
		$rootScope.disableQuality = $scope.location.search().changeQuality === 'disable';
	}

	if($scope.showPromo) {
		$scope.$on('deviceAcquired', preparePromo);
		if(device.has() || auth.isDemo()) preparePromo();
	} else {
		$scope.$on('deviceAcquired', getRecommended);
		if(device.has() || auth.isDemo()) getRecommended();
	}

}]);