angular.module('webpanel').controller('SimOrderController', ['$scope', 'service', 'api', function($scope, service, api) {
	$scope.services = [];
	$scope.packageOrderList = [];

	$scope.currentServicesHidden = false;
	$scope.orderHasLoaded = false;
	$scope.serviceHasLoaded = false;

	$scope.successMsg = 'Dziękujemy za dokonanie zakupu. Pakiet będzie dostępny w ciągu kilku minut.'

	$scope.selectedPackage = null;

	var getServices = function() {
		$scope.services = service.get();
		orderByPriority();

		$scope.currentServicesHidden = false;
		$scope.serviceHasLoaded = $scope.services.length;
		
		api.servicesGetForOrder('group', [103, 133].join(',')).then(function(response) {
			$scope.packageOrderList = response.data;
			$scope.orderHasLoaded = true;
		});
	}

	var orderByPriority = function() {
		if($scope.services.length) {
			$scope.services = $scope.services.sort(function(a,b) {
				return a.type - b.type;
			});
		}
	}

	service.addObserver('tvServiceServiceObserver', function(type, device) {
		switch(type) {
			case 'change':
				// czasem przychodzą eventy od poprzedniego urządzenia typu
				// stb bo jest race conditon przy automatycznej zmianie typu na simcard
				// i nie da się anulować promisa
				if(device.type === 'stb') return;
				getServices();
			break;
		}
	});

	$scope.$on('$destroy', function() {
		service.removeObserver('tvServiceServiceObserver');
	});

	$scope.toggleCurrent = function() {
		$scope.currentServicesHidden = !$scope.currentServicesHidden;
	}

	$scope.order = function(package) {
		if(!$scope.checkHasEmail()) return;

		$scope.selectedPackage = package;
		$scope.analytics.trackEvent('order', 'select', package.name);
	}

	$scope.orderOnCancel = function() {
		$scope.selectedPackage = null;
	}

	$scope.orderOnSuccess = function() {
		$scope.selectedPackage = null;
		service.update();
	}

	if($scope.user.isDemo()) {
		$scope.showLoginPrompt(true);
	} else {
		getServices();
	}

}]);