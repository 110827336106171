angular.module('webpanel').controller('RecordingController', ['$scope', '$rootScope', '$interval', '$timeout', 'recording', 'device', 
	function($scope, $rootScope, $interval, $timeout, recording, device) {
		
	$scope.recordings = [];
	$scope.chosenRecording = null;

	$scope.space = 0;
	$scope.count = -1;

	$scope.now = Math.floor(Date.now()/1000);
	$scope.obliterateReady = false;

	var deletedPosition;
	var obliterateTimeout;

	var choose = function(recording) {
		$scope.chosenRecording = recording;
	}

	$scope.closePane = function() {
		$scope.chosenRecording = null;
	}

	$scope.recordDelete = function(entry) {
		if(!$scope.checkHasEmail()) return;
		if(typeof entry === 'undefined' || typeof entry.id === 'undefined') return;

		var index = Object.keys($scope.recordings);

		for(var i in $scope.recordings) {
			if(entry.id === $scope.recordings[i].id) {
				deletedPosition = index.indexOf(i);
				break;
			}
		}

		recording.remove(parseInt(entry.id));
		$scope.analytics.trackEvent('recording', 'delete', entry.rec_title);
	}

	$scope.recordStop = function(entry) {
		if(!$scope.checkHasEmail()) return;
		if(typeof entry === 'undefined' || typeof entry.id === 'undefined') return;

		recording.change(entry.id, $scope.now);
		$scope.analytics.trackEvent('recording', 'stop', entry.rec_title);
	}

	$scope.recordResume = function(entry) {
		if(!$scope.checkHasEmail()) return;
		if(typeof entry === 'undefined' || typeof entry.id === 'undefined') return;

		recording.change(entry.id, 0);
		$scope.analytics.trackEvent('recording', 'resume', entry.rec_title);
	}

	$scope.choose = choose;

	$scope.$on('$destroy', function() {
		recording.removeObserver('recordingRecordingObserver');
		device.removeObserver('watchDeviceObserver');
	});

	$scope.prepareObliterate = function() {
		if(!$scope.checkHasEmail()) return;

		$scope.obliterateReady = true;
		obliterateTimeout = $timeout($scope.cancelObliterate, 5000);
	}

	$scope.obliterate = function() {
		if(!$scope.checkHasEmail()) return;

		$timeout.cancel(obliterateTimeout);
		recording.obliterate();
		$scope.obliterateReady = false;

		$scope.analytics.trackEvent('recording', 'obliterate');
	}

	$scope.cancelObliterate = function() {
		$timeout.cancel(obliterateTimeout);
		$scope.obliterateReady = false;
	}

	// ----------------------------------------

	recording.addObserver('recordingRecordingObserver', function(type) {
		switch(type) {
			case 'listChange':
				try {
					$scope.recordings = recording.get();
					$scope.count = Object.keys($scope.recordings).length;

					for(var i in $scope.recordings) {
						var description = $scope.recordings[i].rec_ext_info.split('***');
						var newDescription = description[0]
						if(description[1]) newDescription += '<br><br>'+description[1];

						$scope.recordings[i].rec_ext_info = newDescription;
						$scope.recordings[i].duration = Math.ceil(($scope.recordings[i].rec_to-$scope.recordings[i].rec_from)/60);
					}
				} catch(e) {
					$scope.recordings = [];
				}
			break;
			case 'listFail':
				$scope.count = 0;
			break;
			case 'storageChange':
				$scope.space = ''.replace.call(recording.getSpace(), '.', ',');
			break;
			case 'removeSuccess':
				if(deletedPosition > -1) {
					var index = Object.keys($scope.recordings);

					var candidateNext = $scope.recordings[index[deletedPosition]];
					var candidatePrev = $scope.recordings[index[deletedPosition-1]];

					if(typeof candidateNext !== 'undefined') {
						choose(candidateNext);
					} else if(typeof candidatePrev !== 'undefined') {
						choose(candidatePrev);
					} else {
						$scope.chosenRecording = null;
					}
				}
			break;
		}
	});

	device.addObserver('watchDeviceObserver', function(type) {
		if($scope.user.isDemo()) return;
		if (type === 'selectSuccess' && !$rootScope.access.npvr) return $scope.location.path('/');

	});

	$interval(function() {
		$scope.now = Math.floor(Date.now()/1000);
	}, 10000);

	if($scope.user.isDemo()) {
		$scope.showLoginPrompt(true);
	} else {
		recording.update();
		$scope.recordings = recording.get();
	}

}]);