angular.module('webpanel').controller('WatchController', ['$scope', '$rootScope', '$interval', 'asset', 'ott', 'state', 'api', 'setting', 'device', 'cast','auth',
	function($scope, $rootScope, $interval, asset, ott, state, api, setting, device, cast, auth) {

	$scope.assets = [];
	$scope.channelEpg = [];

	$scope.currentChannelId = null;
	$scope.currentChannelName = '';
	$scope.currentProgramName = '';

	$rootScope.isPlaying = false;

	$scope.epgScale = 800;
	$scope.isEpgVertical = false;

	$scope.sidebarWidth = 0;
	$scope.epgHeight = 0;

	var assetsReady = false;
	var epgUpdateInterval;
	var lastEpgCurrentDownload = 0;

	// -----------------------------------------------------

	$scope.play = function(id, channelName, url, programName, description) {
		if($scope.user.isDemo()) return;
		if(!$scope.checkHasEmail()) return;

		if(cast.getMedia().state === 'PLAYING' || cast.getMedia().state === 'PAUSED') {
			// var alternateIds = asset.getAlternateIds(id);
			
			// if(alternateIds && alternateIds.vectra_uuid) {
			// 	cast.zapDash(alternateIds.vectra_uuid, programName, description, id);
			// } else {
				cast.zap(ott.addCredentials(url), programName, description, id);
			// }
			return;
		}

		$scope.analytics.trackEvent('watch', 'watch', channelName);
		document.body.classList.add('watch-standalone');

		$scope.currentChannelId = id;
		$scope.currentChannelName = channelName;

		$scope.currentProgramName = (typeof programName === 'undefined') ? '' : (' - '+programName);

		ott.play(id, channelName, url);
	}

	$scope.playLast = function() {
		if(!$scope.assets.length) return;
		if(!$scope.assets[0].epg) return; // jak jest w pierwszym, to jest we wszystkich

		var lastId = setting.get().lastchannel;

		if(typeof lastId !== 'undefined') {
			lastId = Math.abs(lastId); // lista 100 jest ujemna ;)

			for(var i in $scope.assets) {
				if($scope.assets[i].id == lastId) { // settingsy są niestety stringami
					$scope.play($scope.assets[i].id, $scope.assets[i].name, $scope.assets[i].url, $scope.assets[i].epg.name);
					return;
				}
			}
		}

		// jeśli nie znaleziono w assetach dostępnych w OTT
		$scope.play($scope.assets[0].id, $scope.assets[0].name, $scope.assets[0].url, $scope.assets[0].epg.name);
	}

	$scope.resize = function(width, height) {
		var unit = $scope.clientRect.height;

		var sidebarWidth = 27.3*unit;
		var epgHeight = 14.1*unit;

		var videoWidth = width-sidebarWidth;
		var videoHeight = height-epgHeight;

		var channelListTop = 0;
		// obfuskator się wykłada jeśli nie zadeklaruję tu tego
		var newEpgHeight, targetVideoHeight, targetVideoWidth, newSidebarWidth;

		if(window.innerWidth > 1399) {
			if(videoWidth*9 !== videoHeight*16) {
				if(videoWidth*9 > videoHeight*16) {
					targetVideoHeight = videoHeight;
					targetVideoWidth = videoHeight/9*16;
					newEpgHeight = height - videoHeight - 2.7*unit;
					newSidebarWidth = width - targetVideoWidth;
				} else {
					targetVideoWidth = videoWidth;
					targetVideoHeight = videoWidth/16*9;
					newEpgHeight = height - targetVideoHeight - 2.7*unit;
					newSidebarWidth = width - targetVideoWidth;
				}

				$scope.sidebarWidth = newSidebarWidth;
				$scope.epgHeight = newEpgHeight;
			}
		} else {
			targetVideoHeight = width/16*9;
			if(targetVideoHeight > .4*window.innerHeight) targetVideoHeight = .4*window.innerHeight;

			// CSS to nadpisuje i tak
			targetVideoWidth = 0;
			newEpgHeight = 0;
			newSidebarWidth = 0;

			var channelListTop = targetVideoHeight + 3.85*unit; // belka tytułowa

			$scope.sidebarWidth = 0;
			$scope.epgHeight = 0;
		}

		// nie chcemy za bardzo skurczonego EPG
		$scope.isEpgVertical = (newEpgHeight > 200);
		// wiemy że targetVideoWidth = epg width bo taki mamy layout
		$scope.epgScale = targetVideoWidth < 600 ? 500 : 800;

		// 1. telefony mulą, 2. to nie jest potrzebne na layoucie mobilnym
		if(!isMobile()) {
			recalcUiStyle(targetVideoWidth, targetVideoHeight, channelListTop);
		}
	}

	// -----------------------------------------------------

	var isMobile = function() {
		return !!(
			navigator.userAgent.match(/android/i)
			|| navigator.userAgent.match(/webOS/i)
			|| navigator.userAgent.match(/iPhone/i)
			|| navigator.userAgent.match(/iPad/i)
			|| navigator.userAgent.match(/iPod/i)
		);
	}

	var updateEpg = function() {
		return new Promise(function(resolve) {
			var now = Math.floor(Date.now()/1000);
			if(lastEpgCurrentDownload+180 > Date.now()/1000) return;

			api.epgGetCurrentAll().then(function(result) {
				var data = angular.copy(result.data);
				lastEpgCurrentDownload = now;

				for(var i in $scope.assets) {
					if(typeof data[$scope.assets[i].id] === 'undefined') continue;

					$scope.assets[i].epg = {
						'name': data[$scope.assets[i].id].name,
						'description': data[$scope.assets[i].id].description,
					}
				}

				resolve();
			}).catch(function(e) {
				console.error(e);

				resolve();
			});
		});
	}

	var recalcUiStyle = function(targetVideoWidth, targetVideoHeight, channelListTop) {
		var epgContent = document.querySelector('.watch__video__epg .ss-content');
		var epgTrack = document.querySelector('.watch__video__epg .ss-track');
		var channelList = document.querySelector('.watch__channels');
		var player = document.querySelector('.video');
		var playerPlaceholder = document.querySelector('.watch__video__placeholder');

		var scrollbarSize = $scope.clientRect.scrollbar;

		try {
			if($scope.isEpgVertical) {
				epgContent.style.height = '';
			} else {
				epgContent.style.height = 'calc(100% + '+scrollbarSize+'px)';
			}

			epgTrack.style.height = 'calc(100% - '+scrollbarSize+'px)';
		} catch(e) {
			console.error(e);
		}

		try {
			player.style.width = targetVideoWidth+'px';
			player.style.height = targetVideoHeight+'px';
		} catch(e) {
			console.error(e);
		}

		try {
			playerPlaceholder.style.width = targetVideoWidth+'px';
			playerPlaceholder.style.height = targetVideoHeight+'px';
		} catch(e) {
			console.error(e);
		}

		try {
			setTimeout(function() {
				if(channelListTop) {
					channelList.style.top = channelListTop+'px';
				} else {
					channelList.style.top = '';
				}
			}, 0);
		} catch(e) {
			console.error(e);
		}

	}

	$rootScope.fixPlayerSize = function() {
		var container = document.querySelector('.watch');
		$scope.resize(container.clientWidth, container.clientHeight);
	}

	var cleanPlayerStyles = function() {
		var player = document.querySelector('.video');

		player.style.width = null;
		player.style.height = null;
	}

	var getAssets = function() {
		return new Promise(function(resolve) {
			if(assetsReady) return;

			$scope.assets = [];
			if(!asset.has()) {
				asset.update();
				return; //async handler zawoła getAssets()
			}

			var assets = asset.get();
			var newAssetList = [];

			// jeśli kanał jest na liście 100 nie chcemy go powtarzać
			// ani na liście 100, ani dalej w kanonicznych assetach
			var uniqueAssetIdList = [];

			for(var i in assets) {
				if(!assets[i].entitled) continue;
				if(uniqueAssetIdList.indexOf(assets[i].sgtid) >= 0) continue;

				try {
					if(assets[i].alternate_id.vectra_uuid || assets[i].url.hlsAac) {
						uniqueAssetIdList.push(assets[i].sgtid);

						newAssetList.push({
							id: assets[i].sgtid,
							name: assets[i].name,
							url: assets[i].url.hlsAac,
						});
					}
				} catch(e) {}
			}

			$scope.assets = newAssetList;
			assetsReady = true;

			resolve();
		});
	}

	var reloadAfterDataChange = function() {
		assetsReady = false;
		lastEpgCurrentDownload = 0;

		$scope.currentChannelId = null;
		$scope.currentChannelName = '';
		$scope.currentProgramName = '';

		getAssets().then(updateEpg);

		$interval.cancel(epgUpdateInterval);
		epgUpdateInterval = $interval(updateEpg, 60*1000);
	}

	// -----------------------------------------------------

	$rootScope.$on('chromecastInfo', function() {
		setTimeout($rootScope.fixPlayerSize, 100);
	});

	// -----------------------------------------------------

	asset.addObserver('watchAssetObserver', function(type) {
		if($scope.user.isDemo()) return;

		switch(type) {
			case 'change':
				reloadAfterDataChange();
			break;
		}
	});

	device.addObserver('watchDeviceObserver', function(type, data) {
		if($scope.user.isDemo()) return;

		switch(type) {
			case 'selectSuccess':
				if (!$rootScope.access.watchLive) return $scope.location.path('/');
				reloadAfterDataChange();
			break;
			case 'jbxgoChange':
				$scope.hasGo = data;
			break;
		}
	});

	ott.addObserver('watchOttObserver', function(type) {
		switch(type) {
			case 'destroy':
				$rootScope.isPlaying = false;
				$scope.currentChannelId = null;
				$scope.currentChannelName = '';
				$scope.currentProgramName = '';
			break;
		}
	});

	state.addObserver('watchObserver', 'channelEpg', function() {
		var epgData = state.get('channelEpg');

		for(var i in epgData) {
			try {
				epgData[i].description = epgData[i].description.split('***')[0];
				var descriptionShort = epgData[i].description.substr(0, 150);

				if(descriptionShort.length === 150) {
					descriptionShort = descriptionShort.substr(0, descriptionShort.lastIndexOf(' ')) + '...';
				}

				epgData[i].descriptionShort = descriptionShort;
			} catch(e) {
				console.error(e);
			}
		}

		$scope.channelEpg = state.get('channelEpg');
	});

	$scope.$on('$destroy', function() {
		asset.removeObserver('watchAssetObserver');
		device.removeObserver('watchDeviceObserver');
		ott.removeObserver('watchOttObserver');

		state.removeObserver('watchObserver', 'channelEpg');

		document.body.classList.remove('watch-standalone');
		cleanPlayerStyles();

		$interval.cancel(epgUpdateInterval);
	});

	if($scope.user.isDemo()) {
		$scope.showLoginPrompt(true);
	} else {
		if(device.has() && device.getMode() === 'stb') {
			getAssets().then(updateEpg);

			$interval.cancel(epgUpdateInterval);
			epgUpdateInterval = $interval(updateEpg, 60*1000);
		}

		if(ott.initialized()) {
			$scope.currentChannelName = state.get('ottChannelName');
			$scope.currentProgramName = ' - '+state.get('ottProgramName');
			$scope.currentChannelId = state.get('ottChannelId');
			$scope.channelEpg = state.get('channelEpg');

			$rootScope.isPlaying = true;
		}

		$scope.hasGo = device.hasGo();
	}

	document.body.classList.add('watch-standalone');

}]);