angular.module('webpanel').directive('epgPadScroll', function($window) {
	return {
		scope: {
			scrollDirection: '@',
			scrollCallback: '=',
		},
		
		link: function(scope, element) {
			var onClick = function() {
				var container = document.getElementsByClassName('epg__grid')[0];
				
				switch(scope.scrollDirection) {
					case 'up': container.scrollTop -= (container.offsetHeight*.6); break;
					case 'down': container.scrollTop += (container.offsetHeight*.6); break;
					case 'left': container.scrollLeft -= (container.offsetWidth*.6); break;
					case 'right': container.scrollLeft += (container.offsetWidth*.6); break;
				}
				
				scope.scrollCallback(container.offsetLeft, container.offsetTop, container.offsetWidth, $window.innerWidth);
			}
		
			element.on('click', onClick);
			
			scope.$on('$destroy', function() {
				element.off('click', onClick);
			});
		}
	}
});