angular.module('webpanel').directive('runeAutofocus', function($timeout) {
	return {
		link: function(scope, element, attrs) {
			scope.$watch(attrs.runeAutofocus, function(value) {
				if(value === true) {
					$timeout(function() {
						element[0].focus();
					}, 50);
				}
			});
		}
	}
});