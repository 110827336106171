angular.module('webpanel').directive('scrollEnd', function($timeout, $window, $document) {
	return {
		scope: {
			scrollEnd: '=',
			scrollStart: '=',
		},

		link: function(scope, element) {
			if(typeof scope.scrollEnd !== 'function') scope.scrollEnd = function(){};
			if(typeof scope.scrollStart !== 'function') scope.scrollEnd = function(){};

			var scrollStoppedTimeout;

			var dragInProgress = false;
			var previousTouchX = 0;

			var clearSelection = function() {
				if($window.getSelection) {
					if($window.getSelection().empty) {
						$window.getSelection().empty();
					} else if($window.getSelection().removeAllRanges) {
						$window.getSelection().removeAllRanges();
					}
				} else if($document.selection) {
					$document.selection.empty();
				}
			}

			var onMouseUp = function(e) {
				// Firefox wywyła event mouseup jeśli zawartość DOM się zmieni, ponieważ bo tak?
				if(!e.pageX) return;
				dragInProgress = false;
				previousTouchX = 0;
			}

			var onResize = function() {
				scope.scrollEnd(element[0].scrollLeft, element[0].scrollTop, element[0].offsetWidth, $window.innerWidth, element[0]);
			}

			var onScroll = function() {
				scope.scrollStart();
				$timeout.cancel(scrollStoppedTimeout);

				scrollStoppedTimeout = $timeout(function() {
					scope.scrollEnd(element[0].scrollLeft, element[0].scrollTop, element[0].offsetWidth, $window.innerWidth, element[0]);
					// magia przeglądarek mobilnych: jeśli tego eventa nie wyślę, to po zakończeniu
					// płynnego przewijania ("fling scroll") nic się nie załaduje w epg...
					window.dispatchEvent(new Event('mouseup'));
				}, 100);
			}

			var onMouseDown = function(e) {
				dragInProgress = true;

				if(typeof TouchEvent !== 'undefined' && e instanceof TouchEvent) {
					previousTouchX = e.touches[0].clientX;
				}
			}

			var onMouseMove = function(e) {
				if(dragInProgress) {
					if(typeof TouchEvent !== 'undefined' && e instanceof TouchEvent) {
						var touchMovement = previousTouchX-e.touches[0].clientX;
						element[0].scrollLeft += touchMovement;
						previousTouchX = e.touches[0].clientX;
					} else {
						element[0].scrollLeft -= e.movementX;
					}

					clearSelection();
				}

				e.preventDefault();
			}

			element.on('scroll', onScroll);
			element.on('mousedown', onMouseDown);
			element.on('mouseup', onMouseUp);
			element.on('mousemove', onMouseMove);
			angular.element($window).on('mouseup', onMouseUp);
			angular.element($window).on('resize', onResize);

			scope.$on('$destroy', function() {
				element.off('scroll', onScroll);
				element.off('mousedown', onMouseDown);
				element.off('mouseup', onMouseUp);
				element.off('mousemove', onMouseMove);

				angular.element($window).off('mouseup', onMouseUp);
				angular.element($window).off('resize', onResize);
			});

			scope.scrollEnd(element[0].scrollLeft, element[0].scrollTop, element[0].offsetWidth, $window.innerWidth, element[0]);
		}
	}
});