angular.module('webpanel').controller('TvServiceController', ['$scope', 'asset', 'service', function($scope, asset, service) {
	$scope.assets = [];
	$scope.services = [];

	$scope.percentageComplete = 0;

	var assetsReady = false;

	var getServices = function() {
		$scope.services = service.get();
	}

	var getAssets = function() {
		$scope.assets = [];
		$scope.percentageComplete = 0;

		var assets = asset.get();
		var entitledCount = 0;

		if(assets.length) {
			for(var i=0; i<assets.length; i++) {
				if(assets[i].number < 101) continue;

				if(assets[i].entitled) entitledCount++;
				$scope.assets.push(assets[i]);
			}
		}

		if($scope.assets.length) {
			$scope.percentageComplete = Math.ceil(entitledCount/$scope.assets.length*100);
		}

		assetsReady = true;
	}

	$scope.orderMore = function() {
		$scope.location.path('/uslugi-tv/zamow-dodatki');
		$scope.analytics.trackEvent('order', 'more');
	}

	$scope.getRepeats = function(count) {
		var repeats = [];

		for(var i=0; i<count; i++) {
			repeats[i] = i;
		}

		return repeats;
	}

	asset.addObserver('tvServiceAssetObserver', function(type) {
		switch(type) {
			case 'change':
				assetsReady = false;
				getAssets();
			break;
		}
	});

	service.addObserver('tvServiceServiceObserver', function(type) {
		switch(type) {
			case 'change':
				getServices();
			break;
		}
	});


	$scope.$on('$destroy', function() {
		asset.removeObserver('tvServiceAssetObserver');
		service.removeObserver('tvServiceServiceObserver');
	});

	if($scope.user.isDemo()) {
		$scope.showLoginPrompt(true);
	} else {
		getAssets();
		getServices();
	}

}]);