angular.module('webpanel').controller('SimSummaryController', ['$scope', 'mvno', 'device', 'api', function($scope, mvno, device, api) {
	$scope.menuVisible = null;
	$scope.hasData = false;

	$scope.beginningYear = 2017;
	$scope.currentYear = 0;
	$scope.browseYear = 0;

	$scope.filterYear = 0;
	$scope.filterMonth = 0;

	$scope.usage = {}
	$scope.tariffName = '';

	$scope.months = {
		'1': 'Styczeń',
		'2': 'Luty',
		'3': 'Marzec',
		'4': 'Kwiecień',
		'5': 'Maj',
		'6': 'Czerwiec',
		'7': 'Lipiec',
		'8': 'Sierpień',
		'9': 'Wrzesień',
		'10': 'Październik',
		'11': 'Listopad',
		'12': 'Grudzień'
	};

	$scope.optionsLine = {
		plugins: [Chartist.plugins.tooltip()],
		axisY: {
			onlyInteger: true,
		},
		axisX: {
			labelInterpolationFnc: function(value, index) {
				return index % 7 === 0 ? value : null;
			}
		},
		chartPadding: {
			right: 10,
			left: 15,
		},
		showArea: true,
		onDraw: function(event) {
			if(event.type === 'point') {
				event.group.append(new Chartist.Svg('circle', {
					cx: event.x,
					cy: event.y,
					r: 3
				}, 'ct-slice-pie ct-secondary'), true);
			}
		}
	}

	$scope.optionsLineData = angular.copy($scope.optionsLine);
	$scope.optionsLineVoice = angular.copy($scope.optionsLine);

	$scope.optionsLineVoice.chartPadding.left = 20;
	$scope.optionsLineData.chartPadding.left = 22;

	$scope.optionsLineData.axisY.labelInterpolationFnc = function(value) {
		if(value > 1048576) return Math.ceil(value/104857.6)/10 + ' G';
		if(value > 1024) return Math.ceil(value/102.4)/10 + ' M';
		return value + ' k';
	}

	$scope.optionsLineVoice.axisY.labelInterpolationFnc = function(value) {
		if(value > 3600) return Math.ceil(value/3600) + ' h';
		if(value > 60) return Math.ceil(value/60) + ' m';
		return value + ' s';
	}

	var from, to;

	// ----------------------------------------------

	var reset = function() {
		$scope.usage = {
			data: {
				sum: 0,
				detail: {
					in: 0,
					out: 0,
					roamingIn: 0,
					roamingOut: 0,
				},
				chart: {
					labels: [],
					series: [[]],
				},
				euro: {
					exists: false,
					name: "",
					in: 0
				}
			},
			message: {
				sum: 0,
				detail: {
					normal: 0,
					roaming: 0,
					mms: 0,
					premium: 0,
				},
				chart: {
					labels: [],
					series: [[]],
				},
			},
			voice: {
				sum: 0,
				detail: {
					normal: 0,
					roaming: 0,
					premium: 0,
				},
				chart: {
					labels: [],
					series: [[]],
				},
			}
		}
	}

	var getTariff = function() {
		api.simTariffGet().then(function(result) {
			$scope.tariffName = result.data;
		}).catch(function() {
			$scope.tariffName = '(błąd podczas pobierania informacji o usłudze)';
		});
	}

	var setData = function(data, from, to) {
		var slice = data.history;
		var euro = data.eu_package_data;
		reset();
		populateChart();

		for(var i in slice) {
			var dateParts = slice[i].startDate.split(' ');

			slice[i].startDay = dateParts[0];
			slice[i].startHour = dateParts[1];
			slice[i].isRoaming = parseInt(slice[i].isRoaming);

			switch(slice[i].type) {
				case 'SMO': // sms
				case 'MMS':
					$scope.usage.message.sum++;
					if(slice[i].serviceName.match(/premium/i)) $scope.usage.message.detail.premium++;
					if(slice[i].type === 'MMS') $scope.usage.message.detail.mms += parseInt(slice[i].kbDownloaded) + parseInt(slice[i].kbUploaded);

					if(slice[i].isRoaming) {
						$scope.usage.message.detail.roaming++;
					} else {
						$scope.usage.message.detail.normal++;
					}

					addToChart(slice[i], 'message');
				break;
				case 'GPRS': // dane
					var totalUsage = parseInt(slice[i].kbDownloaded) + parseInt(slice[i].kbUploaded);
					$scope.usage.data.sum += totalUsage;

					// nie liczymy premium bo nie ma service "transmisja danych premium"

					if(slice[i].isRoaming) {
						$scope.usage.data.detail.roamingIn += parseInt(slice[i].kbDownloaded);
						$scope.usage.data.detail.roamingOut += parseInt(slice[i].kbUploaded);
					} else {
						$scope.usage.data.detail.in += parseInt(slice[i].kbDownloaded);
						$scope.usage.data.detail.out += parseInt(slice[i].kbUploaded);
					}

					addToChart(slice[i], 'data');
				break;
				case 'MOC':
				case 'MOCO': // jeśli jest lokalnie zakeszowane, to rozmowy są już podzielone na in/out
				case 'CF':
				case 'RCF':
				case 'POC': // voice
					var intUsage = parseInt(slice[i].durationSeconds);

					$scope.usage.voice.sum += intUsage;
					if(slice[i].serviceName.match(/premium/i)) $scope.usage.voice.detail.premium += intUsage;

					if(slice[i].isRoaming) {
						$scope.usage.voice.detail.roaming += intUsage;
					} else {
						$scope.usage.voice.detail.normal += intUsage;
					}

					addToChart(slice[i], 'voice');
				break;
			}
		}

		$scope.usage.data.sum = dataToHighUnits($scope.usage.data.sum).split(' '); // żeby kB/Mb były w osobnej linii w GUI
		$scope.usage.data.detail.roamingIn = dataToHighUnits($scope.usage.data.detail.roamingIn);
		$scope.usage.data.detail.roamingOut = dataToHighUnits($scope.usage.data.detail.roamingOut);
		$scope.usage.data.detail.in = dataToHighUnits($scope.usage.data.detail.in);
		$scope.usage.data.detail.out = dataToHighUnits($scope.usage.data.detail.out);

		$scope.usage.voice.sum = Math.ceil($scope.usage.voice.sum/60);
		$scope.usage.voice.detail.normal = voiceToHighUnits($scope.usage.voice.detail.normal);
		$scope.usage.voice.detail.roaming = voiceToHighUnits($scope.usage.voice.detail.roaming);
		$scope.usage.voice.detail.premium = voiceToHighUnits($scope.usage.voice.detail.premium);

		$scope.usage.message.detail.mms = dataToHighUnits($scope.usage.message.detail.mms);

		if (euro) {
			$scope.usage.data.euro.exists = true;
			$scope.usage.data.euro.name = euro.featureName;
			$scope.usage.data.euro.in = dataToHighUnits(euro.initialUnits - euro.remainingUnits);
		}

		from = from;
		to = to;

		sumChart();
		$scope.hasData = true;
	}

	var setFromToDate = function(year, month) {
		// ktoś w końcu powinien zrobić porządek z tym api
		var yearAndMonth = year+'-'+('0'+(month)).substr(-2,2)+'-';
		var lastDay = (new Date(year, month, 0)).getDate();

		from = yearAndMonth+'01';
		to = yearAndMonth+('0'+lastDay).substr(-2,2);
	}

	var populateChart = function() {
		var daysInMonth = new Date($scope.filterYear, $scope.filterMonth, 0).getDate();
		var stopOnCurrentDay = false;

		for(var i=0; i<daysInMonth; i++) {
			$scope.usage.data.chart.labels[i] = i+1;
			$scope.usage.message.chart.labels[i] = i+1;
			$scope.usage.voice.chart.labels[i] = i+1;

			$scope.usage.data.chart.series[0][i] = 0;
			$scope.usage.message.chart.series[0][i] = 0
			$scope.usage.voice.chart.series[0][i] = 0;
		}

		$scope.optionsLineData.high = 1024*1024;
		$scope.optionsLineVoice.high = 3600;
		$scope.optionsLine.high = 100;
	}

	var addToChart = function(event, type) {
		// phantomjs nie rozumie daty w formacie YYYY-MM-DD HH:ii:ss...
		if(navigator.userAgent.match(/phantomjs/i)) {
			event.startDate = (event.startDate.replace(' ', 'T'))+'.000Z';
		}

		var day = (new Date(event.startDate)).getDate();
		var pointer = $scope.usage[type].chart;

		// mamy już wypełnione przez populateSeries()
		var dayIndex = pointer.labels.indexOf(day);

		switch(type) {
			case 'data':
				pointer.series[0][dayIndex] += (parseInt(event.kbDownloaded) + parseInt(event.kbUploaded));
			break;
			case 'voice':
				pointer.series[0][dayIndex] += parseInt(event.durationSeconds);
			break;
			case 'message':
				pointer.series[0][dayIndex] += 1;
			break;
		}
	}

	var sumChart = function() {
		var daysInMonth = new Date($scope.filterYear, $scope.filterMonth, 0).getDate();
		var selectedMonthBeginningDate = new Date($scope.filterYear, $scope.filterMonth-1, 1);

		var currentDate = new Date();
		var currentDay = currentDate.getDate();
		var stopOnCurrentDay = false;
		var isFutureMonth = false;

		if($scope.filterYear == currentDate.getFullYear() && $scope.filterMonth == currentDate.getMonth()+1) {
			stopOnCurrentDay = true;
		}

		if(currentDate.getTime() < selectedMonthBeginningDate.getTime()) {
			isFutureMonth = true;
		}

		var dataSum = 0;
		var voiceSum = 0;
		var messageSum = 0;

		for(var i=0; i<daysInMonth; i++) {
			if((stopOnCurrentDay && i > currentDay-1) || isFutureMonth) {
				$scope.usage.data.chart.series[0][i] = null;
				$scope.usage.message.chart.series[0][i] = null;
				$scope.usage.voice.chart.series[0][i] = null;
			} else {
				dataSum += $scope.usage.data.chart.series[0][i];
				messageSum += $scope.usage.message.chart.series[0][i];
				voiceSum += $scope.usage.voice.chart.series[0][i];

				$scope.usage.data.chart.series[0][i] = {
					value: dataSum,
					meta: dataToHighUnits(dataSum),
				};

				$scope.usage.message.chart.series[0][i] = {
					value: messageSum,
					meta: messageSum+' szt.',
				};

				$scope.usage.voice.chart.series[0][i] = {
					value: voiceSum,
					meta: voiceToHighUnits(voiceSum),
				};
			}
		}

		if(voiceSum) delete $scope.optionsLineVoice.high;
		if(dataSum) delete $scope.optionsLineData.high;
		if(messageSum) delete $scope.optionsLine.high;

	}

	var dataToHighUnits = function(kilobytes) {
		if(kilobytes >= 1048576) {
			return (Math.round(kilobytes/10485.76)/100).toString().replace('.', ',') + ' GB';
		} else if(kilobytes >= 1024) {
			return (Math.round(kilobytes/10.24)/100).toString().replace('.', ',') + ' MB';
		} else {
			return kilobytes + ' kB';
		}
	}

	var voiceToHighUnits = function(seconds) {
		var returnStr = '';

		var hours = Math.floor(seconds/3600);
		seconds -= hours*3600;
		var minutes = Math.floor(seconds/60);
		seconds -= minutes*60;

		if(hours) returnStr += hours+'h ';
		if(minutes) returnStr += minutes+'m ';
		if(seconds) returnStr += seconds+'s';

		if(returnStr) return returnStr;
		return '0s';
	}

	var updateHistory = function() {
		$scope.hasData = false;
		return mvno.updateHistory(from, to);
	}

	// ----------------------------------------------

	$scope.closeMenuDate = function() {
		// zamknięcie bez wyboru
		if($scope.menuVisible === 'date') {
			$scope.menuVisible = null;
			$scope.analytics.trackEvent('sim_summary', 'menu_close', 'date');
		}

		$scope.browseYear = $scope.filterYear;
	}

	$scope.closeMenuType = function() {
		if($scope.menuVisible === 'type') {
			$scope.menuVisible = null;
			$scope.analytics.trackEvent('sim_summary', 'menu_close', 'type');
		}
	}

	$scope.toggleMenu = function(which) {
		if(!$scope.hasData) return;

		if($scope.menuVisible === which) {
			$scope.menuVisible = null;
		} else {
			$scope.menuVisible = which
		}

		if(which === 'date') {
			$scope.browseYear = $scope.filterYear;
		}

		$scope.analytics.trackEvent('sim_summary', 'menu_toggle', which);
	}

	$scope.setFilterYear = function(year) {
		if(!$scope.hasData) return;

		year = parseInt(year);
		if(year > $scope.currentYear || year < $scope.beginningYear) return;
		$scope.browseYear = year;

		$scope.analytics.trackEvent('sim_summary', 'year_set', year);
	}

	$scope.setFilterMonth = function(month) {
		if(!$scope.hasData) return;

		if($scope.browseYear !== $scope.filterYear) {
			$scope.filterYear = $scope.browseYear;
		}

		$scope.filterMonth = month;
		$scope.menuVisible = null;

		setFromToDate($scope.filterYear, $scope.filterMonth);
		updateHistory();

		$scope.analytics.trackEvent('sim_summary', 'month_set', month);
	}

	$scope.$on('$destroy', function() {
		mvno.removeObserver('simSummaryMvnoObserver');
		device.removeObserver('simSummaryDeviceObserver');
	});

	// ----------------------------------------------

	mvno.addObserver('simSummaryMvnoObserver', function(type, data) {
		switch(type) {
			case 'historyChange':
				setData(mvno.getHistory(data.from, data.to), data.from, data.to);
			break;
			case 'mvnoChange':
				// lokalna historia została wyczyszczona asynchronicznie
				updateHistory();
			break;
		}
	});

	device.addObserver('simSummaryDeviceObserver', function(type) {
		if(device.getMode() != 'simcard') return;

		switch(type) {
			case 'selectSuccess':
				// jeśli nie ma, to api zwraca 404 bo nie ma wybranego numeru
				if(device.has() && device.getMode() === 'simcard') getTariff();
			break;
		}
	});

	// ----------------------------------------------

	var date = new Date();

	$scope.currentYear = (new Date()).getFullYear();
	$scope.filterYear = date.getFullYear();
	$scope.browseYear = $scope.filterYear;
	$scope.filterMonth = date.getMonth()+1;

	setFromToDate($scope.filterYear, $scope.filterMonth);

	if($scope.user.isDemo()) {
		$scope.showLoginPrompt(true);
	} else {
		if(device.has() && device.getMode() === 'simcard') {
			updateHistory();
			getTariff();
		}
	}

}]);