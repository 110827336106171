angular.module('webpanel').directive('touchEvents', function() {
	return {
		scope: {
			touchEventsStart: '=',
			touchEventsEnd: '=',
			touchEventsMove: '=',
		},

		link: function(scope, element) {
			var onTouchStart = function(event) {
				if(typeof scope.touchEventsStart === 'function') scope.touchEventsStart(event);
			}

			var onTouchEnd = function(event) {
				if(typeof scope.touchEventsEnd === 'function') scope.touchEventsEnd(event);
			}

			var onTouchMove = function(event) {
				if(typeof scope.touchEventsMove === 'function') scope.touchEventsMove(event);
			}

			element.on('touchend', onTouchEnd);
			element.on('touchstart', onTouchStart);
			element.on('touchmove', onTouchMove);

			scope.$on('$destroy', function() {
				element.off('touchend', onTouchEnd);
				element.off('touchstart', onTouchStart);
				element.off('touchmove', onTouchMove);
			});
		}
	}
});