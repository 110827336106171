angular.module('webpanel').directive('touchEnd', function() {
	return {
		scope: {
			touchEnd: '=',
			touchParam: '=',
		},
		
		link: function(scope, element) {
			var moved = false;
			
			var onTouchStart = function() {
				moved = false;
			}
			
			var onTouchEnd = function() {
				if(!moved) scope.touchEnd(scope.touchParam);
			}
			
			var onTouchMove = function() {
				moved = true;
			}
			
			element.on('touchend', onTouchEnd);
			element.on('touchstart', onTouchStart);
			element.on('touchmove', onTouchMove);

			scope.$on('$destroy', function() {
				element.off('touchend', onTouchEnd);
				element.off('touchstart', onTouchStart);
				element.off('touchmove', onTouchMove);
			});
		}
	}
});