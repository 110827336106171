angular.module('webpanel').directive('chartist', function() {

	function link(scope, element, attrs) {
		scope.$watch('chartData', function(newValue, oldValue) {
			if(newValue == oldValue) return;
			chart.update(scope.chartData, scope.chartOptions);
		}, true);
		
		scope.$watch('chartOptions', function(newValue, oldValue) {
			if(JSON.stringify(newValue) == JSON.stringify(oldValue)) return;
			chart.update(scope.chartData, scope.chartOptions);
		}, true);
		
		if(typeof(scope.highlight) !== 'undefined') {
			scope.$watch('highlightSelector', function(newValue, oldValue) {
				if(newValue == oldValue) return;
				
				if(newValue == '') {
					for(var i=0; i<chart.svg._node.childNodes.length; i++) {
						var element = chart.svg._node.childNodes[i];
						var ngElement = angular.element(element);
						
						if(!ngElement.hasClass('ct-series')) continue;
						
						ngElement.css('opacity', '1');
						element.parentElement.appendChild(element);
					}
				} else {
					for(var i=0; i<chart.svg._node.childNodes.length; i++) {
						var element = chart.svg._node.childNodes[i];
						var ngElement = angular.element(element);
						
						if(!ngElement.hasClass('ct-series')) continue;
						var index = ngElement.children().attr('data-index');
						
						if(scope.chartData.labels[index] == newValue && ngElement.hasClass('ct-series')) {
							ngElement.css('opacity', '0.7');
							element.parentElement.appendChild(element);
							break;
						}
					}
				}
			});
		}
		
		var chart = Chartist[scope.chartType]('#' + scope.id, scope.chartData, scope.chartOptions);
		
		chart.on('draw', function(data) {
			switch(data.type) {
				case 'label':
					data.element._node.firstChild.title = data.text;
				break;
				case 'bar':
				case 'slice':
				case 'point':
					data.element.attr({
						'data-index': data.index,
					});
				break;
			}

			if(typeof(scope.chartOptions.onDraw) === 'function') scope.chartOptions.onDraw(data);
		});
	}

	return {
		link: link,
		scope: {
			chartData: '=',
			chartType: '@',
			chartOptions: '=',
			highlight: '@',
			highlightSelector: '=',
			id: '@',
		},
		restrict: 'EA',
	};

});