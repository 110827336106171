angular.module('webpanel').service('loadingInterceptor', ['$q', function($q) {
	var observers = {};
	var loadingCount = 0;

	var addObserver = function(name, observer) {
		if(typeof observer !== 'function') return;
		if(typeof observers[name] !== 'undefined') return;
		observers[name] = observer;
	}

	var removeObserver = function(name) {
		delete observers[name];
	}

	var notifyObservers = function(eventType, eventData) {
		for(var i in observers) observers[i](eventType, eventData);
	}

	return {
		request: function(config) {
			if(loadingCount < 1) loadingCount = 0;
			loadingCount++;

			notifyObservers('request', loadingCount);
			return config;
		},

		requestError: function(rejection) {
			loadingCount--;
			notifyObservers('requestError', loadingCount);
			return $q.reject(rejection);
		},

		response: function(response) {
			loadingCount--;
			notifyObservers('response', loadingCount);
			return response;
		},

		responseError: function(rejection) {
			loadingCount--;
			notifyObservers('responseError', loadingCount);
			return $q.reject(rejection);
		},

		addObserver: addObserver,
		removeObserver: removeObserver,
	};
}]);