angular.module('webpanel').service('service', ['api', 'device', '$rootScope', 'socket', 'popup', 'auth', function(api, device, $rootScope, socket, popup, auth) {
	var services = {};
	var observers = {};

	var get = function() {
		return services;
	}

	var update = function() {
		if(device.has()) {
			var currentDevice = device.getSelected();
			api.servicesGet().then(function(response) {
				services = response.data;
				notifyObservers('change', currentDevice);
			}).catch(function() {
				if(auth.isDemo()) return;

				var pop = popup.create();
				pop.setText('Wystąpił błąd podczas pobierania danych. Spróbuj ponownie. '+
					'Jeśli problem będzie się powtarzał, skontaktuj się ze swoim Operatorem.');
				pop.addButton('OK', angular.noop, 'yes', true);
				popup.push(pop);
			});
		}
	}

	var addObserver = function(name, observer) {
		if(typeof observer !== 'function') return;
		if(typeof observers[name] !== 'undefined') return;
		observers[name] = observer;
	}

	var removeObserver = function(name) {
		delete observers[name];
	}

	var notifyObservers = function(eventType, eventData) {
		for(var i in observers) observers[i](eventType, eventData);
	}

	$rootScope.$on('deviceAcquired', update);

	$rootScope.$on('deviceLost', function() {
		services = {};
		notifyObservers('change');
	});

	socket.addObserver('serviceSocketObserver', 'service', update);
	update();

	return {
		get: get,
		update: update,
		addObserver: addObserver,
		removeObserver: removeObserver,
	}

}]);