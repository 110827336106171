angular.module('webpanel').controller('SimHistoryController', ['$scope', 'mvno', 'device', function($scope, mvno, device) {
	$scope.slice = [];
	$scope.selected = null;
	$scope.count = 0;

	$scope.menuVisible = null;
	$scope.hasData = false;

	$scope.filterType = { serviceId: '' };
	$scope.filterTypeList = {};
	$scope.filterTypeLength = 0;
	$scope.filterTypeName = '';

	$scope.beginningYear = 2017;
	$scope.currentYear = 0;
	$scope.browseYear = 0;

	$scope.filterYear = 0;
	$scope.filterMonth = 0;

	$scope.months = {
		'1': 'Styczeń',
		'2': 'Luty',
		'3': 'Marzec',
		'4': 'Kwiecień',
		'5': 'Maj',
		'6': 'Czerwiec',
		'7': 'Lipiec',
		'8': 'Sierpień',
		'9': 'Wrzesień',
		'10': 'Październik',
		'11': 'Listopad',
		'12': 'Grudzień'
	};

	$scope.typeDictionary = {
		'MOCO': {'long': 'Rozmowa wychodząca', 'short': 'Rozmowa wych.' },
		'MOCI': {'long': 'Rozmowa przychodząca', 'short': 'Rozmowa przych.' },
		'RCF': { 'long': 'Roaming' },
		'CF': { 'long': 'Przekierowanie rozmowy', 'short': 'Przekierowanie' },
		'SMO': { 'long': 'SMS wychodzący', 'short': 'SMS wych.' },
		'SMT': { 'long': 'SMS przychodzący', 'short': 'SMS przych.' },
		'GPRS': { 'long': 'Transmisja danych', 'short': 'Dane' },
		'MMS': { 'long': 'MMS' },
		'WAP': { 'long': 'WAP Premium' },
		'POC': { 'long': 'Poczta głosowa' },
	}

	var from, to;

	// ----------------------------------------------

	var setFromToDate = function(year, month) {
		// ktoś w końcu powinien zrobić porządek z tym api
		var yearAndMonth = year+'-'+('0'+(month)).substr(-2,2)+'-';
		var lastDay = (new Date(year, month, 0)).getDate();

		from = yearAndMonth+'01';
		to = yearAndMonth+('0'+lastDay).substr(-2,2);
	}

	var clearFilter = function() {
		$scope.filterTypeList = {
			'': { id: '', label: 'Wszystkie usługi' } // to jest całkowicie poprawne. Własność obiektu może być pustym stringiem.
		};
		$scope.filterType = { serviceId: '' };
		$scope.filterTypeLength = Object.keys($scope.filterTypeList).length;
		$scope.filterTypeName = $scope.filterTypeList[$scope.filterType.serviceId].label;
	}

	var setData = function(data, from, to) {
		clearFilter();
		var slice = data.history;

		for(var i in slice) {
			if(typeof($scope.filterTypeList[slice[i].serviceId]) === 'undefined') {
				$scope.filterTypeList[slice[i].serviceId] = {
					'id': slice[i].serviceId,
					'label': slice[i].serviceName,
				}
			}

			var dateParts = slice[i].startDate.split(' ');

			slice[i].startDay = dateParts[0];
			slice[i].startHour = dateParts[1];
			slice[i].isRoaming = parseInt(slice[i].isRoaming);
			// tymczasowy fix na problemy z billingiem - unitPrice is null
			if(slice[i].unitPrice) slice[i].unitPrice = slice[i].unitPrice.replace('.', ',');
			slice[i].totalPrice = slice[i].totalPrice.replace('.', ',');

			if(slice[i].type == 'MOC') {
				if(slice[i].direction === 'out') {
					slice[i].type = 'MOCO';
				} else {
					slice[i].type = 'MOCI';
				}
			}

			switch(slice[i].type) {
				case 'GPRS':
				case 'MMS':
					slice[i].usage = parseInt(slice[i].kbDownloaded) + parseInt(slice[i].kbUploaded);
					if(slice[i].usage > 1024) {
						slice[i].usage = (Math.round(slice[i].usage/10.24)/100).toString().replace('.', ',') + ' MB';
					} else {
						slice[i].usage += ' kB';
					}

					slice[i].isDiscrete = true;
				break;
				case 'SMT':
				case 'SMO':
					slice[i].usage = '1 szt.';
					slice[i].isDiscrete = true;
				break;
				case 'MOCO':
				case 'MOCI':
				case 'CF':
				case 'RCF':
				case 'POC':
					slice[i].usage = '';
					slice[i].isDiscrete = false;

					var durationSeconds = slice[i].durationSeconds;
					var durationHour = Math.floor(durationSeconds/3600);
					durationSeconds -= durationHour*3600;
					var durationMinute = Math.floor(durationSeconds/60);
					durationSeconds -= durationMinute*60;

					if(durationHour) slice[i].usage += durationHour+'h ';
					if(durationMinute) slice[i].usage += durationMinute+'m ';
					if(durationSeconds) slice[i].usage += durationSeconds+'s ';
				break;
			}
		}

		$scope.filterTypeLength = Object.keys($scope.filterTypeList).length;
		// dodaj .5 żeby user widział że da się scrollować
		if($scope.filterTypeLength > 6) $scope.filterTypeLength = 6.5;

		$scope.slice = slice;
		$scope.count = Object.keys(slice).length;

		from = from;
		to = to;

		$scope.hasData = true;
	}

	var updateHistory = function() {
		$scope.hasData = false;
		return mvno.updateHistory(from, to);
	}

	// ----------------------------------------------

	$scope.getName = function(type) {
		if(typeof $scope.typeDictionary[type] !== 'undefined') return $scope.typeDictionary[type].long;
		return '(nieznana usługa)';
	}

	$scope.getNameShort = function(type) {
		if(typeof $scope.typeDictionary[type] !== 'undefined') {
			if(typeof $scope.typeDictionary[type].short !== 'undefined') {
				return $scope.typeDictionary[type].short;
			} else {
				return $scope.typeDictionary[type].long;
			}
		}

		return '(niezn. usługa)';
	}

	$scope.close = function() {
		$scope.selected = null;

		$scope.analytics.trackEvent('sim_history', 'entry_close');
	}

	$scope.select = function(entry) {
		$scope.selected = entry;

		$scope.analytics.trackEvent('sim_history', 'entry_select');
	}

	$scope.closeMenuDate = function() {
		// zamknięcie bez wyboru
		if($scope.menuVisible === 'date') {
			$scope.menuVisible = null;
			$scope.analytics.trackEvent('sim_history', 'menu_close', 'date');
		}

		$scope.browseYear = $scope.filterYear;
	}

	$scope.closeMenuType = function() {
		if($scope.menuVisible === 'type') {
			$scope.menuVisible = null;
			$scope.analytics.trackEvent('sim_history', 'menu_close', 'type');
		}
	}

	$scope.toggleMenu = function(which) {
		if(!$scope.hasData) return;

		if($scope.menuVisible === which) {
			$scope.menuVisible = null;
		} else {
			$scope.menuVisible = which
		}

		if(which === 'date') {
			$scope.browseYear = $scope.filterYear;
		}

		$scope.analytics.trackEvent('sim_history', 'menu_toggle', which);
	}

	$scope.setFilterType = function(id) {
		if(!$scope.hasData) return;

		$scope.filterType = { serviceId: id };
		$scope.menuVisible = null;
		$scope.filterTypeName = $scope.filterTypeList[$scope.filterType.serviceId].label;

		$scope.analytics.trackEvent('sim_history', 'filter_set', $scope.filterTypeName);
	}

	$scope.setFilterYear = function(year) {
		if(!$scope.hasData) return;

		year = parseInt(year);
		if(year > $scope.currentYear || year < $scope.beginningYear) return;
		$scope.browseYear = year;

		$scope.analytics.trackEvent('sim_history', 'year_set', year);
	}

	$scope.setFilterMonth = function(month) {
		if(!$scope.hasData) return;

		if($scope.browseYear !== $scope.filterYear) {
			$scope.filterYear = $scope.browseYear;
		}

		$scope.filterMonth = month;
		$scope.menuVisible = null;
		$scope.selected = null;

		setFromToDate($scope.filterYear, $scope.filterMonth);
		updateHistory();

		$scope.analytics.trackEvent('sim_history', 'month_set', month);
	}

	$scope.$on('$destroy', function() {
		mvno.removeObserver('simHistoryMvnoObserver');
	});

	// ----------------------------------------------

	mvno.addObserver('simHistoryMvnoObserver', function(type, data) {
		if(device.getMode() != 'simcard') return;

		switch(type) {
			case 'historyChange':
				setData(mvno.getHistory(data.from, data.to), data.from, data.to);
			break;
			case 'mvnoChange':
				// lokalna historia została wyczyszczona asynchronicznie
				updateHistory();
			break;
		}
	});

	// ----------------------------------------------

	var date = new Date();

	$scope.currentYear = (new Date()).getFullYear();
	$scope.filterYear = date.getFullYear();
	$scope.browseYear = $scope.filterYear;
	$scope.filterMonth = date.getMonth()+1;

	setFromToDate($scope.filterYear, $scope.filterMonth);

	if($scope.user.isDemo()) {
		$scope.showLoginPrompt(true);
	} else {
		clearFilter();
		if(device.has() && device.getMode() === 'simcard') {
			updateHistory();
		}
	}

}]);