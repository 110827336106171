angular.module('webpanel').service('auth', ['localStorageService', function(storage) {
	var observers = {};
	var oolIsDemo;

	var demoAccount = {
		id: 'user-DEMODEMODEMODEMODEMODEMODEMODEMODEMODEMO',
		seed: 'KONTODEMOKONTODEMOKONTODEMOKONTODEMOKONTODEMOKONTODEMOK',
		device: '00:23:F8:2E:14:86',
	}

	var initDemo = function() {
		storage.set('DeviceId', demoAccount.id);
		storage.set('HmacSeed', demoAccount.seed);
		storage.set('Impersonating', demoAccount.device);
	}

	var checkStoredData = function() {
		if(!getId() || !hasSeed()) {
			// user nie ma nic w storage, może świeży user albo wyczyścił dane w przegladarce
			initDemo();
		}
	}

	var isDemo = function() {
		checkStoredData();

		var storedDeviceId = getId();
		var isDemo = (storedDeviceId === demoAccount.id);

		checkCredentialsChangedOOL(isDemo);
		return isDemo;
	}

	// ool = out of lifecycle
	var checkCredentialsChangedOOL = function(currentIsDemo) {
		if(typeof oolIsDemo === 'undefined') {
			oolIsDemo = currentIsDemo;
			return;
		}

		if(currentIsDemo !== oolIsDemo) {
			notifyObservers('credChangeOol', currentIsDemo);
			oolIsDemo = currentIsDemo;
		}
	}

	var setId = function(id) {
		storage.set('DeviceId', id);
		notifyObservers('idChange');
	}

	var getId = function() {
		return storage.get('DeviceId');
	}

	var setSeed = function(seed) {
		storage.set('HmacSeed', seed);
		notifyObservers('seedChange');
	}

	var hasSeed = function() {
		return (storage.get('HmacSeed') && storage.get('HmacSeed').length > 0);
	}

	var setImpersonating = function(id) {
		storage.set('Impersonating', id);
		notifyObservers('deviceChange');
	}

	var getImpersonating = function() {
		var device = storage.get('Impersonating');

		if(device) return device;
		return '';
	}

	var destroy = function(ignoreNotify) {
		storage.remove('DeviceId');
		storage.remove('HmacSeed');
		storage.remove('Impersonating');

		if(ignoreNotify) return;
		notifyObservers('destroy');
	}

	var addObserver = function(name, observer) {
		if(typeof observer !== 'function') return;
		if(typeof observers[name] !== 'undefined') return;
		observers[name] = observer;
	}

	var removeObserver = function(name) {
		delete observers[name];
	}

	var notifyObservers = function(eventType, eventData) {
		for(var i in observers) observers[i](eventType, eventData);
	}

	var isAppleDevice = function(){
		
		var appleDevices = [
			'iPad Simulator',
			'iPhone Simulator',
			'iPod Simulator',
			'iPad',
			'iPadMini',
			'iPadMini2',
			'iPhone',
			'iPod'
		];
		return appleDevices.includes(navigator.platform) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
	}

	var getHeaders = function(endpoint) {
		var nonce = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
		var date = Math.floor(Date.now()/30000)*30;
		var client = isAppleDevice() ? 'apple' : 'other';
		
		return {
			headers: {
				'X-Device-Id': getId(),
				'X-Nonce': nonce,
				'X-Auth': btoa(CryptoJS.HmacSHA256(nonce+endpoint+date, storage.get('HmacSeed'))),
				'X-Impersonate': getImpersonating(),
				'X-Device-Type': client,
			}
		}
	}

	return {
		getHeaders: getHeaders,
		setId: setId,
		getId: getId,
		setSeed: setSeed,
		hasSeed: hasSeed,
		setImpersonating: setImpersonating,
		getImpersonating: getImpersonating,
		destroy: destroy,
		addObserver: addObserver,
		removeObserver: removeObserver,
		initDemo: initDemo,
		isDemo: isDemo,
		isAppleDevice: isAppleDevice,
	}

}]);