angular.module('webpanel').controller('CastController', ['$scope', '$rootScope', '$timeout', '$interval', 'cast', function($scope, $rootScope, $timeout, $interval, $cast) {
	$scope.castInfo = {};
	$scope.tokenConfirmed = false;

	$scope.paused = false;
	$scope.muted = false;
	$scope.connected = false;

	$scope.canPlayPause = true;

	$scope.volume = 0;
	$scope.changingVolume = false;

	$scope.controlsAvailable = true;
	$scope.controlsAvailableTimeout = null;

	$scope.volumeControlsVisible = false;

	$scope.updatedTitle = null;
	$scope.updatedEnds = null;

	var volumeListenerSuspendTimeout;
	var suspendVolumeListener;

	var setVolumeDebounce;
	var volumeChangeStartTarget;

	var onConnectMediaUpdateTimeout;

	var volumeListener;
	var muteListener;

	// ----------------------------------------------------------------

	$scope.stop = function() {
		try {
			// na mobilnym nie zatrzymuje odtwarzania
			cast.framework.CastContext.getInstance().endCurrentSession(true);
			reset();
		} catch(e) {
			$cast.getAdapter().disconnect();
		}
	}

	$scope.pause = function() {
		controlsTimeout();
		$cast.getAdapter().playOrPause();
	}

	$scope.toggleVolumeControls = function() {
		$scope.volumeControlsVisible = !$scope.volumeControlsVisible;
	}

	$scope.hideVolumeControls = function() {
		$scope.volumeControlsVisible = false;
	}

	$scope.volumeChangeStart = function(event) {
		volumeChangeStartTarget = event.target;
		$scope.changingVolume = true;
		volumeChange(event);
	}

	$scope.volumeChangeEnd = function() {
		$scope.changingVolume = false;
	}

	$scope.volumeChangeMove = function(event) {
		if(!$scope.changingVolume) return;
		volumeChange(event);
	}

	// ----------------------------------------------------------------

	var volumeChange = function(event) {
		if(!event) return;

		var clientRect = volumeChangeStartTarget.getBoundingClientRect();
		var offsetY;

		if(event instanceof TouchEvent) {
			offsetY = clientRect.bottom - event.touches[0].clientY;
		} else {
			offsetY = clientRect.bottom - event.clientY;
		}

		var newVolume = offsetY/volumeChangeStartTarget.offsetHeight;

		if(newVolume < 0) newVolume = 0;
		if(newVolume > 1) newVolume = 1;

		setupVolume(newVolume, true);

		// żeby nie było karuzeli eventów
		suspendVolumeListener = true;

		$timeout.cancel(volumeListenerSuspendTimeout);
		volumeListenerSuspendTimeout = $timeout(function() {
			suspendVolumeListener = false;
		}, 2000);
	}

	var setupVolume = function(volume, shouldSet) {
		if(typeof shouldSet === 'undefined') shouldSet = false;

		$scope.volume = Math.round(Math.ceil(volume/.05)*5); // v/0.05*0.05*100;
		if($scope.volume > 100) $scope.volume = 100;

		$scope.muted = $scope.volume < 5;
		if($scope.muted) $scope.volume = 0;

		if(shouldSet) {
			$timeout.cancel(setVolumeDebounce);

			setVolumeDebounce = $timeout(function() {
				$cast.setVolume($scope.volume);
			}, 100);
		}
	}

	var controlsTimeout = function() {
		$scope.controlsAvailable = false;

		$scope.controlsAvailableTimeout = $timeout(function() {
			$scope.controlsAvailable = true;
		}, 1000);
	}

	var attachVolumeListeners = function() {
		detachVolumeListeners();

		volumeListener = $cast.getAdapter().Controller.addEventListener('volumeLevelChanged', function() {
			if(suspendVolumeListener) return;
			volumeChangeCallback();
		});

		muteListener = $cast.getAdapter().Controller.addEventListener('isMutedChanged', function() {
			if(suspendVolumeListener) return;
			volumeChangeCallback();
		});
	}

	var volumeChangeCallback = function() {
		setupVolume($cast.getAdapter().Player.isMuted ? 0 : $cast.getAdapter().Player.volumeLevel);
	}

	var detachVolumeListeners = function() {
		$cast.getAdapter().Controller.removeEventListener(volumeListener);
		$cast.getAdapter().Controller.removeEventListener(muteListener);
	}

	var updateMedia = function(data) {
		attachVolumeListeners();
		volumeChangeCallback();

		$scope.connected = true;

		try {
			$scope.castInfo = data;

			if($scope.castInfo.casting) {
				document.body.classList.add('has-active-chromecast');
			} else {
				document.body.classList.remove('has-active-chromecast');
			}
		} catch(e) {
			document.body.classList.remove('has-active-chromecast');
		}

		try {
			$scope.paused = data.media.paused;
		} catch(e) {
			$scope.paused = false;
		}

		try {
			$scope.castInfo.deviceName = $cast.getAdapter().Session.getCastDevice().friendlyName;
		} catch(e) {
			$scope.castInfo.deviceName = '';
		}
	}

	var reset = function() {
		$scope.hideVolumeControls();
		$scope.tokenConfirmed = false;
		$scope.updatedTitle = null;
		$scope.updatedEnds = null;
		$scope.connected = false;
		document.body.classList.remove('has-active-chromecast');
	}

	// ----------------------------------------------------------------

	$rootScope.$on('chromecastInfo', function(_, data) {
		$scope.canPlayPause = true;
		updateMedia(data);
	});

	// ----------------------------------------------------------------

	$cast.addObserver('castCastObserver', function(type, data) {
		switch(type) {
			case 'tokenConfirm':
				$scope.tokenConfirmed = true;
			break;

			case 'epgChange':
				$scope.updatedTitle = data.title;
				$scope.updatedEnds = data.ends;
			break;

			case 'zap':
				$scope.connected = true;
				$scope.updatedTitle = data.title;
			break;

			case 'sessionResume':
				// te eventy przychodzą też po normalnym starcie z ikonki "przesyłaj" z jakiegoś
				// powodu i nie da się inaczej rozróżnić niż po statusie...
				if(data.title === 'JAMBOX Online') return;

				$scope.connected = true;
				$scope.updatedTitle = data.title.replace(/^.*?:\s/, '');
				$scope.canPlayPause = false;
			break;

			case 'connect':
				$scope.connected = true;

				$timeout.cancel(onConnectMediaUpdateTimeout);
				onConnectMediaUpdateTimeout = $timeout(function() {
					var media = $cast.getMedia();
					updateMedia({
						casting: (media.state !== 'IDLE'),
						media: media,
					});
				}, 1000);
			break;

			case 'disconnect':
				reset();
			break;

			case 'state':
				switch(data) {
					case 'IDLE':
						reset();
					break;
				}
			break;
		}
	});

	$scope.$on('$destroy', function() {
		$scope.connected = false;
		$cast.removeObserver('castCastObserver');
		detachVolumeListeners();
	});
}]);