angular.module('webpanel').controller('DeviceController', ['$scope', 'device', 'mode', 'api', function($scope, device, mode, api) {
	$scope.devices = [];
	var currentDevice = null;

	device.addObserver('deviceDeviceObserver', function(type) {
		switch(type) {
			case 'change':
			case 'selectSuccess':
				fetch();
				currentDevice = device.getSelected();
			break;
		}
	});

	$scope.select = function(deviceObj) {
		$scope.$emit('deviceSelect', deviceObj);
	}

	$scope.resetKey = function(deviceObj) {
		var pop = $scope.popup.create();
		pop.setText('Reset uprawnień spowoduje ponowne uruchomienie i aktywację urządzenia. Kontynuować?');

		pop.addButton('Resetuj uprawnienia', function() {
			api.deviceResetKey(deviceObj.id).then(function() {
				var pop = $scope.popup.create();
				pop.setText('Zresetowano pomyślnie.');
				pop.addButton('OK', angular.noop, 'yes', true);
				$scope.popup.push(pop);
			}).catch(function() {
				var pop = $scope.popup.create();
				pop.setText('Operacja nie powiodła się. Spróbuj ponownie.');
				pop.addButton('OK', angular.noop, 'yes', true);
				$scope.popup.push(pop);
			});
		}, 'yes');
		pop.addButton('Anuluj', angular.noop, 'no', true);
		$scope.popup.push(pop);
	}

	$scope.isSelected = function(deviceObj) {
		return deviceObj.id === currentDevice.id;
	}

	$scope.$on('$destroy', function() {
		device.removeObserver('deviceDeviceObserver');
	});

	var fetch = function() {
		var devices = device.get();
		for(var i in devices) {
			devices[i].isLajt = !!devices[i].model.match(/^sdmc/i);
		}

		switch(device.getMode()) {
			case 'stb': $scope.mode = 'default'; break;
			case 'simcard': $scope.mode = 'mvno'; break;
		}

		$scope.devices = devices;
	}

	if($scope.user.isDemo()) {
		$scope.showLoginPrompt(true);
	} else {
		fetch();

		$scope.mode = mode;
		currentDevice = device.getSelected();
	}

}]);