angular.module('webpanel').service('mvno', ['$rootScope', 'device', 'api', 'popup', 'auth', function($rootScope, device, api, popup, auth) {
	var callHistory = {};
	var simServices = {};

	var observers = {};

	var updateHistory = function(from, to) {
		if(!device.has()) return;
		if(auth.isDemo()) return;

		var localCacheKey = from+'_'+to;

		if(typeof callHistory[localCacheKey] !== 'undefined') {
			notifyObservers('historyChange', { from: from, to: to });
			return;
		}

		api.simHistoryGet(from, to).then(function(response) {
			callHistory[localCacheKey] = response.data;
			notifyObservers('historyChange', { from: from, to: to });
		}).catch(function() {
			var pop = popup.create();
			pop.setText('Nie udało się załadować wykazu połączeń. Spróbuj ponownie.');
			pop.addButton('OK', angular.noop, 'yes', true);
			popup.push(pop);

			notifyObservers('historyGetFail');
		});
	}

	var updateServices = function() {
		if(!device.has()) return;
		if(auth.isDemo()) return;

		api.simStatusGet().then(function(response) {
			simServices = response.data;
			notifyObservers('statusChange');
		}).catch(function() {
			var pop = popup.create();
			pop.setText('Nie udało się załadować stanu blokad. Spróbuj ponownie.');
			pop.addButton('OK', angular.noop, 'yes', true);
			popup.push(pop);

			notifyObservers('statusGetFail');
		});
 	}

	var saveServices = function(newServiceStatus) {
		if(!device.has()) return;
		if(auth.isDemo()) return;

		api.simStatusSave(newServiceStatus).then(updateServices).catch(function() {
			var pop = popup.create();
			pop.setText('Nie udało się zapisać stanu blokad. Spróbuj ponownie.');
			pop.addButton('OK', angular.noop, 'yes', true);
			popup.push(pop);

			notifyObservers('statusSaveFail');
		});
	}

	var getServices = function() {
		return angular.copy(simServices);
	}

	var getHistory = function(from, to) {
		var localCacheKey = from+'_'+to;
		return callHistory[localCacheKey];
	}

	var addObserver = function(name, observer) {
		if(typeof observer !== 'function') return;
		if(typeof observers[name] !== 'undefined') return;
		observers[name] = observer;
	}

	var removeObserver = function(name) {
		delete observers[name];
	}

	var notifyObservers = function(eventType, eventData) {
		for(var i in observers) {
			observers[i](eventType, eventData);
		}
	}

	$rootScope.$on('deviceAcquiredSim', function() {
		callHistory = {};

		// historia jest pobierana na żądanie, więc trzeba powiadomić kontroler
		// o wyczyszczeniu, żeby sobie pobrał dla nowego urządzenia
		notifyObservers('mvnoChange');
	});

	$rootScope.$on('deviceLost', function() {
		callHistory = {};
		simServices = {};

		notifyObservers('historyChange');
		notifyObservers('statusChange');
	});

	return {
		addObserver: addObserver,
		removeObserver: removeObserver,
		getServices: getServices,
		getHistory: getHistory,
		updateHistory: updateHistory,
		updateServices: updateServices,
		saveServices: saveServices,
	}

}]);