angular.module('webpanel').controller('SearchController', ['$scope', '$rootScope', '$routeParams', 'api', 'setting', 'recording', 'asset', 'ott', 'device', '$timeout', '$interval',
	function($scope, $rootScope, $routeParams, api, setting, recording, asset, ott, device, $timeout, $interval) {

	$scope.mode = 'text';

	$scope.suggestions = [];
	$scope.results = [];
	$scope.categories = [];

	$scope.example = '';
	$scope.chosenBrick = null;
	$scope.now = 0;

	$scope.noResult = true;
	$scope.firstSearchMade = false;
	$scope.catSelectorMobileVisible = false;

	$scope.vodKiosks = {};
	$scope.searchFlavor = 'epg';
	$scope.isSearchFieldFocused = false;

	$scope.selectedCategory = {
		short: null,
		full: null,
	};

	$scope.searchContents = {
		bsReference: '',
	};

	var selectedLeaseAsset = null;

	var recordings = {};
	var schedules = {};
	var assetList = {};
	var playQueue = [];

	var page = 0;
	var nowMiliseconds, endOfToday, endOfTomorrow;

	var typingStopTimeout;
	var currentTimestampUpdateInterval;
	var isInfiniteLoading = false;
	var resultsPerPage = 10;

	var stopInfiniteScroll = false;

	var examples = [
		'M jak miłość',
		'Fakty',
		'piłka nożna',
		'hokej',
		'Trudne sprawy',
		'Drogówka',
		'Doktor Who',
		'kolarstwo'
	];

	var shortDateConfig = {
		hour: '2-digit',
		minute: '2-digit',
	}

	var fullDateConfig = {
		weekday: 'long',
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
	}

	// -------------------------------------------

	var setSuggestions = function(suggestions) {
		var parsedSuggestions = [];

		for(var i=0; i<10; i++) {
			if($rootScope.access.epg && suggestions.epg[i]) parsedSuggestions.push({ text: suggestions.epg[i], flavor: 'epg' });
			if($rootScope.access.vod && suggestions.vod[i]) parsedSuggestions.push({ text: suggestions.vod[i], flavor: 'vod' });
		}

		$scope.suggestions = parsedSuggestions;
	}

	var clearSuggestions = function() {
		$scope.suggestions = [];
	}

	var setResults = function(results, fromInfiniteScroll) {
		if(typeof fromInfiniteScroll === 'undefined') fromInfiniteScroll = false;

		if(fromInfiniteScroll) {
			$scope.results = $scope.results.concat(results);
		} else {
			$scope.results = results;
		}

		recountResults();
	}

	var recountResults = function() {
		var resultsVod = 0;
		var resultsEpg = 0;

		for(var i in $scope.results) {
			if($scope.results[i].flavor === 'epg') resultsEpg++;
			if($scope.results[i].flavor === 'vod' || $scope.results[i].flavor === 'vodPromo') resultsVod++;
		}

		if(($scope.searchFlavor === 'epg' && !resultsEpg && !resultsVod) || ($scope.searchFlavor === 'vod' && !resultsVod)) {
			$scope.closePane();
			showPlaceholder();
		} else {
			hidePlaceholder();
		}
	}

	var clearResults = function(alsoResetPage) {
		if(typeof alsoResetPage === 'undefined') alsoResetPage = false;

		$scope.results = [];
		if(alsoResetPage) page = 0;
	}

	var setCategories = function(categories) {
		var newCategories = [];

		for(var i in categories) {
			newCategories.push(categories[i].replace('magazyn ', 'mag. ').replace('program ', 'pr. '));
		}

		$scope.categories = newCategories;
	}

	var clearCategories = function() {
		$scope.categories = [];
		$scope.selectedCategory.short = null;
		$scope.selectedCategory.full = null;
	}

	var showPlaceholder = function() {
		$scope.noResult = true;
	}

	var hidePlaceholder = function() {
		$scope.noResult = false;
	}

	var decorateResult = function(entry) {
		var decoratedResult = {};

		var startDate = new Date(entry.startTime).getTime();
		var endDate = new Date(entry.endTime).getTime();

		decoratedResult.name = entry.programName + ', ' + assetList[entry.mappingId].name;
		decoratedResult.duration = Math.round((new Date(entry.endTime).getTime() - new Date(entry.startTime).getTime()) / (1000*60));
		decoratedResult.isPast = endDate < nowMiliseconds;

		decoratedResult.start = Math.floor(startDate/1000);
		decoratedResult.end = Math.floor(endDate/1000);
		decoratedResult.epgId = entry.epgId;
		decoratedResult.assetId = assetList[entry.mappingId].id;
		decoratedResult.assetMappingId = entry.mappingId;

		decoratedResult.ageRating = 0;
		if(entry.ageRating > 0) {
			decoratedResult.ageRating = entry.ageRating;
		}

		// age rating 3 oznacza "od 18 lat", dlaczego? Bo tak...
		if(decoratedResult.ageRating == 3) decoratedResult.ageRating = 18;

		decoratedResult.entitled = assetList[entry.mappingId].entitled;
		decoratedResult.uid = assetList[entry.mappingId].id+'/'+entry.epgId;
		decoratedResult.recordingAvailable = recording.isAvailable(assetList[entry.mappingId].id);

		try {
			decoratedResult.canPlay = (assetList[entry.mappingId].url.hlsAac.length > 10) 
				|| ($scope.hasMediaSource && assetList[entry.mappingId].alternate_id.vectra_uuid);
		} catch(e) {
			decoratedResult.canPlay = false;
		}

		var scheduleUid = assetList[entry.mappingId].id+'/'+entry.epgId;

		if(typeof schedules[scheduleUid] !== 'undefined') {
			decoratedResult.schedule = schedules[scheduleUid].split(',')[3];
		}

		// tutaj ten sam format uid co w schedule :)
		if(typeof recordings[scheduleUid] !== 'undefined') {
			decoratedResult.recording = recordings[scheduleUid];
		}

		if(entry.description) {
			var htmlDescription = entry.description.split('***');
			var shortDescription;

			if(entry.subentries && entry.subentries.description) {
				shortDescription = (entry.subentries.description+' ⁂ '+entry.description).replace('***', '⁂').substr(0, 350);
			} else {
				shortDescription = entry.description.replace('***', '⁂').substr(0, 350);
			}

			decoratedResult.description = {
				short: shortDescription,
				htmlOne: htmlDescription[1],
				htmlTwo: htmlDescription[0],
			}

			if(decoratedResult.description.short.length === 350) { // jeśli coś udało się przyciąć
				decoratedResult.description.short = decoratedResult.description.short.substr(0, decoratedResult.description.short.lastIndexOf(' ')) + '...';
			}
		} else {
			decoratedResult.description = null;
		}

		if(startDate < endOfToday && endDate >= nowMiliseconds) {
			decoratedResult.dateLine = 'dzisiaj, '+ new Date(entry.startTime).toLocaleString('pl-PL', shortDateConfig);
			decoratedResult.relativeStart = 'today';
		} else if(startDate < endOfTomorrow && endDate >= nowMiliseconds) {
			decoratedResult.dateLine = 'jutro, '+ new Date(entry.startTime).toLocaleString('pl-PL', shortDateConfig);
			decoratedResult.relativeStart = 'tomorrow';
		} else {
			decoratedResult.dateLine = new Date(entry.startTime).toLocaleString('pl-PL', fullDateConfig);
			decoratedResult.relativeStart = null;
		}

		if(entry.productionCountries) {
			entry.productionCountries.unshift(entry.genre || '');
			decoratedResult.productionLine = entry.productionCountries.join(', ').replace(/,[ ,]*/g, ', ').replace(/(, $)|(^, )/g, '');
		} else decoratedResult.productionLine = '';

		if(entry.productionYear) {
			decoratedResult.productionLine += ' - '+entry.productionYear;
		}

		decoratedResult.awards = entry.awards ? entry.awards : [];
		decoratedResult.flavor = entry.flavor;

		return decoratedResult;
	}

	var decorateResultVod = function(entry) {
		var decoratedResult = angular.copy(entry);

		decoratedResult.uid = 'vod-'+entry.id;

		var shortDescription = entry.description.substr(0, 350);
		if(shortDescription.length === 350) { // jeśli coś udało się przyciąć
			decoratedResult.fullDescription = angular.copy(entry.description);
			decoratedResult.description = shortDescription.substr(0, shortDescription.lastIndexOf(' ')) + '...';
		}

		if(entry.type === 'serial') {
			var seriesSeason = entry.series.match(/\d+$/);
			var seriesName = seriesSeason ? (entry.series.replace(/\d+$/, '').trim() + ', s. ' + seriesSeason) : entry.series;

			decoratedResult.displayName = seriesName + ', odc. ' + entry.episode;
		} else {
			decoratedResult.displayName = entry.title;
		}

		return decoratedResult;
	}

	var refreshDates = function() {
		var currentDate = new Date();

		nowMiliseconds = Date.now();
		endOfToday = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), 23, 59, 59).getTime();

		endOfTomorrow = new Date(endOfToday);
		endOfTomorrow.setDate(endOfTomorrow.getDate()+1);
		endOfTomorrow = endOfTomorrow.getTime();
	}

	var stopSuggestionLoading = function() {
		clearSuggestions();
		$timeout.cancel(typingStopTimeout);
		isInfiniteLoading = false;
	}

	// dodaje w tablicy obiektów pole o name=value w każdym obiekcie z tablicy
	var deepAddField = function(array, name, value) {
		if(!array) return;

		return array.map(function(o) {
			o[name] = value;
			return o;
		});
	}

	var searchResponseCallbackUnified = function(responseData, fromInfiniteScroll) {
		if(!$rootScope.access.epg || !responseData.epg) responseData.epg = [];
		if(!$rootScope.access.vod || !responseData.vod) responseData.vod = [];

		var responseDataWithFlavor = [].concat(
			deepAddField(responseData.epg, 'flavor', 'epg'),
			deepAddField(responseData.vod, 'flavor', 'vod')
		);

		var promotionalVodContent = [];

		for(var i=0; i<3; i++) {
			if(typeof responseData.vod[i] === 'undefined') continue;
			promotionalVodContent.push(angular.copy(responseData.vod[i]));
		}

		if(promotionalVodContent.length) {
			deepAddField(promotionalVodContent, 'flavor', 'vodPromo');
		}

		searchResponseCallback(responseDataWithFlavor, fromInfiniteScroll, true, promotionalVodContent.reverse());
	}

	var searchResponseCallback = function(responseData, fromInfiniteScroll, hasAddedFlavor, promotionalVodContent) {
		if(!hasAddedFlavor) {
			// połaczone wyniki epg+vod obsługuje searchResponseCallbackUnified więc do tego ifa trafiamy
			// tylko z metod, które nie zwracają połączonych wyników. Więc wszystkie wyniki to epg.
			responseData = [].concat(deepAddField(responseData, 'flavor', 'epg'));
		}

		refreshDates();

		var responseHasCurrentFlavor = false;
		for(var i in responseData) {
			if(responseData[i].flavor === $scope.searchFlavor) {
				responseHasCurrentFlavor = true;
				break;
			}
		}

		if(!fromInfiniteScroll) {
			clearResults(false);
			try {
				document.querySelector('.search__text__inner .ss-content').scrollTop = 0;
			} catch(e) {}
		}

		stopSuggestionLoading();

		if(responseData.length) {
			var processedResults = [];

			for(var i in responseData) {
				if(responseData[i].flavor === 'epg' && !assetList[responseData[i].mappingId]) continue;

				switch(responseData[i].flavor) {
					case 'epg':
						processedResults.push(decorateResult(responseData[i]));
					break;
					case 'vod':
						processedResults.push(decorateResultVod(responseData[i]));
					break;
				}
			}

			// dodajemy ze trzy wyniki z VOD do epg żeby promowac szukanie w VOD.
			// nie da się tego pomieszać żeby było wszystko na jednej stronie i jednocześnie
			// żeby kolejność miała sens.

			if(page === 0 && typeof promotionalVodContent !== 'undefined' && promotionalVodContent.length) {
				for(var i in promotionalVodContent) {
					processedResults.splice(2, 0, decorateResultVod(promotionalVodContent[i]));
				}

				// i jeszcze belka "więcej VOD"
				processedResults.splice(2+promotionalVodContent.length, 0, { flavor: 'fakeMoreVod' });
			}

			if (!processedResults.length) {
				page++;
				getAwarded();
			}

			stopSuggestionLoading();
			setResults(processedResults, fromInfiniteScroll);
		}


		switch($scope.searchFlavor) {
			case 'epg':
				if(!responseHasCurrentFlavor) {
					stopInfiniteScroll = true;
				}

				// bo chcemy wyświetlić 3 wyniki VOD
				if(!responseData.length && page === 0) {
					clearResults();
					showPlaceholder();
				}
			break;
			case 'vod':
				if(!responseHasCurrentFlavor) {
					if(page === 0) {
						if(!responseData.length) clearResults();
						showPlaceholder();
					} else {
						// nie ma więcej wyników więc nie chcemy kolejnych requestów
						stopInfiniteScroll = true;
					}
				}
			break;
		}
	}

	var searchErrorCallback = function() {
		clearResults();
		stopSuggestionLoading();
		showPlaceholder();
	}

	var populateAssets = function() {
		var assets = asset.get();
		var newAssetList = [];

		for(var i in assets) {
			newAssetList[assets[i].epg_mapping_id] = {
				name: assets[i].name,
				entitled: assets[i].entitled,
				id: assets[i].sgtid,
				url: assets[i].url,
			}
		}

		assetList = newAssetList;
	}

	var updateRecordings = function() {
		var recordingList = recording.get();
		recordings = {};

		// no i teraz nie mamy id nagrań w klocku, więc trzeba je zaindeksować inaczej niż
		// w epg - np. tworząc sobie jakieś unikalne identyfikatory. Mamy farta bo w nagraniu
		// `channel_id` to channelSgtId a `rec_contentid` to brickEpgId :) więc używamy tego
		// formatu klucza co wszędzie indziej w szukajce, czyli "assetId/epgId"

		for(var i in recordingList) {
			recordings[recordingList[i].channel_id+'/'+recordingList[i].rec_contentid] = recordingList[i];
		}
	}

	var updateSchedules = function() {
		schedules = {};
		var schedulesNew = setting.getAutozap();
		//[start,end,channelSgtId,scheduleType,brickEpgId]

		for(var i in schedulesNew) {
			var autozapData = schedulesNew[i].split(',');
			schedules[autozapData[2]+'/'+autozapData[4]] = schedulesNew[i];
		}
	}

	var setNow = function() {
		return new Promise(function(resolve) {
			$scope.now = Math.floor(Date.now()/1000);
			resolve();
		});
	}

	var setupvodKiosks = function() {
		return new Promise(function(resolve, reject) {
			$scope.vodKiosks = {};

			var promiseList = [];
			if($scope.user.isDemo()) {
				promiseList = [api.vodGet(), Promise.resolve({ 
					data: { kiosk: [] }
				})];
			} else {
				promiseList = [api.vodGet(), api.tVodGet()];
			}

			Promise.all(promiseList).then(function(response) {
				for(var i in response[0].data.kiosk) {
					$scope.vodKiosks[i] = {
						tvod: false,
						image: response[0].data.kiosk[i].image.replace('/vod_logos/', ''),
					}
				}

				for(var i in response[1].data.kiosk) {
					$scope.vodKiosks[i] = {
						tvod: true,
						image: response[1].data.kiosk[i].image.replace('/vod_logos/', ''),
					}
				}

				resolve();
			}).catch(function() {
				handleError('Nie udało się załadować informacji o kioskach VOD.');
				reject();
			});
		});
	}

	var getVodQueue = function() {
		return new Promise(function(resolve, reject) {
			api.vodCustomQueueList().then(function(response) {
				playQueue = response.data;
				resolve();
			}).catch(function() {
				if($scope.user.isDemo()) {
					resolve();
					return;
				}

				handleError('Nie udało się pobrać kolejki materiałów VOD.');
				reject();
			})
		});
	}

	var init = function() {
		if(asset.has()) {
			populateAssets();
			updateSchedules();
			updateRecordings();
		} else {
			asset.update();
		}

		Promise.all([setNow(), setupvodKiosks(), getVodQueue()]).then(function() {
			if($routeParams.mode && ['vod', 'epg'].indexOf($routeParams.mode) > -1 && $routeParams.phrase) {
				$scope.changeFlavor($routeParams.mode);
				$scope.searchContents.bsReference = decodeURIComponent($routeParams.phrase).replace(/\+/g, ' ');
				$scope.search();
			}
		}).catch(function() {
			handleError('Zapytanie nie powiodło się.');
		});

		$interval.cancel(currentTimestampUpdateInterval);
		currentTimestampUpdateInterval = $interval(setNow, 30*1000);
	}

	// -------------------------------------------
	// tu są funkcje od faktycznej szukajki

	var getSuggestions = function(phrase) {
		$scope.analytics.trackEvent('search', 'typing_stop', phrase);

		api.searchSuggestions(phrase).then(function(response) {
			setSuggestions(response.data);
		}).catch(function(e) {
			// nic nie robimy? bo w sumie po co. Ewentualnie po kilku próbach
			// wyświetlamy toast że sugestie są niedostepne? TODO przemyśleć
		});
	}

	var searchTtile = function(phrase, fromInfiniteScroll) {
		if(typeof phrase === 'undefined' || phrase.length < 3) return;
		if(typeof fromInfiniteScroll === 'undefined') fromInfiniteScroll = false;

		stopSuggestionLoading();

		api.searchTitle(phrase, page*resultsPerPage).then(function(response) {
			searchResponseCallbackUnified(response.data, fromInfiniteScroll);
		}, searchErrorCallback);
	}

	var getCategories = function() {
		api.searchCategoriesGet().then(function(response) {
			setCategories(response.data);
		}).catch(function() {
			handleError('Nie udało się pobrać listy kategorii VOD.');
		});
	}

	var searchCategory = function(category, fromInfiniteScroll) {
		if(typeof category === 'undefined' || category === null) return;
		if(typeof fromInfiniteScroll === 'undefined') fromInfiniteScroll = false;

		api.searchCategory(category, page*resultsPerPage).then(function(response) {
			searchResponseCallback(response.data, fromInfiniteScroll);
		}).catch(searchErrorCallback);
	}

	var getAwarded = function(fromInfiniteScroll) {
		if(typeof fromInfiniteScroll === 'undefined') fromInfiniteScroll = false;

		api.searchAwarded(page*resultsPerPage).then(function(response) {
			searchResponseCallback(response.data, fromInfiniteScroll);
		}).catch(searchErrorCallback);
	}

	var handleError = function(message) {
		var pop = $scope.popup.create();
		pop.setText(message+ ' Spróbuj ponownie.');
		pop.addButton('OK', angular.noop, 'yes', true);
		$scope.popup.push(pop);
	}

	// -------------------------------------------

	$scope.onType = function() {
		$timeout.cancel(typingStopTimeout);

		if(typeof $scope.searchContents.bsReference === 'undefined' || $scope.searchContents.bsReference.length < 3) {
			clearSuggestions();
			return;
		}

		typingStopTimeout = $timeout(function() {
			getSuggestions($scope.searchContents.bsReference);
		}, 300);
	}

	$scope.onFocus = function() {
		$scope.isSearchFieldFocused = true;
	}

	$scope.onBlur = function() {
		$scope.isSearchFieldFocused = false;
	}

	$scope.clear = clearSuggestions;

	$scope.search = function() {
		if($scope.searchContents.bsReference.length < 3) return;

		stopInfiniteScroll = false;
		$scope.firstSearchMade = true;
		page = 0;

		hidePlaceholder();
		clearSuggestions();

		searchTtile($scope.searchContents.bsReference);
		$scope.analytics.trackEvent('search', 'phrase', $scope.searchContents.bsReference);
	}

	$scope.choose = function(phrase) {
		$scope.firstSearchMade = true;
		stopInfiniteScroll = false;

		$scope.searchContents.bsReference = phrase;
		page = 0;

		hidePlaceholder();
		clearSuggestions();

		searchTtile($scope.searchContents.bsReference);
		$scope.analytics.trackEvent('search', 'suggestion', $scope.searchContents.bsReference);
	}

	$scope.scrollEnd = function(_, scrollTop, __, ___, element) {
		if(isInfiniteLoading || stopInfiniteScroll) return;

		var scrollThreshold = element.scrollHeight - element.clientHeight;

		if(scrollTop + 400 > scrollThreshold) {
			isInfiniteLoading = true;
			page += 1;

			switch($scope.mode) {
				case 'text': searchTtile($scope.searchContents.bsReference, true); break;
				case 'category': searchCategory($scope.selectedCategory.full, true); break;
				case 'award': getAwarded(true); break;
			}
		}
	}

	$scope.contentChanged = function(element, _, parent) {
		// jeśli wysokość wyników jest mniejsza niż wysokość kontenera,
		// dociągaj kolejne dopóki kontener nie zostanie wypełniony

		if(element.scrollHeight < parent.scrollHeight) {
			$scope.scrollEnd(null, 0, null, null, element);
		}
	}

	$scope.selectBrick = function(program) {
		if($scope.chosenBrick && $scope.chosenBrick.uid === program.uid) {
			// jesli kliknęliśmy to samo, zamknij opis
			$scope.closePane();
		} else {
			if(program.flavor !== 'epg' && $scope.vodKiosks[program.providerId].tvod) {
				api.tVodGetTicketPackage(program.id).then(function(response) {
					program.tVodTicketPackage = response.data;
					$scope.chosenBrick = program;
				});
			} else $scope.chosenBrick = program;
		}

		$scope.analytics.trackEvent('search', 'select', program.name);
	}

	$scope.closePane = function() {
		$scope.chosenBrick = null;
	}

	$scope.schedule = function(brick, type) {
		if(!$scope.checkHasEmail()) return;

		var assetId = brick.assetId;

		var program = [];
		var programPosition = 0;

		for(var i in $scope.results) {
			if($scope.results[i].uid === brick.uid) {
				programPosition = i;
				program = $scope.results[i];
				break;
			}
		}

		switch(type) {
			case 'autozap':
				if(program.schedule == 2) {
					program.schedule = 0;
					$scope.analytics.trackEvent('autozap', 'remove', brick.name);
				} else {
					program.schedule = 2;
					$scope.analytics.trackEvent('autozap', 'add', brick.name);
				}
			break;
			case 'remind':
				if(program.schedule == 3) {
					program.schedule = 0;
					$scope.analytics.trackEvent('remind', 'remove', brick.name);
				} else {
					program.schedule = 3;
					$scope.analytics.trackEvent('remind', 'add', brick.name);
				}
			break;
		}

		$scope.results[programPosition] = program;
		var scheduleExists = false;

		for(var entry in schedules) {
			var autozapData = schedules[entry].split(',');

			if(parseInt(autozapData[0]) < $scope.now) {
				delete schedules[entry];
				continue;
			}

			if(autozapData[2] == assetId && autozapData[4] == program.epgId) {
				scheduleExists = true;

				if(program.schedule === 0) {
					delete schedules[entry];
				} else {
					// zmień typ schedula i zapisz w obiekcie
					autozapData[3] = program.schedule;
					schedules[entry] = autozapData.join(',');
				}
				break;
			}
		}

		if(!scheduleExists) {
			var newAutozapData = [program.start, program.end, assetId, program.schedule, program.epgId];
			schedules[assetId+'/'+program.epgId] = newAutozapData.join(',');
		}

		setting.setAutozap(schedules);
	}

	$scope.record = function(brick) {
		if(!$scope.checkHasEmail()) return;

		recording.add(brick.assetId, brick.start, brick.end, brick.epgId, brick.name);
		$scope.analytics.trackEvent('recording', 'add', brick.name);
	}

	$scope.recordDelete = function(brick) {
		if(!$scope.checkHasEmail()) return;

		if(typeof brick.recording === 'undefined' || typeof brick.recording.id === 'undefined') return;
		recording.remove(brick.recording.id);

		$scope.analytics.trackEvent('recording', 'delete', brick.name);
	}

	$scope.recordStop = function(brick) {
		if(!$scope.checkHasEmail()) return;

		if(typeof brick.recording === 'undefined' || typeof brick.recording.id === 'undefined') return;
		recording.change(brick.recording.id, $scope.now);

		$scope.analytics.trackEvent('recording', 'stop', brick.name);
	}

	$scope.recordResume = function(brick) {
		if(!$scope.checkHasEmail()) return;

		if(typeof brick.recording === 'undefined' || typeof brick.recording.id === 'undefined') return;
		recording.change(brick.recording.id, 0);

		$scope.analytics.trackEvent('recording', 'resume', brick.name);
	}

	$scope.watchLive = function() {
		if($scope.user.isDemo()) {
			$scope.analytics.trackEvent('search', 'watch_unauthorized', $scope.chosenBrick.name);
			$scope.showLoginPrompt();
			return;
		}

		$scope.analytics.trackEvent('search', 'watch', $scope.chosenBrick.name);
		if(!$scope.checkHasEmail()) return;

		var asset = assetList[$scope.chosenBrick.assetMappingId];
		var source = asset.url.hlsAac;

		var play = function() {
			if(typeof source !== 'undefined' && source.length) {
				ott.play(asset.id, asset.name, source);
				ott.setLabel(asset.name);
				ott.setChannelId(asset.id);
			}
		}

		if(!ott.initialized()) {
			ott.init().then(play); // TODO .catch()
		} else {
			play();
		}
	}

	$scope.changeMode = function(newMode) {
		$scope.mode = newMode;

		$scope.firstSearchMade = false;
		$scope.catSelectorMobileVisible = false;
		if ($rootScope.access.epg) $scope.searchFlavor = 'epg';

		clearSuggestions();
		clearResults();
		clearCategories();
		showPlaceholder();

		page = 0;
		$scope.analytics.trackEvent('search', 'mode_change', newMode);

		switch(newMode) {
			case 'text':
			break;
			case 'category':
				getCategories();

				if(window.innerWidth <= 991) {
					// brzydki hack ale co tam
					$scope.catSelectorMobileVisible = true;
				}
			break;
			case 'award':
				stopInfiniteScroll = false;
				getAwarded();
			break;
		}

		$scope.closePane();
		$scope.searchContents.bsReference = '';
	}

	$scope.selectCategory = function(category) {
		if(category === $scope.selectedCategory.short) {
			$scope.catSelectorMobileVisible = false;
			return;
		}

		hidePlaceholder();
		$scope.firstSearchMade = true;
		$scope.closePane();
		page = 0;

		$scope.analytics.trackEvent('search', 'category_select', category);

		// trzeba odkręcić skróty, które potworzyliśmy po stronie klienta
		$scope.selectedCategory.full = category.replace('mag. ', 'magazyn ').replace('pr. ', 'program ');
		$scope.selectedCategory.short = category;

		searchCategory($scope.selectedCategory.full);
		stopInfiniteScroll = false;

		if($scope.catSelectorMobileVisible) {
			document.querySelector('.search__category__categories ul').scrollTop = 0;
		}

		$scope.catSelectorMobileVisible = false;
	}

	$scope.toggleCatSelectorMobile = function() {
		$scope.catSelectorMobileVisible = !$scope.catSelectorMobileVisible;
	}

	$scope.changeFlavor = function(newFlavorOverride) {
		if($scope.mode !== 'text') return;

		// jeśli zmieniamy z flavor:epg i były tylko wyniki VOD, to ta flaga będzie true
		// i żadne dodatkowe wyniki się nie dociągną. Więc czyścimy.
		stopInfiniteScroll = false;

		if(typeof newFlavorOverride !== 'undefined') {
			$scope.searchFlavor = newFlavorOverride;
		} else {
			if($scope.searchFlavor === 'epg') {
				$scope.searchFlavor = 'vod';
			} else {
				$scope.searchFlavor = 'epg';
			}
		}

		try {
			document.querySelector('.search__text__inner .ss-content').scrollTop = 0;
		} catch(e) {}

		$scope.analytics.trackEvent('search', 'flavor_change', $scope.searchFlavor);
		recountResults();
	}

	$scope.orderCheck = function(assetId, priceGroup) {
		$scope.closePane();
		selectedLeaseAsset = assetId;

		api.tVodGetLeases().then(function(response) {
			if(typeof response.data.leases === 'undefined') throw new Error('bad response');

			if(typeof response.data.leases[priceGroup] !== 'undefined' && response.data.leases[priceGroup].count > 0) {
				var pop = $scope.popup.create();
				pop.setText('Wypożyczenie filmu zostało już rozliczone. Czy chcesz wypożyczyć ten film?');

				pop.addButton('Wypożycz', function() {
					lease(assetId).then(function() {
						toast.push('Film został dodany do kolejki "Do obejrzenia".');
						$scope.analytics.trackEvent('order', 'lease_success', assetId);
					});
					$scope.analytics.trackEvent('tvod', 'order_free_complete', assetId);
				}, 'yes');

				pop.addButton('Anuluj', function() {
					$scope.analytics.trackEvent('tvod', 'order_free_reject', assetId);
					angular.noop();
				}, 'no', true);

				$scope.popup.push(pop);
				$scope.analytics.trackEvent('tvod', 'order', assetId);
			} else {
				$scope.order(assetId);
			}
		}).catch(function() {
			var pop = $scope.popup.create();
			pop.setText(errorReasons.genericTvod);
			pop.addButton('OK', angular.noop, 'yes');
			$scope.popup.push(pop);
		});
	}

	var lease = function(assetId) {
		selectedLeaseAsset = assetId;

		return api.tVodLease(selectedLeaseAsset).then(function() {
			return api.vodCustomQueuePush(selectedLeaseAsset);
		}).then(function() {
			for(var i in $scope.content) {
				if($scope.content[i].id === selectedLeaseAsset) {
					$scope.content[i].leased = true;
					break;
				}
			}

			playQueue.push(selectedLeaseAsset);
		});
	}

	$scope.order = function(assetId) {
		if(!$scope.checkHasEmail()) return;

		selectedLeaseAsset = assetId;

		api.tVodGetTicketPackage(assetId).then(function(response) {
			$scope.selectedPackage = response.data;
			$scope.analytics.trackEvent('order', 'select', $scope.selectedPackage.name);
		});
	}

	$scope.orderOnCancel = function() {
		$scope.selectedPackage = null;
		selectedLeaseAsset = null;
	}

	$scope.orderOnSuccess = function() {
		lease(selectedLeaseAsset).then(function() {
			selectedLeaseAsset = null;
			$scope.selectedPackage = null;
		}).catch(function() {
			var pop = $scope.popup.create();
			pop.setText('Nie udało się wypożyczyć materiału. Spróbuj ponownie.');
			pop.addButton('OK', angular.noop, 'yes');
			$scope.popup.push(pop);

			$scope.analytics.trackEvent('order', 'lease_fail', assetId);
		})
	}

	$scope.noop = angular.noop;

	// -------------------------------------------

	$scope.enqueue = function(assetId) {
		if(!$scope.checkHasEmail()) return;

		assetId = parseInt(assetId);

		api.vodCustomQueuePush(assetId).then(function() {
			playQueue.push(assetId);
			$scope.analytics.trackEvent('search', 'customlist_add', assetId);
		}).catch(function(response) {
			switch(response.status) {
				case 402:
				case '402':
					var pop = $scope.popup.create();

					if(response.data.package) {
						pop.setText('Aby uzyskać dostęp do wybranego materiału, zamów pakiet z usługą '+response.data.package+'.');
						pop.addButton('Sprawdź ofertę', function() {
							$scope.location.path('/uslugi-tv/zamow-dodatki');
							$scope.analytics.trackEvent('order', 'from_vodkiosk');
						}, 'yes');
					} else {
						pop.setText('Ten materiał nie jest dostępny w twoim abonamencie. '+
						'Skontaktuj się ze swoim Operatorem i zapytaj o dostępność wybranego materiału.');
						pop.addButton('Przejdź do danych Operatora', function() {
							$scope.location.path('/twoje-konto/twoje-dane');
						}, 'yes', true);
					}

					pop.addButton('Nie teraz', angular.noop, 'no', true);
					$scope.popup.push(pop);

					$scope.analytics.trackEvent('search', 'customlist_not_entitled', assetId);
				break;
				default:
					handleError('Nie udało się dodać materiału do kolejki.');
					$scope.analytics.trackEvent('search', 'customlist_add_error', assetId);
			}
		});
	}

	$scope.dequeue = function(assetId) {
		if(!$scope.checkHasEmail()) return;

		assetId = parseInt(assetId);

		// żeby nie było jakichś dzikich race conditions
		var playQueueCopy = angular.copy(playQueue);

		for(var i in playQueueCopy) {
			if(playQueueCopy[i] === assetId) {
				playQueueCopy.splice(i, 1);
				break;
			}
		}

		api.vodCustomQueueDelete(assetId).then(function() {
			playQueue = playQueueCopy;
			$scope.analytics.trackEvent('vod', 'customlist_remove_kiosk', assetId);
		}).catch(function() {
			handleError('Nie udało się usunąć materiału z kolejki.');
			$scope.analytics.trackEvent('vod', 'customlist_remove_error', assetId);
		});
	}

	$scope.isInQueue = function(assetId) {
		return playQueue.indexOf(parseInt(assetId)) > -1;
	}


	// -------------------------------------------

	asset.addObserver('searchAssetObserver', function(type) {
		switch(type) {
			case 'change':
				populateAssets();
				updateSchedules();
				updateRecordings();

				page = 0;
				$scope.closePane();

				switch($scope.mode) {
					case 'text':
						searchTtile($scope.searchContents.bsReference);
					break;
					case 'category':
						searchCategory($scope.selectedCategory.full);
					break;
					case 'award':
						getAwarded();
					break;
				}
			break;
		}
	});

	setting.addObserver('searchSettingObserver', function(type) {
		switch(type) {
			case 'change':
				updateSchedules();

				for(var i in schedules) {
					var autozapData = schedules[i].split(',');

					// tu nie ma listy 100 więc nie trzeba się martwić powtórzeniami,
					// wystarczy sprawdzić id kanału i epgid klocka
					for(var result in $scope.results) {
						var resultObj = $scope.results[result];

						if(resultObj.epgId == autozapData[4] && resultObj.assetId == autozapData[2]) {
							resultObj.schedule = autozapData[3];
							break;
						}
					}
				}
			break;
		}
	});

	device.addObserver('searchDeviceObserver', function(type) {
		if(device.getMode() != 'stb') return;

		switch(type) {
			case 'selectSuccess':
				init();
			break;
		}
	});

	device.addObserver('watchDeviceObserver', function(type) {
		if($scope.user.isDemo()) return;
		if (type === 'selectSuccess') {
			if (!$rootScope.access.search) return $scope.location.path('/');
			if (!$rootScope.access.epg && $rootScope.access.vod) $scope.searchFlavor = 'vod';
		}

	});

	recording.addObserver('searchRecordingObserver', function(type, data) {
		switch(type) {
			case 'listChange':
				updateRecordings();
			break;
			case 'asyncListChange':
				updateRecordings();

				for(var result in $scope.results) {
					delete $scope.results[result].recording;

					if(typeof recordings[$scope.results[result].uid] !== 'undefined') {
						$scope.results[result].recording = recordings[$scope.results[result].uid];
					}
				}
			break;
			case 'addSuccess':
				var addedRecordingBrickUid = data.channel_id+'/'+data.rec_contentid;

				for(var result in $scope.results) {
					if($scope.results[result].uid === addedRecordingBrickUid) {
						$scope.results[result].recording = data;
					}
				}
			break;
			case 'removeSuccess':
				var deletedRecordingBrickUid = data.channel_id+'/'+data.rec_contentid;

				for(var result in $scope.results) {
					if($scope.results[result].uid === deletedRecordingBrickUid) {
						delete $scope.results[result].recording;
					}
				}
			break;
			case 'changeSuccess':
				var affectedRecording = recording.getOne(data); // w data jest id nagrania
				var affectedRecordingUid = affectedRecording.channel_id+'/'+affectedRecording.rec_contentid;

				if($scope.chosenBrick && typeof $scope.chosenBrick.recording !== 'undefined' && $scope.chosenBrick.recording.id == data) {
					$scope.chosenBrick.recording.user_rec_stop = affectedRecording.user_rec_stop;
					$scope.chosenBrick.recording.user_rec_resume = affectedRecording.user_rec_resume;
				}

				for(var result in $scope.results) {
					if($scope.results[result].uid === affectedRecordingUid) {
						$scope.results[result].recording.user_rec_stop = affectedRecording.user_rec_stop;
						$scope.results[result].recording.user_rec_resume = affectedRecording.user_rec_resume;
					}
				}
			break;
			case 'obliterateSuccess':
				recordings = {};

				for(var result in $scope.results) {
					delete $scope.results[result].recording;
				}
			break;
			case 'triggerNotEntitled':
				$scope.analytics.trackEvent('recording', 'not_entitled');
			break;
			case 'triggerOrderMore':
				$scope.location.path('/uslugi-tv/zamow-dodatki');
				$scope.analytics.trackEvent('order', 'more');
			break;
		}
	});

	$scope.$on('$destroy', function() {
		asset.removeObserver('searchAssetObserver');
		setting.removeObserver('searchSettingObserver');
		recording.removeObserver('searchRecordingObserver');
		device.removeObserver('searchDeviceObserver');
		device.removeObserver('watchDeviceObserver');

		$interval.cancel(currentTimestampUpdateInterval);
	});

	// -------------------------------------------

	$scope.example = examples[Math.round(Math.random()*(examples.length-1))];

	if(device.has() && device.getMode() === 'stb') {
		init();
	}

}]);