angular.module('webpanel').controller('ParentalLockController', ['$scope', '$rootScope', 'setting', 'asset', 'device',  'api', '$timeout', 
	function($scope, $rootScope, setting, asset, device, api, $timeout) {

	$scope.list = {};
	$scope.lockEnabled = false;
	$scope.applyRestartReady = false;

	var applyRestartTimeout;
	var lockedChannels = [];

	var fetch = function() {
		lockedChannels = [];

		var settings = setting.get();
		var assets = asset.get();

		var hasMasterSetting = false;

		for(var name in settings) {
			if(name.match(/^parentallock_\d+$/) && settings[name] === '1') {
				var channelId = name.split('_')[1];
				lockedChannels.push(parseInt(channelId));
			}

			if(name === 'lockenabled') {
				$scope.lockEnabled = (settings[name] === '1');
				hasMasterSetting = true;
			}
		}

		if(!hasMasterSetting) $scope.lockEnabled = true;

		for(var i=0; i<assets.length; i++) {
			$scope.list[assets[i].number] = assets[i];
		}
	}

	var isLocked = function(channelId) {
		return lockedChannels.indexOf(parseInt(channelId)) > -1;
	}

	$scope.isLocked = isLocked;

	$scope.toggleMasterStatus = function() {
		if(!$scope.checkHasEmail()) return;

		$scope.lockEnabled = !$scope.lockEnabled;
		setting.set('lockenabled', $scope.lockEnabled ? '1' : '0');

		$scope.analytics.trackEvent('parentallock', $scope.lockEnabled ? 'enable' : 'disable');
	}

	$scope.toggleChannel = function(channel) {
		if(!$scope.checkHasEmail()) return;

		channel = parseInt(channel);

		if(isLocked(channel)) {
			var index = lockedChannels.indexOf(channel);
			lockedChannels.splice(index, 1);
			setting.set('parentallock_'+channel, '0');

			$scope.analytics.trackEvent('parentallock', 'unlock', channel);
		} else {
			lockedChannels.push(channel);
			setting.set('parentallock_'+channel, '1');

			$scope.analytics.trackEvent('parentallock', 'lock', channel);
		}
	}

	$scope.prepareApplyAndReboot = function(event) {
		if(typeof event !== 'undefined') {
			try {
				event.stopPropagation();
				event.preventDefault();
			} catch(e) {}
		}

		if(!$scope.checkHasEmail()) return;

		$scope.applyRestartReady = true;
		applyRestartTimeout = $timeout($scope.cancelApplyAndReboot, 5000);
	}

	$scope.cancelApplyAndReboot = function() {
		$timeout.cancel(applyRestartTimeout);
		$scope.applyRestartReady = false;
	}

	$scope.applyAndReboot = function(event) {
		// inaczej kliknie belkę i zmieni status blokady
		if(typeof event !== 'undefined') {
			try {
				event.stopPropagation();
				event.preventDefault();
			} catch(e) {}
		}

		$timeout.cancel(applyRestartTimeout);
		$scope.applyRestartReady = false;

		if(!$scope.checkHasEmail()) return;

		api.deviceReboot().then(function() {
			$scope.analytics.trackEvent('device', 'restart', device.getSelected().id);
		}).catch(function() {
			var pop = $scope.popup.create();
			pop.setText('Restart nie powiódł się. Spróbuj ponownie.');
			pop.addButton('OK', angular.noop, 'yes', true);
			$scope.popup.push(pop);
		});
	}

	asset.addObserver('parentalLockAssetObserver', function(type) {
		switch(type) {
			case 'change':
				fetch();
			break;
		}
	});

	setting.addObserver('parentalLockSettingObserver', function(type) {
		switch(type) {
			case 'change':
				fetch();
			break;
		}
	});

	device.addObserver('watchDeviceObserver', function(type) {
		if($scope.user.isDemo()) return;
		if (type === 'selectSuccess' && !$rootScope.access.parentProtect) return $scope.location.path('/');

	});

	$scope.$on('$destroy', function() {
		asset.removeObserver('parentalLockAssetObserver');
		setting.removeObserver('parentalLockSettingObserver');
		device.removeObserver('watchDeviceObserver');
	});

	if($scope.user.isDemo()) {
		$scope.showLoginPrompt(true);
	} else {
		asset.update();
	}

}]);