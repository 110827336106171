angular.module('webpanel').directive('clientUnit', function() {
	
	return {
		scope: {
			clientUnit: '=',
		},
		
		link: function(scope, element) {
			scope.clientUnit = {
				width: (element[0].offsetWidth/5),
				height: (element[0].offsetHeight/5),
				scrollbar: element[0].offsetWidth-element[0].clientWidth,
			}
		}
	}
});