angular.module('webpanel').directive('runeResize', function($window, $timeout) {
	return {
		scope: {
			runeResize: '='
		},
		link: function(scope, element) {
			var resizeDebounce;

			var onResize = function() {
				$timeout.cancel(resizeDebounce);

				resizeDebounce = $timeout(function() {
					scope.runeResize(element[0].clientWidth, element[0].clientHeight);
				}, 100);
			}

			angular.element($window).on('resize', onResize);
			onResize();

			scope.$on('$destroy', function() {
				angular.element($window).off('resize', onResize);
			});

		}
	}
});