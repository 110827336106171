angular.module('webpanel').service('asset', ['socket', 'api', 'device', '$rootScope', 'popup', function(socket, api, device, $rootScope, popup) {
	var assets = {};
	var observers = {};

	var get = function() {
		return assets;
	}

	var has = function() {
		return Object.keys(assets).length > 0;
	}

	var handleLoadError = function() {
		var pop = popup.create();
		pop.setText('Wystąpił błąd podczas pobierania danych. Spróbuj ponownie. '+
			'Jeśli problem będzie się powtarzał, skontaktuj się ze swoim Operatorem.');
		pop.addButton('OK', angular.noop, 'yes', true);
		popup.push(pop);
	}

	var update = function() {
		if(device.has()) {
			api.assetsGet().then(function(response) {
				assets = response.data;
				notifyObservers('change');
			}).catch(handleLoadError);
		}
	}

	var getAlternateIds = function(sgtId) {
		for(var i in assets) {
			if(assets[i].sgtid === sgtId) {
				return assets[i].alternate_id;
			}
		}
	}

	var getCast = function(sgtId) {
		for(var i in assets) {
			if(assets[i].sgtid === sgtId) {
				if(assets[i].constraints_at_home.cast && device.isHome()) return true;
				else if(assets[i].constraints_out_of_home.cast) return true;
				else return false;
			}
		}
	}

	var addObserver = function(name, observer) {
		if(typeof observer !== 'function') return;
		if(typeof observers[name] !== 'undefined') return;
		observers[name] = observer;
	}

	var removeObserver = function(name) {
		delete observers[name];
	}

	var notifyObservers = function(eventType, eventData) {
		for(var i in observers) observers[i](eventType, eventData);
	}

	$rootScope.$on('deviceAcquired', update);

	$rootScope.$on('deviceLost', function() {
		assets = {};
		notifyObservers('change');
	});

	socket.addObserver('assetSocketObserver', 'service', update);

	return {
		get: get,
		has: has,
		update: update,
		getCast: getCast,
		getAlternateIds: getAlternateIds,
		addObserver: addObserver,
		removeObserver: removeObserver,
	}

}]);