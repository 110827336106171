angular.module('webpanel').controller('DeviceListController', ['$scope', '$rootScope', function($scope, $rootScope) {
	$scope.devices = {};
	$scope.visible = false;

	$scope.seeAll = function() {
		$rootScope.$broadcast('deviceMenuSeeAll');
	}

	$scope.select = function(id, type) {
		$rootScope.$broadcast('deviceSelect', { id: id, type: type });
	}

	$scope.$on('deviceMenuFill', function(_, devices) {
		$scope.devices = devices;
	});

	$scope.$on('deviceMenuToggle', function() {
		$scope.visible = !$scope.visible;
	});

	$scope.$on('deviceMenuHide', function() {
		$scope.visible = false;
	});


}]);