angular.module('webpanel').directive('followMouse', function() {
	return {
		link: function(scope, element) {
			var parent = element[0].parentNode;
			var $parent = angular.element(parent);
			
			var parentRect = parent.getBoundingClientRect();
			var $element = angular.element(element);
			
			var updatePosition = function(e) {
				$element.css('left', (e.clientX-parentRect.left)+'px');
				$element.css('top', (e.clientY-parentRect.top)+'px');
			}
			
			$parent.on('mousemove', updatePosition);
			$parent.on('mousedown', updatePosition);
			
			scope.$on('$destroy', function() {
				$parent.off('mousemove', updatePosition);
				$parent.off('mousedown', updatePosition);
			});
		}
	}
});