angular.module('webpanel').controller('MainController', ['$scope', '$rootScope', '$location', 'user', 'popup', 'Analytics', 'loadingInterceptor', 'sizing', '$timeout',
	function($scope, $rootScope, $location, user, popup, Analytics, loadingInterceptor, sizing, $timeout) {

	$scope.clientRect = {};
	$scope.loading = false;

	$scope.hasMediaSource = typeof (window.MediaSource || window.WebKitMediaSource) === 'function';

	var loadingAnimationTimeout;

	$rootScope.access = {
		tv: false,
		card: false,
		mobile: false,
		device: false,
		auth: false,
		watchLive: false,
		epg: false,
		npvr: false,
		list100: false,
		parentProtect: false,
		search: false,
		vod: false,
		schedule: false,
	}

	$rootScope.$on('appConfigChange', function(event, data) {
		if(typeof $rootScope.access[data.what] !== 'boolean') return;
		$rootScope.access[data.what] = data.state;
		event.stopPropagation();
	});

	$scope.checkHasEmail = function() {
		if(user.isVerified()) {
			return true;
		} else {
			if(user.isDemo()) {
				$scope.showLoginPrompt();
			} else {
				var pop = popup.create();
				// #3821 uwaga, NON-BREAKING HYPHEN (U+2011)
				pop.setText('Aby korzystać ze wszystkich funkcji JAMBOX go!, należy zweryfikować konto poprzez ustawienie adresu e‑mail.');
				pop.addButton('Dodaj adres e-mail', function() {
					$location.path('/twoje-konto/twoje-dane');
				}, 'yes', true);
				pop.addButton('Nie teraz', angular.noop, 'no');
				popup.push(pop);
			}

			return false;
		}
	}

	$scope.hasEmail = function() {
		return user.isVerified();
	}

	$scope.showLoginPrompt = function(storeAction) {
		if(typeof storeAction === 'undefined') storeAction = false;
		if(storeAction) $scope.$emit('LoginControllerStoreAction');

		$scope.analytics.trackEvent('user', 'login_prompt_unauthorized');
		//if($rootScope.access.auth) return; // już zalogowany
		$rootScope.$broadcast('loginRequest');
	}

	loadingInterceptor.addObserver('mainLoadingObserver', function(type, count) {
		switch(type) {
			case 'request':
				$timeout.cancel(loadingAnimationTimeout);
				$scope.loading = true;
			break;
			case 'requestError':
			case 'response':
			case 'responseError':
				if(count < 1) {
					loadingAnimationTimeout = $timeout(function() {
						$scope.loading = false;
					}, 500)
				}
			break;
		}
	});

	$scope.$on('$destroy', function() {
		loadingInterceptor.removeObserver('mainLoadingObserver');
	});

	$scope.$watch('clientRect', function(newRect) {
		sizing.update(newRect);
	}, true);

	$scope.location = $location;
	$scope.user = user;
	$scope.popup = popup;

	// nie da się po prostu zadeklarować " || <Function>" w dyrektywie transkludujacej
	// z jakiegoś pomylonego powodu, więc trzeba gdzieś zadeklarować sane defauilt żeby
	// był widoczny w scope... because reasons. Patrz directive/scrollbar.js
	$scope.noop = angular.noop;

	$scope.analytics = Analytics;
}]);