angular.module('webpanel').directive('blockKeyboardEventPropagation', function($document) {
	return {
		link: function(scope) {
			var handler = function(event) {
				event.stopPropagation();
				event.preventDefault();
				return false;
			}

			$document.on('keydown', handler);

			scope.$on('$destroy', function() {
				$document.off('keydown', handler);
			});
		}
	}
});