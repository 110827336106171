angular.module('webpanel').service('sizing', [function() {
	var rect = {};
	var observers = {};

	var addObserver = function(name, observer) {
		if(typeof observer !== 'function') return;
		if(typeof name !== 'string') return;

		observers[name] = observer;
	}

	var removeObserver = function(_, name) {
		delete observers[name];
	}

	var notifyObservers = function(type, data) {
		for(var i in observers) observers[i](type, data);
	}

	var update = function(newRect) {
		rect = newRect;
		notifyObservers('change', rect);
	}

	var get = function() {
		return rect;
	}

	return {
		update: update,
		get: get,
		addObserver: addObserver,
		removeObserver: removeObserver,
	}

}]);